import { MainReducer } from "../../types";
import { ActionType } from "../action-types/index";
import { Action } from "../actions";
import moment from "moment";

export const operationsApiParamsInitState = {
    page: 1,
    pageSize: 50,
    filterData: {
        whatsapp_no: "",
        employee_id: "",
        status: "",
        start_date: moment().subtract(2, "month").format("YYYY-MM-DD"),
        end_date: moment().add(1, "days").format("YYYY-MM-DD")
    },
    filterModel: null,
    sortModel: null
}
const initialState: MainReducer = {
    employee: {
        status: false,
        body: {}
    },
    loan: {
        status: false,
        body: {},
    },
    loanRequest: {
        status: false,
        body: {}
    },
    nbfc: {
        status: false,
        body: {}
    },
    comments: {
        status: false,
        body: {}
    },
    logs: {
        status: false,
        body: {}
    },
    stream: {
        status: false,
        body: {}
    },
    repayment: {
        status: false,
        body: {}
    },
    operationsFilter: {},
    appraisalFilter: {},
    arthmate: {
        status: false,
        body: {}
    },
    operationsApiParams: { ...operationsApiParamsInitState },
    operationsApiResponse: {
        response: null,
        status: false,
        loading: false
    },
};

const reducer = (state: MainReducer = initialState, action: Action): MainReducer => {
    switch (action.type) {
        case ActionType.FETCHLOANREQUEST:
            if (action.payload.status && action.payload.status_code === 404) {
                return { ...state, ...{ loanRequest: { body: {}, status: false } } };
            }
            else {
                return { ...state, ...{ loanRequest: { body: action.payload, status: true } } };
            }
        case ActionType.FETCHEMPLOYEE:
            if (action.payload.status && action.payload.status_code === 404) {
                return { ...state, ...{ employee: { body: {}, status: false } } };
            }
            else {
                return { ...state, ...{ employee: { body: action.payload, status: true } } };
            }
        case ActionType.UPDATEEMPLOYEE:
            return { ...state, ...{ employee: { body: action.payload, status: true } } };

        case ActionType.UPDATELOANREQUEST:
            return { ...state, ...{ loanRequest: { body: action.payload, status: true } } };
            
        case ActionType.FETCHARTHMATE:
            if (action.payload.status && action.payload.status_code === 500) {
                return { ...state, ...{ arthmate: { body: {}, status: false } } };
            }
            else {
                return { ...state, ...{ arthmate: { body: action.payload, status: true } } };
            }
        case ActionType.FETCHLOAN:
            if (action.payload.status_code && action.payload.status_code === 404) {
                return { ...state, ...{ loan: { body: {}, status: false } } };
            }
            else {
                return { ...state, ...{ loan: { body: action.payload, status: true } } };
            }
        case ActionType.FETCHCOMMENTS:
            if (action.payload.status_code && action.payload.status_code === 404) {
                return { ...state, ...{ comments: { body: {}, status: false } } };
            }
            else {
                return { ...state, ...{ comments: { body: action.payload, status: true } } };
            }
        case ActionType.FETCHLOGS:
            if (action.payload.status_code && action.payload.status_code === 404) {
                return { ...state, ...{ logs: { body: {}, status: false } } };
            }
            else {
                return { ...state, ...{ logs: { body: action.payload, status: true } } };
            }
        case ActionType.FETCHSTREAM:
            if (action.payload.status_code && action.payload.status_code === 404) {
                return { ...state, ...{ stream: { body: {}, status: false } } };
            }
            else {
                return { ...state, ...{ stream: { body: action.payload, status: true } } };
            }
        case ActionType.FECTHREPAYMENT:
            if (action.payload.status_code && action.payload.status_code === 404) {
                return { ...state, ...{ repayment: { body: {}, status: false } } };
            }
            else {
                return { ...state, ...{ repayment: { body: action.payload, status: true } } };
            }
        case ActionType.FETCHNBFC:
            return { ...state, ...{ nbfc: { body: action.payload, status: true } } };
        case ActionType.OPERATIONSFILTER:
            return { ...state, ...{ operationsFilter: action.payload } };
        case ActionType.APPRAISALFILTER:
            return { ...state, ...{ appraisalFilter: action.payload } };
        case ActionType.RESET:
            return initialState;

        case ActionType.SET_OPERATIONS_API_PARAMS:
            return {
                ...state,
                operationsApiParams: action.payload
            }

        case ActionType.SET_OPERATIONS_LIST:
            if (action.payload.status && action?.payload?.data && action.payload.status ===
                200) {
                return {
                    ...state, ...{
                        operationsApiResponse: {
                            response: action?.payload?.data
                            , status: true, loading: false
                        }
                    }
                };
            }
            else {
                return { ...state, ...{ operationsApiResponse: { response: null, status: false, loading: false } } };
            }
        case ActionType.GET_OPERATIONS_LIST_API_LOADING:
            return { ...state, ...{ operationsApiResponse: { ...state.operationsApiResponse, loading: action?.payload } } };

        case ActionType.CLEAR_EMPLOYEE_DATA:
            return { ...state, ...{ employee: { body: {}, status: false } } };

        default:
            return state
    }
}

export default reducer