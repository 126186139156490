import {
  Row,
  Col,
  Divider,
  Card,
  Pagination,
  Statistic,
  Spin,
  Descriptions,
  Badge,
  Tooltip,
  Button,
  Select,
  Typography,
  Drawer,
  Modal,
  Input,
  Form,
  Radio,
  Alert,
  message,
  DatePicker,
  Upload,
} from "antd";

import React from "react";
import { render } from "react-dom";
import { AgGridColumn, AgGridReact } from "ag-grid-react";
import { useEffect, useState, useRef, useLayoutEffect } from "react";
import "ag-grid-community/dist/styles/ag-grid.css";
import "ag-grid-community/dist/styles/ag-theme-alpine.css";
import { DrawerProps } from "antd/es/drawer";
import Entitled from "../assets/ent-logo-min.png";
import { BtnCellRenderer } from "./button";
import { AppraisalBtnCellRenderer } from "./appraisalButton";
import { Document } from "./icons/documents";
import { Employee } from "./icons/employee";
import { Activerequest } from "./icons/activerequests";
import { Enach } from "./icons/enach";
import moment from "moment";
import { useSelector, useDispatch } from "react-redux";
import {
  appraisal,
  enach,
  updateBulk,
  updateAllRowsConstant,
  updateBulkAppraised,
  getUsers,
  updateBulkLoanAssignUser,
  createExport,
  createRepaymentExport,
  getRepayments,
  esign,
  getExportForEnachEsign,
  exportZipFiles,
} from "../utitlities/request";
import {
  LogoutOutlined,
  PlusOutlined,
  AppstoreAddOutlined,
  DiffTwoTone,
  SettingTwoTone,
  HighlightOutlined,
  AlignCenterOutlined,
  UploadOutlined,
  DoubleLeftOutlined,
  ExportOutlined,
  RetweetOutlined,
} from "@ant-design/icons";
import axios from "axios";
import { Loan, MainReducer } from "../types";
import { DocumentsVerified } from "./icons/documentsVerified";

import { InfoCircleOutlined } from "@ant-design/icons";
import { EnachGenerated } from "./icons/enachgenerated";
import { Esign } from "./icons/esign";
import {
  DisbursedAmount,
  EmiAmount,
  Interest,
  preEmiInterest,
  TotalAmountToBeRepaid,
} from "../utitlities/calculations";
import { CSVLink, CSVDownload } from "react-csv";
import {
  AppraisalActualStatus,
  AppraisalStatus,
  enach_status,
  esign_status,
} from "../utitlities/status";
import { TopHeader } from "./common/topHeader";
import { fetchNbfc, reset } from "../state/action-creators";
import App from "../App";
import { ActionType } from "../state/action-types";
import {
  DynamicList,
  DynamicMandateSignature,
} from "../utitlities/filteroptions";
import usePrevious from "../utitlities/previous";
import { DuplicateLoan } from "./icons/duplicateloan";
import { ActiveLoan } from "./icons/activeloan";
import { LoanClosed } from "./icons/loanClosed";
import { result } from "lodash";
import { config } from "../config";
import { RepaymentCellRenderer } from "./repaymentButton";
type RequiredMark = boolean | "optional";
const { Paragraph, Text, Title } = Typography;
export default function Repayment() {
  const gridRef: any = useRef(null);
  const [gridApi, setGridApi] = useState(null);
  const groupDisplayType = "singleColumn";
  const [gridColumnApi, setGridColumnApi] = useState(null);
  const gridApiRef = useRef<any>(null);
  const [view, setView] = useState(false);
  const [selectedRows, setSelectedRows] = useState(0);
  const [width, setWidth] = useState(window.innerWidth);
  const [height, setHeight] = useState(window.innerHeight);
  const [states, setStates] = useState<any>(null);
  const [rowData, setRowData] = useState<Array<Loan>>([]);
  const [visible, setVisible] = useState(false);
  const [currentState, setCurrentState] = useState("");
  const [option, setOption] = useState("");
  const [optionuser, setOptionUser] = useState("");
  const [isDrawer, setIsDrawer] = useState(false);
  const { Option } = Select;
  const [currentpage, setCurrentPage] = useState(1);
  const [total, setTotal] = useState(0);
  const [size, setSize] = useState(20);
  const [isModalVisible, setIsModalVisible] = useState(false);
  // fixed parameters

  const [processingfee, setProcessingFee] = useState("350");
  const [interestrate, setInterestRate] = useState("12");
  const [type, setType] = useState("weekly");
  const [approvedamount, setApprovedAmount] = useState("3500");
  const [period, setPeriod] = useState("12");
  // form
  const [users, setUsers] = useState([]);
  const [role, setRole] = useState("");
  // @ts-ignore
  const [pagination, setPagination] = useState<number>(
    //  @ts-ignore
    Number(localStorage.getItem("appraisal_pagination") || 1500)
  );
  // @ts-ignore
  const [current_page, setCurrentpage] = useState<number>(
    //  @ts-ignore
    Number(localStorage.getItem("appraisal_page") || 0)
  );
  const prev_page = usePrevious(pagination);
  const [status_comment, SetComment] = useState("");
  const [company, setCompany] = useState("");
  const [companyOptions, setCompanyOptions] = useState<any>();
  // date
  const { RangePicker } = DatePicker;
  const [start_date, setStartDate] = useState<any>(
    localStorage.getItem("aps_start_date")
      ? localStorage.getItem("aps_start_date")
      : moment().subtract(1, "weeks").format("YYYY-MM-DD")
  );
  const [end_date, setEndDate] = useState<any>(
    localStorage.getItem("aps_end_date")
      ? localStorage.getItem("aps_end_date")
      : moment().add(1, "days").format("YYYY-MM-DD")
  );
  const [disbursal_date, SetDisbursalDate] = useState("");
  const [mobile_number, setMobileNumber] = useState("");
  const [exportUIRows, setExportUIRows] = useState<any>([]);
  const csvLink = useRef();
  const [whatsapp_no, setWhatsappNumber] = useState("");
  const [status, setStatus] = useState("Please select a status");
  const onDateChange = (dates: any, dateStrings: any) => {
    if (dates) {
      setStartDate(dates[0].format("YYYY-MM-DD"));
      setEndDate(dates[1].format("YYYY-MM-DD"));
      localStorage.setItem("aps_start_date", dates[0].format("YYYY-MM-DD"));
      localStorage.setItem("aps_end_date", dates[1].format("YYYY-MM-DD"));
    }
  };
  const [form] = Form.useForm();
  const [requiredMark, setRequiredMarkType] =
    useState<RequiredMark>("optional");

  const dispatch = useDispatch();

  const onRequiredTypeChange = ({
    requiredMarkValue,
  }: {
    requiredMarkValue: RequiredMark;
  }) => {
    setRequiredMarkType(requiredMarkValue);
  };

  const showModal = () => {
    setIsModalVisible(true);
  };

  const handleOk = () => {
    setIsModalVisible(false);
  };

  const handleCancel = () => {
    setIsModalVisible(false);
  };

  const showDrawer = () => {
    setIsDrawer(true);
  };
  const onClose = () => {
    setIsDrawer(false);
  };
  function updateDimension() {
    setWidth(window.innerWidth);
    setHeight(window.innerHeight);
  }
  function RouteGuard() {
    let check: boolean = false;
    if (localStorage.getItem("token") && localStorage.getItem("user")) {
      check = true;
    }
    return check;
  }
  const earlierfilter = useSelector((state: MainReducer) => {
    return state.loan.appraisalFilter;
  });
  const nbfcstatus = useSelector((state: MainReducer) => {
    return state.loan.nbfc.status;
  });
  const nbfc = useSelector((state: MainReducer) => {
    return state.loan.nbfc.body;
  });
  useEffect(() => {
    (async () => {
      const routeguard: boolean = RouteGuard();
      if (routeguard) {
        let user: any = {};
        //
        // reset redux state
        dispatch(reset());
        //
        dispatch(fetchNbfc());
        {
          //@ts-ignore
          user = JSON.parse(localStorage.getItem("user"));
          setRole(user["role"]);
        }
        const users = await getUsers();
        setUsers(users);
        // setCompanyOptions(["Enable Cap", "Siply"]);
        const loans = await getRepayments(
          currentpage,
          size,
          start_date,
          end_date
        );
        setRowData(loans["result"]);
        setStates(AppraisalStatus);
        setTotal(loans["count"]);
        setVisible(true);
        setTimeout(() => {
          ApplyFilter("fresh");
        }, 200);
      } else {
        window.location.href = "/no_cred";
      }
    })();
  }, [currentpage, size, start_date, end_date]);

  useEffect(() => {
    // if(gridApiRef.current){
    //   gridRef.current.api.deselectAll();
    // }

    ApplyFilter();
  }, [status, mobile_number, whatsapp_no]);

  const ApplyFilter = (type: any = null) => {
    if (Object.keys(earlierfilter).length !== 0 && type) {
      gridApiRef.current.setFilterModel(earlierfilter);
      gridApiRef.current.paginationGoToPage(current_page);
    } else if (localStorage.getItem("appraisalFilter") && type) {
      {
        if (gridApiRef.current) {
          //  @ts-ignore
          gridApiRef.current.setFilterModel(
            //  @ts-ignore
            JSON.parse(localStorage.getItem("appraisalFilter"))
          );
          gridApiRef.current.paginationGoToPage(current_page);
          //  @ts-ignore
          let filter = JSON.parse(localStorage.getItem("appraisalFilter"));
          if (filter["status"]) {
            setStatus(filter["status"]["filter"]);
          }
          if (filter["mobile_number"]) {
            setMobileNumber(filter["mobile_number"]["filter"]);
          }
          if (filter["loan_id"]) {
            setWhatsappNumber(filter["loan_id"]["filter"]);
          }
        }
      }
    } else {
      var hardcodedFilter = {
        status: {
          type: "equals",
          filter: status,
        },
        mobile_number: {
          type: "equals",
          filter: mobile_number,
        },
        loan_id: {
          type: "equals",
          filter: whatsapp_no,
        },
      };
      if (
        mobile_number.length !== 0 ||
        whatsapp_no.length !== 0 ||
        status !== "Please select a status"
      ) {
        if (mobile_number.length == 0) {
          {
            //@ts-ignore
            delete hardcodedFilter["mobile_number"];
          }
        }
        if (whatsapp_no.length == 0) {
          {
            //@ts-ignore
            delete hardcodedFilter["loan_id"];
          }
        }
        if (status == "Please select a status") {
          {
            //@ts-ignore
            delete hardcodedFilter["status"];
          }
        }
        if (gridApiRef.current) {
          const filter = gridApiRef.current.getFilterModel();
          delete filter["status"];
          delete filter["mobile_number"];
          delete filter["loan_id"];
          gridApiRef.current.setFilterModel({ ...filter, ...hardcodedFilter });
          gridApiRef.current.paginationGoToPage(current_page);
        }
      } else {
        if (gridApiRef.current) {
          const filter = gridApiRef.current.getFilterModel();
          delete filter["status"];
          delete filter["mobile_number"];
          delete filter["loan_id"];
          gridApiRef.current.setFilterModel(filter);
          gridApiRef.current.paginationGoToPage(current_page);
          //  gridRef.current.api.deselectAll();
        }
      }
    }
  };
  const deselectAll = () => {
    if (gridApiRef.current) {
      gridRef.current.api.deselectAll();
    }
  };

  useEffect(() => {
    localStorage.setItem("appraisal_pagination", JSON.stringify(pagination));
    if (gridApiRef.current) {
      gridRef.current.api.deselectAll();
    }
  }, [pagination]);
  useEffect(() => {
    setCompanyOptions(nbfc);
  }, [nbfcstatus]);
  useLayoutEffect(() => {
    window.addEventListener("resize", updateDimension);
    updateDimension();
    return () => window.removeEventListener("resize", updateDimension);
  }, []);

  const onGridReady = (params: any) => {
    gridApiRef.current = params.api;
    setGridApi(params.api);
    setGridColumnApi(params.columnApi);
  };
  const assignUser = async () => {
    const selectedNodes = gridRef.current.api.getSelectedNodes();
    const selectedData = selectedNodes.map((node: any) => node.data);
    let loan_ids: any = [];
    selectedData.forEach((element: any) => {
      loan_ids.push(Number(element["loan_id"]));
    });
    let data = {
      loan_ids: loan_ids,
      user_id: Number(optionuser),
    };
    if (loan_ids.length == 0) {
      message.warning("Please select a row  to proceed");
    } else if (optionuser.length == 0) {
      message.warning("Please select an option  to proceed");
    } else {
      setVisible(true);
      const loans = await updateBulkLoanAssignUser(data);
      window.location.reload();
    }
  };
  const onButtonClick = async () => {
    const selectedNodes = gridRef.current.api.getSelectedNodes();
    const selectedData = selectedNodes.map((node: any) => node.data);
    let loan_ids: any = [];
    selectedData.forEach((element: any) => {
      loan_ids.push(Number(element["loan_id"]));
    });
    let data: any = {
      loan_ids: loan_ids,
      status: option,
    };
    if (option == "COMPLETE") {
      data["disbursal_date"] = disbursal_date;
    }
    if (loan_ids.length == 0) {
      message.warning("Please select a row  to proceed");
    } else if (option.length == 0) {
      message.warning("Please select a row  to proceed");
    } else if (option == "COMPLETE" && !disbursal_date) {
      message.warning("Please select a disbursal date");
    } else {
      setVisible(true);
      const loans = await updateBulkAppraised(data);
      window.location.reload();
    }
  };

  const eNach = async () => {
    const selectedNodes = gridRef.current.api.getSelectedNodes();
    const selectedData = selectedNodes.map((node: any) => node.data);
    let loan_ids: any = [];
    selectedData.forEach((element: any) => {
      loan_ids.push(Number(element["loan_id"]));
    });
    let data = {
      loan_ids: loan_ids,
    };
    if (loan_ids.length == 0) {
      message.warning("Please select a row  to proceed");
    } else {
      setVisible(true);
      const loans = await enach(data);
      window.location.reload();
    }
  };
  const eSign = async () => {
    const selectedNodes = gridRef.current.api.getSelectedNodes();
    const selectedData = selectedNodes.map((node: any) => node.data);
    let loan_ids: any = [];
    selectedData.forEach((element: any) => {
      loan_ids.push(Number(element["loan_id"]));
    });
    let data = {
      loan_ids: loan_ids,
    };
    if (loan_ids.length == 0) {
      message.warning("Please select a row  to proceed");
    } else {
      setVisible(true);
      const loans = await esign(data);
      // window.location.reload();
    }
  };
  const filterOptions = [
    "empty",
    {
      displayKey: "blanks",
      displayName: "Blanks",
      test: function (filterValue: any, cellValue: any) {
        return cellValue == "";
      },
      hideFilterInput: true,
    },
    {
      displayKey: "notblanks",
      displayName: "Not Blanks",
      test: function (filterValue: any, cellValue: any) {
        return cellValue !== "";
      },
      hideFilterInput: true,
    },
    "equals",
    "notEqual",
    "lessThan",
    "lessThanOrEqual",
    "greaterThan",
    "greaterThanOrEqual",
    "inRange",
  ];
  const filterOptionsAssigned: any = DynamicList(
    users.map((element: any) => {
      return element.user_name;
    })
  );
  const filterOptionsEnach: any = DynamicMandateSignature(enach_status);
  const filterOptionsEsign: any = DynamicMandateSignature(esign_status);
  const propsNewDocument = {
    name: "file",
    action: `${config.base_url}/utils/upload/repayment`,
    headers: {
      authorization: "authorization-text",
    },
    async onChange(info: any) {
      if (info.file.status !== "uploading") {
      }
      if (info.file.status === "done") {
        message.success(`${info.file.name} file uploaded successfully`);
        setVisible(true);
      } else if (info.file.status === "error") {
        message.error(`${info.file.name} file upload failed.`);
      } else {
        //message.error(`${info.file.name} file upload failed.`);
      }
    },
  };
  const filterOptionsText = [
    "empty",
    {
      displayKey: "blanks",
      displayName: "Blanks",
      test: function (filterValue: any, cellValue: any) {
        return cellValue == "";
      },
      hideFilterInput: true,
    },
    {
      displayKey: "notblanks",
      displayName: "Not Blanks",
      test: function (filterValue: any, cellValue: any) {
        return cellValue !== "";
      },
      hideFilterInput: true,
    },
    "equals",
    "notEqual",
    "contains",
    "lessThan",
    "lessThanOrEqual",
    "greaterThan",
    "greaterThanOrEqual",
    "inRange",
  ];
  const dateFilterParams = {
    filterOptions: filterOptions,

    // provide comparator function
    comparator: (filterLocalDateAtMidnight: any, cellValue: any) => {
      const dateAsString = cellValue;

      if (!dateAsString) {
        return;
      }

      // In the example application, dates are stored as dd/mm/yyyy
      // We create a Date object for comparison against the filter date
      const dateParts = dateAsString.split("/");
      const day = Number(dateParts[0]);
      const month = Number(dateParts[1]) - 1;
      const year = Number(dateParts[2]);
      const cellDate = new Date(year, month, day);

      // Now that both parameters are Date objects, we can compare
      if (cellDate < filterLocalDateAtMidnight) {
        return -1;
      } else if (cellDate > filterLocalDateAtMidnight) {
        return 1;
      }
      return 0;
    },
  };

  const filterOptionsPresent = [
    "empty",
    {
      displayKey: "present",
      displayName: "Present",
      test: function (filterValue: any, cellValue: any) {
        return cellValue == "true";
      },
      hideFilterInput: true,
    },
    {
      displayKey: "notpresent",
      displayName: "Not Present",
      test: function (filterValue: any, cellValue: any) {
        return cellValue == "false";
      },
      hideFilterInput: true,
    },
  ];
  function resetAllFilters() {
    setMobileNumber("");
    setWhatsappNumber("");
    gridApiRef.current.setFilterModel(null);
    setStatus("Please select a status");
    message.info("Filters Reset Sucessfully");
  }

  const updateAllRowsScreen = async () => {
    let rows: any = [];
    gridRef.current.api.forEachNode((rowindex: any, node: any) => {
      let element = rowindex.data;
      let json = {
        repayment_schedule: {
          installment_amt: Number(element.installment_amt),
          installment_count: Number(element.installment_count),
          first_date: parseDateDb(element.first_date),
          role: element.role,
          emi_amount: Number(element.installment_amt),
          pre_emi_interest: Number(element.pre_emi_interest),
          interest: Number(element.interest),
          loan_id: Number(element.loan_id),
        },
        loan: {
          approved_amount: Number(element.approved_amount),
          tenure: Number(element.installment_count),
          irr: Number(element.irr),
          processing_fee: Number(element.processing_fee),
          interest_amount: Number(element.interest),
          disbursal_amount: Number(element.disbursal_amount),
          total_amount_to_be_repaid: Number(element.amount_to_be_repaid),
        },
      };
      rows.push(json);
    });
    const result = await updateAllRowsConstant(rows);

    if (result["statusCode"] == 200) {
      message.success("updated all rows");
    } else {
      message.error("could not update all rows");
    }
  };
  function Tenure(param: any) {
    return Number(param.data.tenure);
  }
  function Irr(param: any) {
    return Number(param.data.irr);
  }
  function approvedAmount(param: any) {
    return Number(param.data.approved_amount);
  }
  function processingFee(param: any) {
    let x = param.data.processing_fee;
    let ps = Math.round(Number(x));
    param.data.processing_fee = ps;
    return param.data.processing_fee;
  }

  function installmentCount(param: any) {
    return Number(param.data.installment_count);
  }

  function installmentAmount(param: any) {
    param.data.installment_amt = EmiAmount(
      param.data.irr / 100 / 12,
      param.data.installment_count,
      param.data.approved_amount * -1,
      0,
      0
    );
    return param.data.installment_amt;
  }
  function emiAmount(param: any) {
    param.data.emi_amount = EmiAmount(
      param.data.irr / 100 / 12,
      param.data.installment_count,
      param.data.approved_amount * -1,
      0,
      0
    );
    return param.data.emi_amount;
  }

  // function role(param: any) {
  //   return param.data.role
  // }
  function disbursalAmount(param: any) {
    let preemiinterest = preEmiInterest(
      param.data.approved_amount,
      param.data.irr / 100,
      -10
    );
    let ds = DisbursedAmount(
      param.data.approved_amount,
      Math.round(
        Number(param.data.processing_fee) * 0.18 +
          Number(param.data.processing_fee)
      ),
      preemiinterest
    );
    param.data.disbursal_amount = ds;
    return param.data.disbursal_amount;
  }

  function interestAmount(param: any) {
    let emi = EmiAmount(
      param.data.irr / 100 / 12,
      param.data.installment_count,
      param.data.approved_amount * -1,
      0,
      0
    );
    let i = Interest(
      Number(param.data.installment_count),
      Number(param.data.irr),
      Number(param.data.approved_amount),
      Number(emi)
    );
    param.data.interest = i;
    return param.data.interest;
  }

  function emiDate(param: any) {
    let temp = param.data.first_date.split("T")[0].replace(/-/g, "/");
    param.data.first_date = temp;
    return param.data.first_date;
  }
  function parseDateDb(date: any) {
    let value: any = "";

    if (date && date.includes("T")) {
      value = date.split("T")[0].split("-");
      return `${value[0]}-${value[1]}-${value[2]}`;
    } else if (date && date.includes("/")) {
      value = date.split("/");
      return `${value[2]}-${value[1]}-${value[0]}`;
    } else {
      return null;
    }
  }
  function preEmiDate(param: any) {
    let value = "";
    let day = "";
    let month = -1;
    let year = -1;
    // parse the date
    if (param.data.first_date) {
      if (param.data.first_date.includes("T")) {
        value = param.data.first_date.split("T")[0].split("-");
        day = value[2];
        month = Number(value[1]);
        year = Number(value[0]);
      } else {
        value = param.data.first_date.split("/");
        day = value[0];
        month = Number(value[1]);
        year = Number(value[2]);
      }
      if (month == 1) {
        month = 12;
        year = year - 1;
      } else {
        month = month - 1;
      }
      let new_date = `${day}/${month}/${year}`;
      param.data.pre_emi_date = new_date;
      return param.data.pre_emi_date;
    } else {
      return "";
    }
  }

  function peI(param: any) {
    let ped: any;
    let diffrenece: any = 0;
    if (param.data.pre_emi_date) {
      ped = param.data.pre_emi_date.split("/");
      diffrenece = Math.round(
        (new Date(
          Number(ped[2]),
          Number(ped[1] - 1),
          Number(ped[0])
        ).getTime() -
          new Date().getTime()) /
          86400000
      );
    }
    let preEmi = preEmiInterest(
      param.data.approved_amount,
      param.data.irr / 100,
      diffrenece
    );
    param.data.pre_emi_interest = preEmi;
    return param.data.pre_emi_interest;
  }
  function amountRepaid(param: any) {
    let emi = EmiAmount(
      param.data.irr / 100 / 12,
      param.data.installment_count,
      param.data.approved_amount * -1,
      0,
      0
    );
    let at = TotalAmountToBeRepaid(
      Number(param.data.approved_amount),
      Number(param.data.interest)
    );
    param.data.amount_to_be_repaid = at;
    return param.data.amount_to_be_repaid;
  }
  function parseDate(params: any) {
    if (params.data.date_of_enquiry) {
      let date = params.data.date_of_enquiry.split("T")[0].split("-");
      return `${date[2]}/${date[1]}/${date[0]}`;
    } else {
      return "";
    }
  }
  function parseDisbursalDate(params: any) {
    if (params.data.disbursal_date) {
      return moment(params.data.disbursal_date).format("DD/MM/YYYY");
    } else {
      return "";
    }
  }
  function parseReceivedDate(params: any) {
    if (params.data.received_date) {
      return moment(params.data.received_date).format("DD/MM/YYYY");
    } else {
      return "";
    }
  }
  function parseDateEMI(params: any) {
    if (params.data.first_date && params.data.first_date.includes("T")) {
      let date = params.data.first_date.split("T")[0].split("-");
      return `${date[2]}/${date[1]}/${date[0]}`;
    } else if (params.data.first_date) {
      return params.data.first_date;
    } else {
      return "";
    }
  }
  function parseAnyDate(date: any) {
    date = date.split("T")[0].split("-");
    return `${date[2]}/${date[1]}/${date[0]}`;
  }

  function AssignedTo(params: any) {
    if (params.data.user_name) {
      return params.data.user_name;
    } else {
      return "";
    }
  }
  function AssignedToOps(params: any) {
    if (params.data.ops_username) {
      return params.data.ops_username;
    } else {
      return "";
    }
  }
  function parseAssignedToDate(params: any) {
    if (params.data.user_assigned_at) {
      let date = params.data.user_assigned_at.includes("T")
        ? params.data.user_assigned_at.split("T")[0].split("-")
        : params.data.user_assigned_at.split(" ")[0].split("-");
      return `${date[2]}/${date[1]}/${date[0]}`;
    } else {
      return "";
    }
  }
  const columnDefs = [
    {
      headerName: "Loan Id",
      field: "loan_id",
      headerCheckboxSelectionFilteredOnly: true,
      headerCheckboxSelection: true,
      checkboxSelection: true,
      pinned: "left",
      width: 180,
      suppressSizeToFit: true,
      filter: true,
      cellStyle: { fontSize: "12px" },
    },
    {
      headerName: "Amount",
      field: "amount",
      minWidth: 180,
      suppressSizeToFit: true,
      filter: true,
      cellStyle: { fontSize: "12px" },
    },
    {
      field: "channel",
      headerName: "Channel",
      filter: true,
      minWidth: 170,
      suppressSizeToFit: true,
      cellStyle: { fontSize: "12px" },
      filterParams: {
        filterOptions: filterOptionsText,
      },
    },
    {
      field: "type",
      headerName: "Type",
      filter: true,
      // filterParams: {
      //   filterOptions: filterOptionsText,
      // },
      cellStyle: { fontSize: "12px" },
      suppressSizeToFit: true,
      minWidth: 140,
    },
    {
      field: "received_date",
      headerName: "Recieved Date",
      filterParams: dateFilterParams,
      filter: "agDateColumnFilter",
      valueGetter: parseReceivedDate,
      sortable: true,
      cellStyle: { fontSize: "12px" },
      minWidth: 180,
    },
    {
      field: "borrower_id",
      minWidth: 120,
      headerName: "Borrower Id",
      filter: true,
      cellStyle: { fontSize: "12px" },
      pinned: "left",
    },
    {
      field: "mobile_number",
      minWidth: 120,
      headerName: "Mobile",
      filter: true,
      cellStyle: { fontSize: "12px" },
      pinned: "left",
    },
    //   {
    //     field: "status",
    //     headerName: "Status",
    //     minWidth: 140,
    //     suppressSizeToFit: true,
    //     cellStyle: { fontSize: "12px" },
    //   },
    //   {
    //     headerName: "Client Name",
    //     field: "client_name",
    //     filter: true,
    //     cellStyle: { fontSize: "12px" },
    //     minWidth: 180,
    //   },
    //   {
    //     field: "request_status_comment",
    //     headerName: "Loan Status Comment",
    //     filter: true,
    //     minWidth: 220,
    //     suppressSizeToFit: true,
    //     cellStyle: { fontSize: "12px" },
    //   },
    //   {
    //     field: "ops_request_status_comment",
    //     headerName: "Request Status Comment",
    //     filter: true,
    //     minWidth: 220,
    //     suppressSizeToFit: true,
    //     cellStyle: { fontSize: "12px" },
    //   },
    //   {
    //     headerName: "Amount",
    //     field: "requested_amount",
    //     filter: "agNumberColumnFilter",
    //     minWidth: 140,
    //     suppressSizeToFit: true,
    //     cellStyle: { fontSize: "12px" },
    //     filterParams: {
    //       filterOptions: filterOptionsText,
    //     },
    //     sortable: true,
    //   },
    {
      field: "loan_id",
      headerName: "Description",
      pinned: "right",
      minwidth: 180,
      suppressSizeToFit: true,
      cellRenderer: "repaymentButton",
      cellStyle: { fontSize: "12px" },
    },
    // {
    //   field: "user_assigned_at",
    //   headerName: "Assigned on ",
    //   valueGetter: parseAssignedToDate,
    //   sortable: true,
    //   filterParams: dateFilterParams,
    //   filter: "agDateColumnFilter",
    //   cellStyle: { fontSize: "12px" },
    //   minWidth: 140,
    //   pinned:"right"
    // },

    // {
    //   field: "employee_id",
    //   headerName: "Employee Id",
    //   sortable: true,
    //   filter: true,
    //   cellStyle: { fontSize: "12px" },
    //   width: 150,
    //   suppressSizeToFit: true,
    //   minWidth: 140,
    // },
    // {
    //   field: "mobile_number",
    //   headerName: "Mobile",
    //   filter: true,
    //   cellStyle: { fontSize: "12px" },
    //   width: 150,
    //   suppressSizeToFit: true,
    //   minWidth: 140,
    //   filterParams: {
    //     filterOptions: filterOptionsText,
    //   },
    // },
    // {
    //   field: "marital_status",
    //   headerName: "Marital Status",
    //   sortable: true,
    //   filter: true,
    //   cellStyle: { fontSize: "12px" },
    //   width: 150,
    //   suppressSizeToFit: true,
    //   minWidth: 140,
    // },
    // {
    //   field: "salary",
    //   headerName: "Salary",
    //   sortable: true,
    //   filter: true,
    //   cellStyle: { fontSize: "12px" },
    //   width: 150,
    //   suppressSizeToFit: true,
    //   minWidth: 140,
    // },
    // {
    //   field: "gender",
    //   headerName: "Gender",
    //   sortable: true,
    //   filter: true,
    //   cellStyle: { fontSize: "12px" },
    //   width: 150,
    //   suppressSizeToFit: true,
    //   minWidth: 140,
    // },
    // {
    //   field: "pincode",
    //   headerName: "Pincode",
    //   sortable: true,
    //   filter: true,
    //   cellStyle: { fontSize: "12px" },
    //   width: 150,
    //   suppressSizeToFit: true,
    //   minWidth: 140,
    // },
    // {
    //   field: "city",
    //   headerName: "City",
    //   sortable: true,
    //   filter: true,
    //   cellStyle: { fontSize: "12px" },
    //   width: 150,
    //   suppressSizeToFit: true,
    //   minWidth: 140,
    // },
    // {
    //   field: "pan",
    //   headerName: "Pan",
    //   sortable: true,
    //   filter: true,
    //   cellStyle: { fontSize: "12px" },
    //   width: 150,
    //   suppressSizeToFit: true,
    //   minWidth: 140,
    // },
    // {
    //   field: "account_no",
    //   headerName: "Account",
    //   sortable: true,
    //   filter: true,
    //   cellStyle: { fontSize: "12px" },
    //   width: 150,
    //   suppressSizeToFit: true,
    //   minWidth: 140,
    // },
    // {
    //   field: "ifsc_code",
    //   headerName: "IFSC",
    //   sortable: true,
    //   filter: true,
    //   cellStyle: { fontSize: "12px" },
    //   width: 150,
    //   suppressSizeToFit: true,
    //   minWidth: 140,
    // },
    // {
    //   field: "aadhaar",
    //   headerName: "Aadhaar",
    //   sortable: true,
    //   filter: true,
    //   cellStyle: { fontSize: "12px" },
    //   width: 150,
    //   suppressSizeToFit: true,
    //   minWidth: 140,
    // },
    // {
    //   field: "state",
    //   headerName: "State",
    //   sortable: true,
    //   filter: true,
    //   cellStyle: { fontSize: "12px" },
    //   width: 150,
    //   suppressSizeToFit: true,
    //   minWidth: 140,
    // },
    // {
    //   field: "creditor_agent_code",
    //   headerName: "Creditor Agent Code ",
    //   sortable: true,
    //   filter: true,
    //   cellStyle: { fontSize: "12px" },
    //   width: 150,
    //   suppressSizeToFit: true,
    //   minWidth: 170,
    // },
    // {
    //   field: "creditor_utility_code",
    //   headerName: "Creditor Utitlity Code",
    //   sortable: true,
    //   filter: true,
    //   cellStyle: { fontSize: "12px" },
    //   width: 150,
    //   suppressSizeToFit: true,
    //   minWidth: 170,
    // },

    // {
    //   headerName: "Date of Birth",
    //   field: "date_of_birth",
    //   filterParams: dateFilterParams,
    //   valueGetter: parseDate,
    //   filter: "agDateColumnFilter",
    //   cellStyle: { fontSize: "12px" },
    //   width: 150,
    //   suppressSizeToFit: true,
    //   minWidth: 140,
    // },
    // {
    //   headerName: "Date of enquiry",
    //   field: "date_of_enquiry",
    //   filterParams: dateFilterParams,
    //   valueGetter: parseDate,
    //   filter: "agDateColumnFilter",
    //   cellStyle: { fontSize: "12px" },
    //   width: 150,
    //   suppressSizeToFit: true,
    //   minWidth: 140,
    // },
    //   {
    //     field: "nbfc_name",
    //     headerName: "NBFC",
    //     sortable: true,
    //     filter: true,
    //     cellStyle: { fontSize: "12px" },
    //     width: 150,
    //     suppressSizeToFit: true,
    //     minWidth: 140,
    //   },
    //   {
    //     field: "esign_status",
    //     headerName: "E-Signature",
    //     cellStyle: { fontSize: "12px" },
    //     minWidth: 130,
    //     filterParams: {
    //       filterOptions: filterOptionsEsign,
    //     },
    //     filter: true,
    //   },
    //   {
    //     field: "enach_status",
    //     headerName: "E-Nach",
    //     cellStyle: { fontSize: "12px" },
    //     minWidth: 130,
    //     filterParams: {
    //       filterOptions: filterOptionsEnach,
    //     },
    //     filter: true,
    //   },

    //   {
    //     field: "verified_ref_count",
    //     headerName: "Reference Verified",
    //     cellStyle: { fontSize: "12px" },
    //     minWidth: 180,
    //     filter: true,
    //     sortable: true,
    //   },
    //   {
    //     field: "is_loan_closed",
    //     headerName: "Loan Closed Status",
    //     cellStyle: { fontSize: "12px" },
    //     minWidth: 180,
    //     filter: true,
    //     filterParams: {
    //       filterOptions: filterOptionsPresent,
    //     },
    //     cellRenderer: "is_loan_closed",
    //     sortable: true,
    //   },
    //   {
    //     field: "duplicate",
    //     headerName: "Duplicate",
    //     cellStyle: { fontSize: "12px" },
    //     minWidth: 180,
    //     filter: true,
    //     filterParams: {
    //       filterOptions: filterOptionsPresent,
    //     },
    //     cellRenderer: "duplicate_loan",
    //     sortable: true,
    //   },
    //   {
    //     field: "active",
    //     headerName: "Active",
    //     cellStyle: { fontSize: "12px" },
    //     minWidth: 180,
    //     cellRenderer: "active_loan",
    //     filter: true,
    //     filterParams: {
    //       filterOptions: filterOptionsPresent,
    //     },
    //     sortable: true,
    //   },
    //   {
    //     headerName: "Disbursal Date",
    //     field: "disbursal_date",
    //     filterParams: dateFilterParams,
    //     filter: "agDateColumnFilter",
    //     valueGetter: parseDisbursalDate,
    //     sortable: true,
    //     cellStyle: { fontSize: "12px" },
    //     minWidth: 180,
    //   },

    //   {
    //     field: "ops_username",
    //     headerName: "Request Assigned to ",
    //     valueGetter: AssignedToOps,
    //     filter: true,
    //     cellStyle: { fontSize: "12px" },
    //     minWidth: 180,
    //     filterParams: {
    //       filterOptions: filterOptionsAssigned,
    //     },
    //   },
    // {
    //   field: "installment_count",
    //   filter:'agNumberColumnFilter',
    //   headerName: "Tenure(months)",
    //   valueGetter: installmentCount,
    //   valueSetter: function (params: any) {
    //     var newVal = Number(params.newValue);
    //     var valueChanged = Number(params.data.installment_count)!== newVal;
    //     if (valueChanged) {
    //       params.data.installment_count = newVal;
    //     }
    //     return true;
    //   },
    //   editable: true,
    //   minWidth: 140,
    //   cellStyle: { fontSize: "12px" },
    // },
    // {
    //   field: "tenure",
    //   headerName: "Tenure",
    //   valueGetter: Tenure,
    //   editable: true,
    //   minWidth: 140,
    // },
    // {
    //   field: "approved_amount",
    //   filter:'agNumberColumnFilter',
    //   headerName: "Approved Amount",
    //   valueGetter: approvedAmount,
    //   valueSetter: function (params: any) {
    //     var newVal = Number(params.newValue);
    //     var valueChanged = Number(params.data.approved_amount)!== newVal;
    //     if (valueChanged) {
    //       params.data.approved_amount = newVal;
    //     }
    //     return true;
    //   },
    //   editable: true,
    //   minWidth: 170,
    //   cellStyle: { fontSize: "12px" },
    // },
    // {
    //   field: "irr",
    //   filter:'agNumberColumnFilter',
    //   headerName: "IRR(%)",
    //   valueGetter: Irr,
    //   editable: true,
    //   minWidth: 140,
    //   valueSetter: function (params: any) {
    //     var newVal = Number(params.newValue);
    //     var valueChanged = Number(params.data.irr)!== newVal;
    //     if (valueChanged) {
    //       params.data.irr = newVal;
    //     }
    //     return true;
    //   },
    //   cellStyle: { fontSize: "12px" },
    // },
    // {
    //   field: "processing_fee",
    //   filter:'agNumberColumnFilter',
    //   headerName: "Processing Fee",
    //   valueGetter: processingFee,
    //   editable: true,
    //   minWidth: 140,
    //   valueSetter: function (params: any) {
    //     var newVal = Number(params.newValue);
    //     var valueChanged = Number(params.data.processing_fee)!== newVal;
    //     if (valueChanged) {
    //       params.data.processing_fee = newVal;
    //     }
    //     return true;
    //   },
    //   cellStyle: { fontSize: "12px" },
    // },
    // {
    //   field: "role",
    //   headerName: "Role",
    //   valueGetter: role,
    //   editable: true,
    //   minWidth: 140,
    //   valueSetter: function (params: any) {
    //     var newVal = params.newValue;
    //     var valueChanged = params.data.role !== newVal;
    //     if (valueChanged) {
    //       params.data.role = newVal;
    //     }
    //     return true;
    //   },
    //   cellStyle: { fontSize: "12px" },
    // },
    // {
    //   field: "installment_amt",
    //   filter:'agNumberColumnFilter',
    //   headerName: "Installment Amount",
    //   valueGetter: installmentAmount,
    //   minWidth: 170,
    //   valueSetter: function (params: any) {
    //     var newVal = Number(params.newValue);
    //     var valueChanged = Number(params.data.installment_amt)!== newVal;
    //     if (valueChanged) {
    //       params.data.installment_amt = newVal;
    //     }
    //     return true;
    //   },
    //   cellStyle: { fontSize: "12px" },
    // },
    // {
    //   field: "emi_amount",
    //   headerName: "EMI Amount",
    //   editable: true,
    //   valueGetter: emiAmount,
    //   minWidth: 140,
    //   valueSetter: function (params: any) {
    //     var newVal = Number(params.newValue);
    //     var valueChanged = Number(params.data.emi_amount)!== newVal;
    //     if (valueChanged) {
    //       params.data.emi_amount = newVal;
    //     }
    //     return true;
    //   },
    // },
    // {
    //   field: "first_date",
    //   headerName: "EMI Date (DD/MM/YYYY)",
    //   filterParams: dateFilterParams,
    //   filter: "agDateColumnFilter",
    //   editable: true,
    //   valueGetter: parseDateEMI,
    //   minWidth: 180,
    //   valueSetter: function (params: any) {
    //     var newVal = params.newValue;
    //     var valueChanged = params.data.first_date!== newVal;
    //     if (valueChanged) {
    //       params.data.first_date = newVal;
    //     }
    //     return true;
    //   },
    //   cellStyle: { fontSize: "12px" },
    // },
    // {
    //   field: "pre_emi_date",
    //   headerName: "Pre Emi Date",
    //   valueGetter: preEmiDate,
    //   valueSetter: function (params: any) {
    //     var newVal = params.newValue;
    //     var valueChanged = params.data.pre_emi_date!== newVal;
    //     if (valueChanged) {
    //       params.data.pre_emi_date = newVal;
    //     }
    //     return true;
    //   },
    //   minWidth: 140,
    //   cellStyle: { fontSize: "12px" },
    // },
    // {
    //   field: "interest",
    //   filter:'agNumberColumnFilter',
    //   headerName: "Interest Amount",
    //   valueGetter: interestAmount,
    //   minWidth: 140,
    //   valueSetter: function (params: any) {
    //     var newVal = Number(params.newValue);
    //     var valueChanged = Number(params.data.interest)!== newVal;
    //     if (valueChanged) {
    //       params.data.interest = newVal;
    //     }
    //     return true;
    //   },
    //   cellStyle: { fontSize: "12px" },
    // },
    // {
    //   field: "pre_emi_interest",
    //   filter:'agNumberColumnFilter',
    //   headerName: "Pre Emi Interest",
    //   valueGetter: peI,
    //   minWidth: 140,
    //   valueSetter: function (params: any) {
    //     var newVal = Number(params.newValue);
    //     var valueChanged = Number(params.data.pre_emi_interest)!== newVal;
    //     if (valueChanged) {
    //       params.data.pre_emi_interest = newVal;
    //     }
    //     return true;
    //   },
    //   cellStyle: { fontSize: "12px" },
    // },
    // {
    //   field: "disbursal_amount",
    //   filter:'agNumberColumnFilter',
    //   headerName: "Disbursal Amount",
    //   valueGetter: disbursalAmount,
    //   minWidth: 150,
    //   valueSetter: function (params: any) {
    //     var newVal = Number(params.newValue);
    //     var valueChanged = Number(params.data.disbursal_amount)!== newVal;
    //     if (valueChanged) {
    //       params.data.disbursal_amount = newVal;
    //     }
    //     return true;
    //   },
    //   cellStyle: { fontSize: "12px" },
    // },
    // {
    //   field: "amount_to_be_repaid",
    //   filter:'agNumberColumnFilter',
    //   headerName: "Amount to be Repaid",
    //   valueGetter: amountRepaid,
    //   minWidth: 170,
    //   valueSetter: function (params: any) {
    //     var newVal = Number(params.newValue);
    //     var valueChanged = Number(params.data.amount_to_be_repaid)!== newVal;
    //     if (valueChanged) {
    //       params.data.amount_to_be_repaid = newVal;
    //     }
    //     return true;
    //   },
    //   cellStyle: { fontSize: "12px" },
    // },
  ];

  function returnName(name: string) {
    let result = "";
    name.split("_").forEach((el) => {
      result = result + " " + el.toUpperCase();
    });
    return result;
  }

  const onRowSelected = (event: any) => {
    let selectedRowsTemp = gridApiRef.current.getSelectedRows().length;
    setSelectedRows(selectedRowsTemp);
  };

  const onSelectionChanged = async (event: any) => {
    let selectedRowsTemp = gridApiRef.current.getSelectedRows().length;
    let displayedRows = gridApiRef.current.getDisplayedRowCount();
    if (selectedRowsTemp == displayedRows) {
      // header checkbox selected
      let currentPage = gridApiRef.current.paginationGetCurrentPage();
      let start = currentPage * pagination;
      let end = start + pagination;
      let totalPage = gridApiRef.current.paginationGetTotalPages();
      if (totalPage !== 1) gridApiRef.current.deselectAll();
      // prev_selectedRows == displayedRows ? selectedRows ==  0 :   prev_selectedRows == 0
      if (totalPage !== 1) {
        // all selected
        for (let i = start; i < end; i++) {
          var rowNode = gridApiRef.current.getDisplayedRowAtIndex(i);
          if (rowNode) {
            rowNode.setSelected(true);
          }
        }
      }
    }
  };
  const onPaginationChanged = (event: any) => {
    if (event.newPage) {
      if (gridApiRef.current) {
        localStorage.setItem(
          "appraisal_page",
          gridApiRef.current.paginationGetCurrentPage()
        );
        setCurrentpage(gridApiRef.current.paginationGetCurrentPage());
        gridApiRef.current.deselectAll();
      }
    }
  };
  function handleChange(value: any) {
    if (value !== "COMPLETE") {
      SetDisbursalDate("");
    }
    setOption(value);
  }
  function onChangeUser(value: any) {
    setOptionUser(value);
  }
  function onChangePage(page: number, pageSize?: number) {
    if (pageSize) {
      setSize(pageSize);
    }
    setCurrentPage(page);
  }
  async function extractZip() {
    let selectedRows = gridApiRef.current.getSelectedRows();
    let data: any = [];
    selectedRows.forEach((element: any) => {
      data.push(element["loan_id"]);
    });
    if (selectedRows.length > 50) {
      message.warning("Please select less than 50 rows!.");
    } else {
      await exportZipFiles(data);
      message.success("Export will be sent to your email!");
    }
  }
  async function getExportedData() {
    let selectedRows: Array<any> = [];
    let result: Array<any> = [];
    try {
      selectedRows = gridApiRef.current.getSelectedRows();
      selectedRows.map((element: any) => {
        let hashNew: any = {};
        // father_name	address	borrower_id	requested_amount	full_name	mobile_number	whatsapp_no	pincode	internal_emp_id	request_status_comment	ops_request_status_comment	ops_id	request_ops_id	client_name	city
        //state	verified_ref_count	nbfc_name	date_of_enquiry	approval_date	date_of_birth	duplicate	active	pan	aadhaar	esign	enach	account_no	ifsc_code	gender	status	marital_status
        //request_id	loan_id	creditor_agent_code	is_loan_closed	creditor_utility_code	approved_amount	tenure
        //	irr	processing_fee	installment_amt	installment_count	esign_status	enach_status	first_date	role	emi_amount	pre_emi_interest	interest	salaries
        //disbursal_date	user_id	user_name	ops_username	user_assigned_at	Esign Url	Enach Url

        hashNew["Borrower Id"] = element["borrower_id"];
        hashNew["Channel"] = element["channel"];
        hashNew["Amount"] = element["amount"];
        hashNew["Loan Id"] = element["loan_id"];
        hashNew["Type"] = element["type"];
        hashNew["Received Date"] = moment(element["received_date"]).format(
          "DD-MM-YYYY"
        );
        result.push(hashNew);
      });

      setExportUIRows(result);
    } catch (err) {
      message.error("Failed to Export");
      setExportUIRows(result);
    }
  }
  useEffect(() => {
    if (exportUIRows.length > 0) {
      document.getElementById("download")?.click();
    }
  }, [exportUIRows]);
  return (
    <>
      {visible === true ? (
        <div style={{ backgroundColor: "#f0f0f0" }}>
          <p
            style={{
              textAlign: "center",
              marginLeft: "-5%",
              fontSize: "17px",
              marginBottom: "-1%",
            }}
          >
            {" "}
            <span style={{ color: "#002766" }}>{selectedRows}</span> rows
            selected
          </p>
          <Row
            style={{
              background: "",
              marginLeft: "1%",
              marginRight: "5%",
              marginTop: "1%",
              boxShadow: "10px 10px grey",
            }}
          >
            <Col span={6} style={{ padding: "1%" }}>
              {/* <p style={{fontWeight:380, fontSize:"15px"}}>Toolbar</p> */}
              <div>
                <RetweetOutlined
                  style={{ fontSize: "20px" }}
                  onClick={deselectAll}
                />{" "}
                <span
                  onClick={deselectAll}
                  style={{ cursor: "pointer", marginRight: "8%" }}
                >
                  Deselect Rows
                </span>
                <DoubleLeftOutlined
                  style={{ fontSize: "20px", marginTop: "2%" }}
                  onClick={resetAllFilters}
                />{" "}
                <span onClick={resetAllFilters} style={{ cursor: "pointer" }}>
                  Reset Filters
                </span>
              </div>
            </Col>
            <Col span={5} style={{ padding: "1%" }}>
              <Input
                placeholder="Loan Id"
                value={whatsapp_no}
                onChange={(e) => {
                  setWhatsappNumber(e.target.value);
                }}
              />
            </Col>

            <Col span={5} style={{ padding: "1%" }}>
              <Input
                placeholder="Mobile No"
                value={mobile_number}
                onChange={(e) => {
                  setMobileNumber(e.target.value);
                }}
              />
            </Col>
            <Col span={6} style={{ padding: "1%" }}>
              <RangePicker
                format="DD-MM-YYYY"
                onChange={onDateChange}
                defaultValue={[moment(start_date), moment(end_date)]}
              />
            </Col>
            <Col span={2} style={{ background: "#f0f0f0", padding: "1%" }}>
              {/* <Tooltip title="Click here to update all data">
                  <DiffTwoTone
                    style={{
                      fontSize: "20px",
                      cursor: "pointer",
                      marginRight: "20%",
                    }}
                    onClick={() => {
                      console.log("sdcsdsdfsdf");
                      updateAllRowsScreen();
                    }}
                  />
                </Tooltip> */}
              <Tooltip title="Click here for actions">
                <AlignCenterOutlined
                  style={{ fontSize: "20px", cursor: "pointer" }}
                  onClick={showDrawer}
                />
              </Tooltip>
            </Col>
          </Row>
          <Drawer
            title="Actions"
            placement="right"
            onClose={onClose}
            visible={isDrawer}
            width={500}
          >
            {/* <Card style={{ padding: "", marginTop: "4%" }}>
              <Col>
                <h6 style={{ marginBottom: "5%" }}>Update Status</h6>
              </Col>
              <Col span={24}>
                <Select
                  defaultValue="Please select an option"
                  style={{ width: "100%", marginBottom: "2%" }}
                  onChange={handleChange}
                >
                  {states.map((element: any) => {
                    return <Option value={element}>{element}</Option>;
                  })}
                </Select>
                {option == "COMPLETE" ? (
                  <>
                    <h6 style={{ marginBottom: "5%", marginTop: "1%" }}>
                      Disbursal Date
                    </h6>
                    <Input
                      type="date"
                      value={disbursal_date}
                      onChange={(e) => {
                        SetDisbursalDate(e.target.value);
                      }}
                    ></Input>
                  </>
                ) : (
                  ""
                )}
              </Col>
              <Col style={{ marginTop: "5%" }}>
                <Button
                  type="primary"
                  block
                  onClick={() => {
                    onButtonClick();
                  }}
                >
                  Bulk Update
                </Button>
              </Col>

              <Col span={24} style={{ marginTop: "5%" }}></Col>
            </Card> */}

            <Card style={{ padding: "", marginTop: "4%" }}>
              <Col>
                <h6 style={{ marginBottom: "5%" }}>Page Size</h6>
              </Col>
              <Col span={24}>
                <Input
                  type="number"
                  value={pagination}
                  onChange={(e) => {
                    setPagination(Number(e.target.value));
                  }}
                ></Input>
              </Col>
            </Card>

            {/* <Card style={{ padding: "", marginTop: "4%" }}>
              <Col span={24} style={{ marginTop: "5%" }}>
                <Col>
                  <h6 style={{ marginBottom: "5%" }}>Enach</h6>
                </Col>
                <Button
                  block
                  type="primary"
                  onClick={() => {
                    eNach();
                  }}
                >
                  Generate
                </Button>
              </Col>
            </Card> */}
            {/* <Card style={{ padding: "", marginTop: "4%" }}>
              <Col>
                <h6 style={{ marginBottom: "5%" }}>E-Signature</h6>
              </Col>
              <Button
                type="primary"
                block
                onClick={() => {
                  eSign();
                }}
              >
                Generate
              </Button>
            </Card> */}
            <Card style={{ padding: "", marginTop: "4%" }}>
              {/* <Select
                defaultValue="Select Company"
                style={{ width: "100%", marginBottom: "2%" }}
                onChange={(value: any) => {
                  setCompany(value);
                }}
              >
                {nbfcstatus && companyOptions.length > 0
                  ? companyOptions.map((element: any) => {
                    return (
                      <Option value={element?.id}>{element?.name}</Option>
                    );
                  })
                  : ""}
              </Select> */}
              <Button
                type="primary"
                block
                onClick={() => {
                  createRepaymentExport(start_date, end_date);
                }}
              >
                Export Data
              </Button>
            </Card>
            <Card style={{ padding: "", marginTop: "4%" }}>
              <Col style={{ marginTop: "5%" }}>
                <CSVLink
                  id="download"
                  style={{ visibility: "hidden" }}
                  data={exportUIRows}
                >
                  Download me
                </CSVLink>

                <Button
                  type="primary"
                  block
                  onClick={async () => {
                    if (selectedRows == 0) {
                      message.warning("Please select a row!");
                    } else {
                      await getExportedData();
                    }
                  }}
                >
                  Export Selected Rows
                </Button>

                {/* <Button
                        type="primary"
                        block
                        onClick={() => {
  
                          
                          gridApiRef.current.exportDataAsCsv({onlySelected:true
                          });
                        }}
                      >
                        Export Selected Rows
                      </Button> */}
              </Col>
            </Card>

            {/* 
            {role == "admin" ? (
              <>
                <Card style={{ padding: "", marginTop: "4%" }}>
                  <Col>
                    <h6 style={{ marginBottom: "5%" }}>Assign User</h6>
                  </Col>
                  <Col span={24}>
                    <Select
                      defaultValue="Please select an option"
                      style={{ width: "100%" }}
                      onChange={onChangeUser}
                    >
                      {users.map((element: any) => {
                        return (
                          <Option value={element["id"]}>
                            {element["user_name"]}
                          </Option>
                        );
                      })}
                    </Select>
                  </Col>
                  <Col style={{ marginTop: "5%" }}>
                    <Button
                      type="primary"
                      block
                      onClick={() => {
                        assignUser();
                      }}
                    >
                      Assign
                    </Button>
                  </Col>
                </Card>
              </>
            ) : (
              ""
            )} */}

            {(role === "admin" || role === "super_admin") ? (
              <>
                <Card style={{ padding: "", marginTop: "4%" }}>
                  <Col>
                    <h6 style={{ marginBottom: "5%" }}>
                      Upload Repayments sheet
                    </h6>
                  </Col>
                  <Col span={24}>
                    <Upload {...propsNewDocument}>
                      <Button
                        style={{ width: "160%" }}
                        icon={<UploadOutlined />}
                      >
                        Upload a new Document
                      </Button>
                    </Upload>
                  </Col>
                </Card>
              </>
            ) : (
              ""
            )}
          </Drawer>
          <Row>
            <Col span={24}>
              <div
                className="ag-theme-alpine"
                style={{
                  height: height * 0.8,
                  width: width * 0.87,
                  paddingLeft: "1%",
                  paddingRight: "",
                  paddingTop: "1%",
                  marginTop: "",
                  fontSize: "12px",
                }}
              >
                <AgGridReact
                  ref={gridRef}
                  rowData={rowData}
                  rowSelection="multiple"
                  suppressRowClickSelection={true}
                  columnDefs={columnDefs}
                  groupDisplayType="singleColumn"
                  onGridReady={onGridReady}
                  onRowSelected={onRowSelected}
                  enableCellTextSelection={true}
                  onSelectionChanged={onSelectionChanged}
                  onPaginationChanged={onPaginationChanged}
                  defaultColDef={{
                    flex: 1,
                    resizable: true,
                    minWidth: 70,
                  }}
                  onFilterChanged={() => {
                    const filter = gridApiRef.current.getFilterModel();
                    dispatch({
                      type: ActionType.APPRAISALFILTER,
                      payload: filter,
                    });
                    localStorage.setItem(
                      "appraisalFilter",
                      JSON.stringify(filter)
                    );
                  }}
                  pagination={true}
                  paginationPageSize={pagination}
                  frameworkComponents={{
                    btnCellRenderer: BtnCellRenderer,
                    appraisalButton: AppraisalBtnCellRenderer,
                    repaymentButton: RepaymentCellRenderer,
                    document: Document,
                    employee: Employee,
                    active_request: Activerequest,
                    enach: Enach,
                    document_verified: DocumentsVerified,
                    enach_generated: EnachGenerated,
                    esign: Esign,
                    duplicate_loan: DuplicateLoan,
                    active_loan: ActiveLoan,
                    is_loan_closed: LoanClosed,
                  }}
                ></AgGridReact>
              </div>
              {/* <Pagination
                  style={{ marginLeft: "40%", marginTop: "1%" }}
                  current={currentpage}
                  onChange={onChangePage}
                  total={total}
                  defaultPageSize={20}
                /> */}
              ;
            </Col>
          </Row>

          <Modal
            title="Parameters"
            visible={isModalVisible}
            onOk={handleOk}
            onCancel={handleCancel}
          >
            <Row>
              <Col span={24}>
                <Form
                  form={form}
                  layout="vertical"
                  initialValues={{ requiredMarkValue: requiredMark }}
                  onValuesChange={onRequiredTypeChange}
                  requiredMark={requiredMark}
                >
                  <Form.Item
                    label="Processing Fee"
                    required
                    tooltip="This is a required field"
                  >
                    <Input
                      placeholder="processing fee"
                      type="number"
                      value={processingfee}
                      onChange={(e) => {
                        setProcessingFee(e.target.value);
                      }}
                    />
                  </Form.Item>
                  <Form.Item
                    label="Interest Rate(%)"
                    required
                    tooltip="This is a required field"
                  >
                    <Input
                      placeholder="rate"
                      type="number"
                      value={interestrate}
                      onChange={(e) => {
                        setInterestRate(e.target.value);
                      }}
                    />
                  </Form.Item>

                  <Form.Item
                    label="Type"
                    required
                    tooltip="This is a required field"
                  >
                    <Input
                      placeholder="type"
                      type="text"
                      value={type}
                      onChange={(e) => {
                        setType(e.target.value);
                      }}
                    />
                  </Form.Item>

                  <Form.Item
                    label="Approved Amount"
                    required
                    tooltip="This is a required field"
                  >
                    <Input
                      placeholder="amount"
                      type="number"
                      value={approvedamount}
                      onChange={(e) => {
                        setApprovedAmount(e.target.value);
                      }}
                    />
                  </Form.Item>

                  <Form.Item
                    label="Period in Weeks"
                    required
                    tooltip="This is a required field"
                  >
                    <Input
                      placeholder="period"
                      type="number"
                      value={period}
                      onChange={(e) => {
                        setPeriod(e.target.value);
                      }}
                    />
                  </Form.Item>

                  <Form.Item>
                    <Button type="primary">Submit</Button>
                  </Form.Item>
                </Form>
              </Col>
            </Row>
          </Modal>
        </div>
      ) : (
        <Spin size="large" style={{ marginLeft: "40%", marginTop: "20%" }} />
      )}
    </>
  );
}
