import React, {useEffect, useState} from "react";
import { useSelector } from "react-redux";
import { Divider, Table, Space } from "antd";
import type { ColumnsType } from "antd/es/table";
import {HighlightOutlined, DeleteTwoTone} from '@ant-design/icons'
import { ExistingLoan, ExistingLoanRequest, MainReducer } from "../../types";
import { getExistingLoans, getExistingLoanRequests } from "../../utitlities/request";
import moment from "moment";

interface Existing_Loan extends ExistingLoan, ExistingLoanRequest{

}

const ExistingLoans: React.FC = () => {
  const employee = useSelector((state: MainReducer) => {
    return state.loan.employee.body;
  });
  const [existingLoansData, setExistingLoansData] = useState([]);
  const [existingLoanRequestsData, setExistingLoanRequestsData] = useState([]);
  async function getExistingLoansData(empId: any) {
    const existingLoansResponse = await getExistingLoans(empId);
    const newLoansData = existingLoansResponse.data.map((loan:any) => ({
      loan_id: loan.id,
      ops_id: loan.ops_id,
      borrower_id: loan.borrower_id,
      loan_status: loan.request_status,
      category:loan.category,
      dpd:loan.DPD_bucket,
      salary_deduction:loan.salary_deduction_ratio
    }));
    setExistingLoansData(newLoansData);

    const existingLoanRequestsResponse = await getExistingLoanRequests(empId);
      const newLoanRequestsData = existingLoanRequestsResponse.data.map((loanRequest:any) => ({
        request_id: loanRequest.id,
        request_status: loanRequest.request_status,
        request_date: moment.utc(loanRequest.created_at).format('DD-MM-YYYY')
      }));
      setExistingLoanRequestsData(newLoanRequestsData);
  }

  useEffect(()=>{
    getExistingLoansData(employee.id);
  },[])

  const req_columns: ColumnsType<Existing_Loan> = [
    {
      title: "Request ID",
      dataIndex: "request_id",
      key: "request_id",
    },
    {
      title: "Request Status",
      dataIndex: "request_status",
      key: "request_status",
    },
    {
      title: "Request Date",
      dataIndex: "request_date",
      key: "request_date",
    }
  ];
  const loan_columns: ColumnsType<Existing_Loan> = [
    {
      title: "Loan ID",
      dataIndex: "loan_id",
      key: "loan_id",
    },
    {
      title: "Ops ID",
      dataIndex: "ops_id",
      key: "ops_id",
    },
    {
      title: "Borrower ID",
      dataIndex: "borrower_id",
      key: "borrower_id",
    },
    {
      title: "Loan Status",
      dataIndex: "loan_status",
      key: "loan_status",
    },
    {
      title: "Category",
      dataIndex: "category",
      key: "category",
    },
    {
      title: "DPD",
      dataIndex: "dpd",
      key: "dpd",
    },
    {
      title: "Salary Deduction",
      dataIndex: "salary_deduction",
      key: "salary_deduction",
    },
  ];

  return (
    <div className="emp-info-form">
      <div className="title">Existing Loans</div>
      <Divider style={{ borderColor: "#E5E7EA", margin: "20px 0px" }} />
      <div style={{ display: 'flex' }}>
        <Table columns={req_columns} dataSource={existingLoanRequestsData} style={{ flex: 1, marginRight: '8px' }}/>
        <Table columns={loan_columns} dataSource={existingLoansData} style={{ flex: 1, marginLeft: '8px' }}/>
      </div>
      <div className="form01"></div>
    </div>
  );
};
export default ExistingLoans;
