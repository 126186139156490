import { Button, Tooltip } from "antd";
import { useHistory } from "react-router-dom";
import { EyeOutlined } from "@ant-design/icons";

export function RepaymentCellRenderer(props: any) {
  const history = useHistory();
  const redirectToEmployeeDetails = () => {
    history.push(`/repayments?id=${props["data"]["loan_id"]}`);
  };
  return (
    <Tooltip title={"Click here to go to details"}>
      <Button
        type="text"
        icon={<EyeOutlined />}
        onClick={redirectToEmployeeDetails}
      />
    </Tooltip>
  );
}
