import { Row, Col, Typography, Spin, Divider } from 'antd'
import { HighlightOutlined, BankFilled } from "@ant-design/icons";
const { Text } = Typography;

export const EmpBankDetails = (props: any) => {
	props = props.props;
	return (<div>
		<Row>
			<Col
				span={6}
				style={{ padding: "2%", background: "#f5f5f5" }}
			>
				<h6>
					<b>IFSC Code</b>
				</h6>
				<Text
					style={{ fontSize: "14px" }}
					editable={{
						icon: <HighlightOutlined />,
						tooltip: "click to edit text",
						onChange: props.setIfsc,
					}}
				>
					{props.ifsc}
				</Text>
			</Col>
			<Col span={6} style={{ padding: "2%" }}>
				<h6>
					<b>Account Number</b>
				</h6>
				<Text
					style={{ fontSize: "14px" }}
					editable={{
						icon: <HighlightOutlined />,
						tooltip: "click to edit text",
						onChange: props.setAccount,
					}}
				>
					{props.account}
				</Text>
			</Col>

			<Col
				span={6}
				style={{ padding: "2%", background: "#f5f5f5" }}
			>
				<h6>
					<b>Bank Name</b>
				</h6>
				<Text style={{ fontSize: "14px" }}>{props.bank_name}</Text>
			</Col>

			<Col span={6} style={{ padding: "2%" }}>
				<h6>
					<b>Branch</b>
				</h6>
				<Text style={{ fontSize: "14px" }}>{props.bank_branch}</Text>
			</Col>
		</Row>

		<Row>
			<Col span={6} style={{ padding: "2%" }}>
				<h6>
					<b>State</b>
				</h6>
				<Text style={{ fontSize: "14px" }}>{props.bank_state}</Text>
			</Col>
			<Col
				span={6}
				style={{ padding: "2%", background: "#f5f5f5" }}
			>
				<h6>
					<b>Bank Code</b>
				</h6>
				<Text style={{ fontSize: "14px" }}>{props.bank_code}</Text>
			</Col>
			<Col span={6} style={{ padding: "2%" }}>
				<h6>
					<b>Bank Statement Password</b>
				</h6>
				<Text
					style={{ fontSize: "14px" }}
					editable={{
						icon: <HighlightOutlined />,
						tooltip: "click to edit text",
						onChange: props.setPassword,
					}}
				>
					{props.bank_statement_password}
				</Text>
			</Col>
			<Col
				span={6}
				style={{ padding: "2%", background: "#f5f5f5" }}
			>
				<h6>
					<b>Beneficiary Name</b>
				</h6>
				<Text
					style={{ fontSize: "14px" }}
					editable={{
						icon: <HighlightOutlined />,
						tooltip: "click to edit text",
						onChange: props.setBeneficiaryName,
					}}
				>
					{props.beneficiary_name}
				</Text>
			</Col>
		</Row>
		<Row>
			<Col
				span={6}
				style={{ padding: "2%", background: "#f5f5f5" }}
			>
				<h6>
					<b>ABB</b>
				</h6>
				<Text style={{ fontSize: "14px" }}>{props.abb || 0}</Text>
			</Col>
			{props.type === "appraisal" ? (
				<>
					<Col span={6} style={{ padding: "2%" }}>
						<h6>
							<b>Latest Cibil</b>
						</h6>
						<Text style={{ fontSize: "14px" }}>{props.cibil || 0}</Text>
					</Col>

					<Col
						span={6}
						style={{ padding: "2%", background: "#f5f5f5" }}
					>
						<h6>
							<b>NBFC Loan App ID</b>
						</h6>
						<Text style={{ fontSize: "14px" }}>
							{props.arthmateLoanId || "NOT KNOWN"}
						</Text>
					</Col>
					<Col span={6} style={{ padding: "2%" }}>
						<h6>
							<b>NBFC Emp ID</b>
						</h6>
						<Text style={{ fontSize: "14px" }}>
							{props.arthmateEmpId || "NOT KNOWN"}
						</Text>
					</Col>
				</>
			) : (
				""
			)}
		</Row>
		<Row>
			<Col span={6} style={{ padding: "2%" }}>
				<h6>
					<b>ABB Required</b>
				</h6>
				<Text
					style={{ fontSize: "14px" }}
					editable={{
						icon: <HighlightOutlined />,
						tooltip: "click to edit text",
						onChange: props.setAbbModified,
					}}
				>
					{props.abb_modified || 0}
				</Text>
			</Col>
			{props.type === "appraisal" ? (
				<>
					<Col
						span={6}
						style={{ padding: "2%", background: "#f5f5f5" }}
					>
						<h6>
							<b>NBFC Loan ID</b>
						</h6>
						<Text style={{ fontSize: "14px" }}>
							{props.arthmatePostLoanId || "NOT KNOWN"}
						</Text>
					</Col>
					<Col span={6} style={{ padding: "2%" }}>
						<h6>
							<b>NBFC Status</b>
						</h6>
						<Text style={{ fontSize: "14px" }}>
							{props.nbfcStatus || "NOT KNOWN"}
						</Text>
					</Col>
					<Col
						span={6}
						style={{ padding: "2%", background: "#f5f5f5" }}
					>
						<h6>
							<b>NBFC Error</b>
						</h6>
						<Text style={{ fontSize: "14px" }}>
							{props.nbfcError || "NOT KNOWN"}
						</Text>
					</Col>
				</>
			) : (
				""
			)}
		</Row>
		<Row>
			<Col span={5} style={{ paddingRight: "5%" }}>
				{" "}
				<Divider style={{ fontFamily: "", fontSize: "20px" }}>
					{" "}
					<span style={{ fontWeight: 340 }}>Analyse Statement</span>
					<BankFilled
						style={{ margin: "7%", fontSize: "30px" }}
						onClick={async () => {
							await props.analyseStatements(props.employee_id);
						}}
					/>
				</Divider>
			</Col>
			{props.type === "appraisal" ? (
				<Col span={6} style={{ paddingRight: "5%" }}>
					{" "}
					<Divider style={{ fontFamily: "", fontSize: "20px" }}>
						{" "}
						{props.loading === true ? (
							<div>
								<Spin size="large" style={{ marginLeft: "40%", marginTop: "20%" }} />
							</div>
						) : (
							<><span style={{ fontWeight: 340 }}>Generate Cibil</span>
								<BankFilled
									style={{ margin: "7%", fontSize: "30px" }}
									onClick={() => {
										props.callCibil();
									}} /></>
						)}
					</Divider>
				</Col>
			) : (
				""
			)}
			{props.type === "appraisal" ? (
				<Col span={6} style={{ paddingRight: "5%" }}>
					{" "}
					<Divider style={{ fontFamily: "", fontSize: "20px" }}>
						{" "}
						{props.loading === true ? (
							<div>
								<Spin size="large" style={{ marginLeft: "40%", marginTop: "20%" }} />
							</div>
						) : (
							<><span style={{ fontWeight: 340 }}>Validate Bank</span>
								<BankFilled
									style={{ margin: "7%", fontSize: "30px" }}
									onClick={() => {
										props.callValidateBank();
									}} /></>
						)}
					</Divider>
				</Col>
			) : (
				""
			)}
			{props.type === "appraisal" ? (
				<Col
					span={6}
					style={{ marginLeft: "4%", padding: "2%", background: "#f5f5f5" }}
				>
					<h6>
						<b>Bank Account Status</b>
					</h6>
					<Text style={{ fontSize: "14px" }}>
						{props.bankStatus}
					</Text>
				</Col>
			) : (
				""
			)}
		</Row>
	</div>);
}
