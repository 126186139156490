import { AppraisalReducer } from "../../types";
import { ActionType } from "../action-types/index";
import { Action } from "../actions";
import moment from "moment";

export const appraisalApiParamsInitState = {
    page: 1,
    pageSize: 50,
    filterData: {
        whatsapp_no: "",
        status: "",
        start_date: moment().subtract(2, "month").format("YYYY-MM-DD"),
        end_date: moment().add(1, "days").format("YYYY-MM-DD")
    },
    filterModel: null,
    sortModel: null
}
const initialState: AppraisalReducer = {
    appraisalApiParams: { ...appraisalApiParamsInitState },
    appraisalApiResponse: {
        response: null,
        status: false,
        loading: false
    },
};

const reducer = (state: AppraisalReducer = initialState, action: Action): AppraisalReducer => {
    switch (action.type) {

        case ActionType.SET_APPRAISAL_API_PARAMS:
            return {
                ...state,
                appraisalApiParams: action.payload
            }

        case ActionType.SET_APPRAISAL_LIST:
            if (action.payload.status && action?.payload?.data && action.payload.status === 
                200) {
                return { ...state, ...{ appraisalApiResponse: { response: action?.payload?.data
                    , status: true, loading: false } } };
            }
            else {
                return { ...state, ...{ appraisalApiResponse: { response: null, status: false, loading: false } } };
            }
        case ActionType.GET_APPRAISAL_LIST_API_LOADING:
            return { ...state, ...{ appraisalApiResponse: { ...state.appraisalApiResponse, loading: action?.payload } } };
             
        default:
            return state
    }
}

export default reducer