import { Row, Col, Typography, Input } from 'antd'
import { HighlightOutlined } from "@ant-design/icons";
const { Text } = Typography;
export const PersonalDetails = (props: any) => {
	props = props.props
	return (
		<div>
			<Row>
				<Col span={6} style={{ padding: "2%" }}>
					<h6>
						<b>First Name</b>
					</h6>
					<Text
						style={{ fontSize: "14px" }}
						editable={{
							icon: <HighlightOutlined />,
							tooltip: "click to edit text",
							onChange: props.setFirstName,
						}}
					>
						{props.first_name}
					</Text>
				</Col>
				<Col span={6} style={{ padding: "2%", background: "#f5f5f5" }}>
					<h6>
						<b>Last Name</b>
					</h6>
					<Text
						style={{ fontSize: "14px" }}
						editable={{
							icon: <HighlightOutlined />,
							tooltip: "click to edit text",
							onChange: props.setLastName,
						}}
					>
						{props.last_name}
					</Text>
				</Col>

				<Col span={6} style={{ padding: "2%" }}>
					<h6>
						<b>Father Name</b>
					</h6>
					<Text
						style={{ fontSize: "14px" }}
						editable={{
							icon: <HighlightOutlined />,
							tooltip: "click to edit text",
							onChange: props.setFatherName,
						}}
					>
						{props.fathername}
					</Text>
				</Col>

				<Col
					span={6}
					style={{ padding: "2%", background: "#f5f5f5" }}
				>
					<h6>
						<b>Date of Birth</b>
					</h6>
					<Input
						type="date"
						value={props.dob?.split("T")[0]}
						onChange={(e) => {
							props.setDob(e.target.value);
						}}
					/>
				</Col>
			</Row>
			<Row>
				<Col
					span={6}
					style={{ padding: "2%", background: "#f5f5f5" }}
				>
					<h6>
						<b>Email</b>
					</h6>
					<Text
						style={{ fontSize: "14px" }}
						editable={{
							icon: <HighlightOutlined />,
							tooltip: "click to edit text",
							onChange: props.setEmail,
						}}
					>
						{props.email}
					</Text>
				</Col>

				<Col span={6} style={{ padding: "2%" }}>
					<h6>
						<b>Mobile</b>
					</h6>
					<Text style={{ fontSize: "14px" }}>{props.mobile}</Text>
				</Col>
				<Col
					span={6}
					style={{ padding: "2%", background: "#f5f5f5" }}
				>
					<h6>
						<b>Gender</b>
					</h6>
					<Text
						style={{ fontSize: "14px" }}
						editable={{
							icon: <HighlightOutlined />,
							tooltip: "click to edit text",
							onChange: props.setGender,
						}}
					>
						{props.gender}
					</Text>
				</Col>
				<Col span={6} style={{ padding: "2%" }}>
					<h6>
						<b>Marital Status</b>
					</h6>
					<Text
						style={{ fontSize: "14px" }}
						editable={{
							icon: <HighlightOutlined />,
							tooltip: "click to edit text",
							onChange: props.setMaritalStatus,
						}}
					>
						{props.maritalStatus}
					</Text>
				</Col>
			</Row>
			<Row>
				<Col span={6} style={{ padding: "2%" }}>
					<h6>
						<b>Pan Number</b>
					</h6>
					<Text
						style={{ fontSize: "14px" }}
						editable={{
							icon: <HighlightOutlined />,
							tooltip: "click to edit text",
							onChange: props.setPan,
						}}
					>
						{props.pan}
					</Text>
				</Col>
				<Col
					span={6}
					style={{ padding: "2%", background: "#f5f5f5" }}
				>
					<h6>
						<b>Aadhaar</b>
					</h6>
					<Text
						style={{ fontSize: "14px" }}
						editable={{
							icon: <HighlightOutlined />,
							tooltip: "click to edit text",
							onChange: props.setAadhaar,
						}}
					>
						{props.aadhaar}
					</Text>
				</Col>
				<Col span={6} style={{ padding: "2%" }}>
					<h6>
						<b>Temporary Contact Number</b>
					</h6>
					<Text
						style={{ fontSize: "14px" }}
						editable={{
							icon: <HighlightOutlined />,
							tooltip: "click to edit text",
							onChange: props.setTempMobile,
						}}
					>
						{props.temp_mobile}
					</Text>
				</Col>
				<Col span={6} style={{ padding: "2%" }}>
					<h6>
						<b>Employee ID (With Domain)</b>
					</h6>
					<Text style={{ fontSize: "14px" }}>
						{props.temp["client_employee_id"]}
					</Text>
				</Col>
			</Row>
			<Row>
				<Col
					span={6}
					style={{ padding: "2%", background: "#f5f5f5" }}
				>
					<h6>
						<b>Employee ID (Without Domain)</b>
					</h6>
					<Text style={{ fontSize: "14px" }}>
						{props.temp["client_employee_id"]?.includes("$")
							? props.temp["client_employee_id"]?.split("$")[0]
							: ""}
					</Text>
				</Col>

				<Col span={6} style={{ padding: "2%" }}>
					<h6>
						<b>Employee Operations ID </b>
					</h6>
					<Text style={{ fontSize: "14px" }}>{props.opsId}</Text>
				</Col>

				<Col
					span={6}
					style={{ padding: "2%", background: "#f5f5f5" }}
				>
					<h6>
						<b>WhatsApp Number </b>
					</h6>
					<Text
						editable={{
							icon: <HighlightOutlined />,
							tooltip: "click to edit text",
							onChange: props.setWhatsAppNo,
						}}
						style={{ fontSize: "14px" }}
					>
						{props.whatsapp_no}
					</Text>
				</Col>
			</Row>
		</div>
	)
};
