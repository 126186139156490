import { Tooltip } from "antd";
import { Link } from "react-router-dom";
export function AppraisalBtnCellRenderer(props: any) {
  return (
    <Tooltip title={"Click here to go to details"}>
      <Link
        type="primary"
        to={`/employees?request_id=${props["data"]["request_id"]}&employee_id=${props["data"]["internal_emp_id"]}&type=appraisal&loan_id=${props["data"]["loan_id"]}`}
        className="btn btn-primary"
      >
        Show Details
      </Link>
    </Tooltip>
  );
}
