import { AgGridReact } from "ag-grid-react";

type AgGridComponentProps = {
  rowData: any;
  columnDefs: any;
  onSortChanged: (event: any) => void;
  onFilterChanged: () => void;
  onRowDataChanged: (event: any) => void;
  onRowSelected: (event: any) => void;
  defaultColDef: any;
  frameworkComponents: any;
  totalPages: number;
  onGridReady: any;
};

const AgGridComponent = ({
  rowData,
  columnDefs,
  onFilterChanged,
  onSortChanged,
  onRowDataChanged,
  onRowSelected,
  defaultColDef,
  frameworkComponents,
  onGridReady,
}: AgGridComponentProps): JSX.Element => {
  return (
    <>
      <AgGridReact
        rowData={rowData}
        columnDefs={columnDefs}
        pagination={false}
        paginationPageSize={10}
        cacheBlockSize={10}
        maxConcurrentDatasourceRequests={1}
        infiniteInitialRowCount={0}
        onGridReady={onGridReady}
        animateRows={true}
        rowSelection="multiple"
        groupDisplayType="singleColumn"
        suppressRowClickSelection={true}
        onRowSelected={onRowSelected}
        onFilterChanged={onFilterChanged}
        onSortChanged={onSortChanged}
        onRowDataChanged={onRowDataChanged}
        enableCellTextSelection={true}
        paginationAutoPageSize={false}
        defaultColDef={defaultColDef}
        frameworkComponents={{
          ...frameworkComponents,
        }}
      ></AgGridReact>
    </>
  );
};
export default AgGridComponent;
