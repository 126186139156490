import { Row, Col, Tooltip, Button } from "antd";
import { LogoutOutlined, ArrowLeftOutlined } from "@ant-design/icons";
import { useLocation, useHistory } from "react-router-dom";
export const TopHeader = () => {
  let location = useLocation();
  let history = useHistory();
  const search = location.search;
  const type = new URLSearchParams(search).get("type") || "";
  const gridViewMap = {
    'appraisal':'appraisal',
    'operations':'ops_review'
  }
  return (
    <Row
      style={{
        background: "white",
        boxShadow: "0px 2px 4px 0px rgba(0, 0, 0, 0.08)",
        height: "50px",
        minHeight: "50px",
        width: "100%",
        minWidth: "100%",
        maxWidth: "100%",
      }}
    >
      <Col span={22}>
        {location.pathname && (location.pathname.includes("appraisal-detail") || location.pathname.includes("employees") ) ? (
          <Button
            style={{
              display: "flex",
              alignItems: "center",
              marginTop: "8px",
              fontSize: "16px",
              color: "#000",
              fontWeight: 600,
            }}
            type="link"
            onClick={() => type && history.push(gridViewMap[type as keyof typeof gridViewMap])}
            icon={<ArrowLeftOutlined />}
          >
            Back
          </Button>
        ) : null}
      </Col>
      <Col span={2}>
        {location.pathname && !location.pathname.includes("login") ? (
          <Tooltip title="Logout">
            <LogoutOutlined
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "flex-end",
                paddingRight: "15px",
                fontSize: "16px",
                height: "50px",
              }}
              onClick={() => {
                localStorage.clear();
                window.location.href = "/";
              }}
            />
          </Tooltip>
        ) : null}
      </Col>
    </Row>
  );
};
