import { Row, Col, Table, Divider } from 'antd'
import { PlusCircleTwoTone, } from "@ant-design/icons";

export const OpsComments = (props: any) => {
	props = props.props;
	return (
		<div>
			{props.CommentsStatus ? (
				<Table columns={props.commentCols} dataSource={props.comments} />
			) : (
				""
			)}
			<Row>
				<Col span={1}>
					{" "}
					<Divider style={{ fontFamily: "", fontSize: "20px" }}>
						{" "}
						<span style={{ fontWeight: 340 }}>Add Comment</span>
						<PlusCircleTwoTone
							style={{ margin: "5%", fontSize: "30px" }}
							onClick={() => {
								props.setIsCommentModalVisible(true);
							}}
						/>
					</Divider>
				</Col>
			</Row>
		</div>
	)
};
