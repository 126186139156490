import {
  Row,
  Col,
  Card,
  Pagination,
  Tooltip,
  Button,
  Drawer,
  message,
  DatePicker,
  Input,
  PaginationProps,
  Upload,
} from "antd";
import { useEffect, useState, useLayoutEffect, useRef } from "react";
import "ag-grid-community/dist/styles/ag-grid.css";
import "ag-grid-community/dist/styles/ag-theme-alpine.css";
import { Document } from "./icons/documents";
import { Employee } from "./icons/employee";
import { Activerequest } from "./icons/activerequests";
import { Enach } from "./icons/enach";
import moment from "moment";
import {
  AlignCenterOutlined,
  DoubleLeftOutlined,
  RetweetOutlined,
  UploadOutlined,
} from "@ant-design/icons";
import { CSVLink } from "react-csv";
import { DocumentsVerified } from "./icons/documentsVerified";
import { updateBulkUser } from "../utitlities/request";
import { OperationsStatusDataFetch } from "../utitlities/status";
import { useDispatch, useSelector } from "react-redux";
import {
  setMasterViewApiParams,
  getMasterViewList,
} from "../state/action-creators";
import AgGridComponent from "./common/AgGridComponent";
import { masterViewApiParamsInitState } from "../state/reducers/masterViewReducer";
import { RepaymentCellRenderer } from "./repaymentButton";
import { config } from "../config";

const { RangePicker } = DatePicker;
let setTimeoutId: any;

export default function MasterView() {
  const [gridApi, setGridApi] = useState<any>(null);
  const [selectedRows, setSelectedRows] = useState([]);
  const [height, setHeight] = useState(window.innerHeight);
  const [optionUser, setOptionUser] = useState("");
  const [isDrawer, setIsDrawer] = useState(false);
  const [pageSize, setPageSize] = useState(
    masterViewApiParamsInitState?.pageSize || 50
  );
  const [role, setRole] = useState("");
  const [exportUIRows, setExportUIRows] = useState<any>([]);

  const defaultColDef = {
    flex: 1,
    resizable: true,
  };
  const dispatch = useDispatch();
  const gridApiRef = useRef<any>(null);
  const masterViewApiParams = useSelector(
    (state: any) => state?.master?.masterViewApiParams
  );
  const filterData = masterViewApiParams?.filterData;
  const filterModel = masterViewApiParams?.filterModel;
  const sortModel = masterViewApiParams?.sortModel;

  const masterViewList = useSelector(
    (state: any) => state?.master?.masterViewApiResponse?.response
  );
  const masterViewApiLoader = useSelector(
    (state: any) => state?.master?.masterViewApiResponse?.loading
  );

  useEffect(() => {
    const user = JSON.parse(localStorage?.getItem("user") || "");
    setRole(user?.role);
    const _masterViewApiParams = localStorage?.getItem("MASTER_VIEW_API_PARAMS")
      ? // @ts-ignore
      JSON.parse(localStorage?.getItem("MASTER_VIEW_API_PARAMS"))
      : "";
    if (_masterViewApiParams) {
      dispatch(
        setMasterViewApiParams({
          ..._masterViewApiParams,
          page: 1,
        })
      );
      setPageSize(_masterViewApiParams?.pageSize);
      gridApiRef?.current?.setFilterModel(_masterViewApiParams?.filterModel);
      gridApiRef?.current?.setSortModel(_masterViewApiParams?.sortModel);
    }
  }, []);

  useEffect(() => {
    if (exportUIRows.length > 0) {
      document.getElementById("download")?.click();
    }
  }, [exportUIRows]);

  useEffect(() => {
    if (gridApi) {
      if (masterViewApiLoader) {
        gridApi.showLoadingOverlay();
      } else {
        gridApi.hideOverlay();
      }
    }
  }, [masterViewApiLoader, gridApi]);

  useEffect(() => {
    if (setTimeoutId) clearTimeout(setTimeoutId);
    setTimeoutId = setTimeout(() => {
      localStorage.setItem(
        "MASTER_VIEW_API_PARAMS",
        JSON.stringify(masterViewApiParams)
      );
      let request = {
        page: masterViewApiParams?.page,
        limit: masterViewApiParams?.pageSize,
        ...filterData,
        userid: "all",
      };
      for (const [key, value] of Object.entries(filterModel || {})) {
        // @ts-ignore
        const { type, filterType, filter, dateFrom } = value;
        let _value: any;
        if (filterType === "date") {
          _value = dateFrom;
        } else if (key === "user_name" && filterType === "text") {
          _value = type;
        } else {
          _value = filter;
        }
        request[key] = _value;
      }
      for (const _sort of sortModel || []) {
        // @ts-ignore
        request["sort_key"] = _sort?.colId;
        // @ts-ignore
        request["sort_order"] = _sort?.sort;
      }
      if (!request?.status) {
        request["status"] = [...OperationsStatusDataFetch];
      }
      dispatch(getMasterViewList(request));
    }, 500);
  }, [masterViewApiParams, dispatch, filterData, filterModel, sortModel]);

  const onFilterInputChangeHandler = (e: any) => {
    const { name, value } = e?.target;
    gridApiRef?.current?.setFilterModel(null);
    gridApiRef?.current?.setSortModel(null);
    gridApiRef?.current?.deselectAll();
    setTimeout(() => {
      dispatch(
        setMasterViewApiParams({
          ...masterViewApiParams,
          filterData: {
            ...masterViewApiParams?.filterData,
            [name]: value,
          },
          filterModel: null,
          sortModel: null,
        })
      );
    }, 0);
    setSelectedRows([]);
  };

  const onFilterInputChangeHandlerSearch = (e: any) => {
    const { name, value } = e?.target;
    gridApiRef?.current?.setFilterModel(null);
    gridApiRef?.current?.setSortModel(null);
    gridApiRef?.current?.deselectAll();
    setTimeout(() => {
      dispatch(
        setMasterViewApiParams({
          ...masterViewApiParams,
          filterData: {
            ...masterViewApiParams?.filterData,
            [name]: value,
          },
          filterModel: null,
          sortModel: null,
        })
      );
    }, 0);
    setSelectedRows([]);
  };

  const propsNewDocument = {
    name: "file",
    action: `${config.base_url}/utils/upload/repayment`,
    headers: {
      authorization: "authorization-text",
    },
    async onChange(info: any) {
      if (info.file.status !== "uploading") {
      }
      if (info.file.status === "done") {
        message.success(`${info.file.name} file uploaded successfully`);
        closeDrawer();
      } else if (info.file.status === "error") {
        message.error(`${info.file.name} file upload failed.`);
      }
    },
  };

  const onFilterDateChange = (dates: any) => {
    if (dates) {
      gridApiRef?.current?.setFilterModel(null);
      gridApiRef?.current?.setSortModel(null);
      gridApiRef?.current?.deselectAll();
      setTimeout(() => {
        dispatch(
          setMasterViewApiParams({
            ...masterViewApiParams,
            filterData: {
              ...masterViewApiParams?.filterData,
              start_date: moment(dates[0]).format("YYYY-MM-DD"),
              end_date: moment(dates[1]).format("YYYY-MM-DD"),
            },
            filterModel: null,
            sortModel: null,
          })
        );
      }, 0);
      setSelectedRows([]);
    }
  };

  const handleFilterChanged = () => {
    // Perform actions when filters change
    const filterModel = gridApiRef?.current?.getFilterModel();
    gridApiRef?.current?.deselectAll();
    dispatch(
      setMasterViewApiParams({
        ...masterViewApiParams,
        filterModel,
      })
    );
    setSelectedRows([]);
  };

  const handleSortChanged = (event: any) => {
    const sortModel = gridApiRef?.current?.getSortModel();
    gridApiRef?.current?.deselectAll();
    dispatch(
      setMasterViewApiParams({
        ...masterViewApiParams,
        sortModel,
      })
    );
    setSelectedRows([]);
  };

  const handleRowDataChanged = (event: any) => {
    // Row data has been changed
    gridApiRef?.current?.setFilterModel(filterModel);
    gridApiRef?.current?.setSortModel(sortModel);
    // Perform any necessary actions or updates here
  };

  const onRowSelectedHandle = (event: any) => {
    const isSelected = !!event?.node?.selected;
    if (isSelected && event?.data) {
      // @ts-ignore
      setSelectedRows((current: Array) => [
        ...current,
        {
          ...event?.data,
          // borrower_id: event?.data?.borrower_id,
          // loan_id: event?.data?.loan_id,
          // mobile_number: event?.data?.mobile_number,
        },
      ]);
    } else {
      // @ts-ignore
      setSelectedRows((current: Array) => [
        ...current?.filter((obj: any) => {
          return obj?.borrower_id !== event?.data?.borrower_id;
        }),
      ]);
    }
  };

  const showDrawer = () => {
    setOptionUser("");
    setIsDrawer(true);
  };

  const closeDrawer = () => {
    setIsDrawer(false);
  };

  function updateDimension() {
    setHeight(window.innerHeight);
  }

  const onGridReady = async (params: any) => {
    gridApiRef.current = params.api;
    setGridApi(params.api);
  };

  useLayoutEffect(() => {
    window.addEventListener("resize", updateDimension);
    updateDimension();
    return () => window.removeEventListener("resize", updateDimension);
  }, []);

  const desSelectAllRow = () => {
    if (gridApiRef?.current) {
      gridApiRef?.current?.deselectAll();
    }
    setSelectedRows([]);
  };

  const dateFilterParams = {
    comparator: (filterLocalDateAtMidnight: any, cellValue: any) => {
      var dateAsString = cellValue;
      if (dateAsString === null) return -1;
      var dateParts = dateAsString.split("/");
      var cellDate = new Date(
        Number(dateParts[2]),
        Number(dateParts[1]) - 1,
        Number(dateParts[0])
      );
      if (filterLocalDateAtMidnight.getTime() === cellDate.getTime()) {
        return 0;
      }
      if (cellDate < filterLocalDateAtMidnight) {
        return -1;
      }
      if (cellDate > filterLocalDateAtMidnight) {
        return 1;
      }
      return 0;
    },
    minValidYear: 2000,
    maxValidYear: 2021,
    inRangeFloatingFilterDateFormat: "Do MMM YYYY",
    buttons: ["apply", "reset", "cancel"],
    closeOnApply: true,
    filterOptions: ["equals"],
    maxNumConditions: 1,
    trimInput: true,
  };

  const resetAllFilters = () => {
    gridApiRef.current.setFilterModel(null);
    gridApiRef.current.setSortModel(null);
    gridApiRef?.current?.deselectAll();
    setTimeout(() => {
      dispatch(
        setMasterViewApiParams({
          ...masterViewApiParamsInitState,
          pageSize: masterViewApiParams?.pageSize,
          filterData: {
            ...masterViewApiParamsInitState?.filterData,
            start_date: masterViewApiParams?.filterData?.start_date,
            end_date: masterViewApiParams?.filterData?.end_date,
          },
        })
      );
    }, 0);
    setSelectedRows([]);
    message.info("Filters Reset Successfully");
  };

  function parseReceivedDate(params: any) {
    if (params.data.disbursal_date) {
      return moment(params.data.disbursal_date).format("DD/MM/YYYY");
    } else {
      return "";
    }
  }

  const columnDefs = [
    {
      headerName: "Loan Id",
      field: "loan_id",
      headerCheckboxSelectionFilteredOnly: true,
      headerCheckboxSelection: true,
      checkboxSelection: true,
      pinned: "left",
      width: 160,
      minWidth: 160,
      maxWidth: 160,
      suppressSizeToFit: true,
      filter: "agTextColumnFilter",
      filterParams: {
        buttons: ["apply", "reset", "cancel"],
        closeOnApply: true,
        filterOptions: ["contains"],
        trimInput: true,
        maxNumConditions: 1,
      },
      cellStyle: { fontSize: "12px" },
    },
    {
      headerName: "Employee Name",
      field: "first_name",
      // pinned: "left",
      width: 160,
      minWidth: 160,
      maxWidth: 160,
      suppressSizeToFit: true,
      filter: true,
      cellStyle: { fontSize: "12px" },
      filterParams: {
        buttons: ["apply", "reset", "cancel"],
        closeOnApply: true,
        filterOptions: ["contains"],
        trimInput: true,
        maxNumConditions: 1,
      },
    },
    {
      headerName: "Amount",
      field: "disbursed_amt",
      minWidth: 160,
      suppressSizeToFit: true,
      cellStyle: { fontSize: "12px" },
      filter: "agNumberColumnFilter",
      filterParams: {
        buttons: ["apply", "reset", "cancel"],
        closeOnApply: true,
        filterOptions: ["contains"],
        maxNumConditions: 1,
        trimInput: true,
      },
    },
    {
      field: "pan",
      headerName: "PAN",
      filter: false,
      minWidth: 160,
      suppressSizeToFit: true,
      cellStyle: { fontSize: "12px" },
    },
    {
      field: "company_name",
      headerName: "Employer",
      filter: "agTextColumnFilter",
      filterParams: {
        buttons: ["apply", "reset", "cancel"],
        closeOnApply: true,
        filterOptions: ["contains"],
        trimInput: true,
        maxNumConditions: 1,
      },
      minWidth: 140,
      suppressSizeToFit: true,
      cellStyle: { fontSize: "12px" },
    },
    {
      field: "disbursal_date",
      headerName: "Disbursal Date",
      filterParams: dateFilterParams,
      filter: "agDateColumnFilter",
      valueGetter: parseReceivedDate,
      sortable: true,
      cellStyle: { fontSize: "12px" },
      minWidth: 160,
    },
    {
      field: "nbfc_partner",
      headerName: "NBFC Name",
      filter: "agTextColumnFilter",
      filterParams: {
        buttons: ["apply", "reset", "cancel"],
        closeOnApply: true,
        filterOptions: ["contains"],
        trimInput: true,
        maxNumConditions: 1,
      },
      minWidth: 140,
      suppressSizeToFit: true,
      cellStyle: { fontSize: "12px" },
    },
    {
      field: "borrower_id",
      width: 160,
      minWidth: 160,
      maxWidth: 160,
      headerName: "Borrower Id",
      filter: true,
      cellStyle: { fontSize: "12px" },
      pinned: "left",
      filterParams: {
        buttons: ["apply", "reset", "cancel"],
        closeOnApply: true,
        filterOptions: ["contains"],
        trimInput: true,
        maxNumConditions: 1,
      },
    },
    {
      field: "mobile_number",
      minWidth: 140,
      headerName: "Mobile",
      filter: false,
      cellStyle: { fontSize: "12px" },
    },
    {
      field: "arthmate_post_loan_id",
      minWidth: 140,
      headerName: "NBFC Loan Id",
      filter: false,
      cellStyle: { fontSize: "12px" },
    },
    {
      field: "loan_id",
      headerName: "Description",
      pinned: "right",
      width: 120,
      minWidth: 120,
      maxWidth: 120,
      suppressSizeToFit: true,
      cellRenderer: "repaymentButton",
      cellStyle: { fontSize: "12px" },
      filter: false,
    },
    {
      headerName: "Employee Id",
      field: "client_emp_id",
      pinned: "left",
      width: 160,
      minWidth: 160,
      maxWidth: 160,
      suppressSizeToFit: true,
      filter: true,
      cellStyle: { fontSize: "12px" },
      filterParams: {
        buttons: ["apply", "reset", "cancel"],
        closeOnApply: true,
        filterOptions: ["contains"],
        trimInput: true,
        maxNumConditions: 1,
      },
    },
  ];

  async function getExportedData() {
    let selectedRows: Array<any> = [];
    let result: Array<any> = [];
    try {
      selectedRows = gridApiRef.current.getSelectedRows();
      selectedRows.map((element: any) => {
        let hashNew: any = {};

        hashNew["Borrower Id"] = element["borrower_id"];
        hashNew["Amount"] = element["disbursed_amt"];
        hashNew["Loan Id"] = element["loan_id"];
        hashNew["Employee Id"] = element["client_emp_id"];
        hashNew["PAN"] = element["pan"];
        hashNew["Employer"] = element["company_name"];
        hashNew["Mobile"] = element["mobile_number"];
        hashNew["Disbursal Date"] = moment(element["disbursal_date"]).format(
          "DD-MM-YYYY"
        );
        result.push(hashNew);
      });

      setExportUIRows(result);
    } catch (err) {
      message.error("Failed to Export");
      setExportUIRows(result);
    }
  }

  const onChangePage = (page: number, pageSize?: number) => {
    if (page && pageSize) {
      dispatch(
        setMasterViewApiParams({
          ...masterViewApiParams,
          page,
          pageSize,
        })
      );
    }
  };

  function AssignedTo(params: any) {
    if (params.data.user_name) {
      return params.data.user_name;
    } else {
      return "";
    }
  }

  const handleBulkUpdateAssignUser = async () => {
    if (!selectedRows?.length) {
      message.warning("Please select a row to proceed");
      return;
    }
    if (!optionUser) {
      message.warning("Please select user.");
      return;
    }
    let loan_ids: any = [];
    selectedRows?.forEach((element: any) => {
      loan_ids.push(Number(element["loan_id"]));
    });
    let data = {
      loan_ids: loan_ids,
      user_id: Number(optionUser),
    };
    await updateBulkUser(data);
    gridApiRef?.current?.setFilterModel(null);
    gridApiRef?.current?.setSortModel(null);
    gridApiRef?.current?.deselectAll();
    setTimeout(() => {
      dispatch(
        setMasterViewApiParams({
          ...masterViewApiParams,
          filterModel: null,
          sortModel: null,
          page: 1,
        })
      );
    }, 0);
    setSelectedRows([]);
    closeDrawer();
    message.success("loan requests details updated.");
  };

  const pageSizeBtnClickHandler = (e: any) => {
    gridApiRef?.current?.deselectAll();
    setTimeout(() => {
      dispatch(
        setMasterViewApiParams({
          ...masterViewApiParams,
          filterModel: null,
          sortModel: null,
          page: 1,
          pageSize: Number(pageSize),
        })
      );
    }, 0);
    setSelectedRows([]);
    closeDrawer();
    message.success("Page size updated.");
  };

  const showTotal: PaginationProps["showTotal"] = (total) =>
    `Total Rows: ${total}`;

  return (
    <>
      <div style={{ backgroundColor: "#f0f0f0" }}>
        <Row
          style={{
            padding: "15px 15px 5px 15px",
          }}
        >
          <Col span={16}>
            <Row>
              <Col
                span={5}
                style={{
                  paddingRight: "15px",
                }}
              >
                <Input
                  placeholder="PAN"
                  name="pan"
                  value={filterData?.pan}
                  onChange={onFilterInputChangeHandler}
                />
              </Col>
              <Col
                span={5}
                style={{
                  paddingRight: "15px",
                }}
              >
                <Input
                  placeholder="Mobile No"
                  name="mobile_number"
                  value={filterData?.mobile_number}
                  onChange={onFilterInputChangeHandler}
                />
              </Col>

              <Col span={7}>
                <RangePicker
                  format="DD-MM-YYYY"
                  onChange={onFilterDateChange}
                  value={[
                    moment(filterData?.start_date),
                    moment(filterData?.end_date),
                  ]}
                  defaultValue={[
                    moment(filterData?.start_date),
                    moment(filterData?.end_date),
                  ]}
                />
              </Col>
              <Col
                span={5}
                style={{
                  paddingRight: "15px",
                }}
              >
                <Input
                  placeholder="Search ..."
                  name="search"
                  value={filterData?.search}
                  onChange={onFilterInputChangeHandlerSearch}
                />
              </Col>
            </Row>
          </Col>
          <Col
            span={8}
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "flex-end",
            }}
          >
            <Button
              type="text"
              icon={<RetweetOutlined />}
              onClick={desSelectAllRow}
              style={{
                display: "inline-flex",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              Deselect Rows
            </Button>
            <Button
              type="text"
              icon={<DoubleLeftOutlined />}
              onClick={resetAllFilters}
              style={{
                display: "inline-flex",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              Reset Filters
            </Button>
            <Tooltip title="Click here for actions">
              <AlignCenterOutlined
                style={{
                  fontSize: "20px",
                  cursor: "pointer",
                  padding: "0px 0px 0px 10px",
                }}
                onClick={showDrawer}
              />
            </Tooltip>
          </Col>
        </Row>
        <Drawer
          title="Actions"
          placement="right"
          onClose={closeDrawer}
          visible={isDrawer}
          width={500}
        >
          <Card style={{ padding: "", marginTop: "4%" }}>
            <Col>
              <h6 style={{ marginBottom: "5%" }}>Page Size</h6>
            </Col>
            <Row>
              <Col span={18}>
                <Input
                  type="number"
                  name="pageSize"
                  value={pageSize}
                  onChange={(e: any) => {
                    setPageSize(e?.target?.value);
                  }}
                ></Input>
              </Col>
              <Col span={6}>
                <Button
                  type="primary"
                  block
                  onClick={pageSizeBtnClickHandler}
                  style={{
                    marginLeft: "10px", backgroundColor: "#1B875A", border: 0
                  }}
                >
                  Apply
                </Button>
              </Col>
            </Row>
          </Card>
          {role !== "agency" ? (
            <Card style={{ padding: "", marginTop: "4%" }}>
              <Col style={{ marginTop: "5%" }}>
                <CSVLink
                  id="download"
                  style={{ visibility: "hidden" }}
                  data={exportUIRows}
                >
                  Download me
                </CSVLink>

                <Button
                  type="primary"
                  block
                  style={{ backgroundColor: "#1B875A", border: 0 }}
                  onClick={async () => {
                    if (!selectedRows?.length) {
                      message.warning("Please select a row!");
                    } else {
                      await getExportedData();
                    }
                  }}
                >
                  Export Selected Rows
                </Button>
              </Col>
            </Card>) : ""}
          {(role === "admin" || role === "super_admin") ? (
            <>
              {
                <Card style={{ padding: "", marginTop: "4%" }}>
                  <Col>
                    <h6 style={{ marginBottom: "5%" }}>
                      Upload Repayments sheet
                    </h6>
                  </Col>
                  <Col span={24}>
                    <Upload {...propsNewDocument}>
                      <Button
                        type="primary"
                        style={{ width: "160%", backgroundColor: "#1B875A", border: 0 }}
                        icon={<UploadOutlined />}
                      >
                        Upload a new Document
                      </Button>
                    </Upload>
                  </Col>
                </Card>
              }
            </>
          ) : (
            ""
          )}
        </Drawer>
        <Row>
          <Col span={24}>
            <div
              className="ag-theme-alpine"
              style={{
                height: height * 0.76,
                padding: "5px 15px 15px 15px",
                fontSize: "12px",
              }}
            >
              <AgGridComponent
                rowData={masterViewList?.result || []}
                onFilterChanged={handleFilterChanged}
                onSortChanged={handleSortChanged}
                onGridReady={onGridReady}
                onRowDataChanged={handleRowDataChanged}
                onRowSelected={onRowSelectedHandle}
                columnDefs={columnDefs}
                defaultColDef={defaultColDef}
                totalPages={Math.ceil(
                  Number(masterViewList?.count) / masterViewApiParams?.pageSize
                )}
                frameworkComponents={{
                  document: Document,
                  employee: Employee,
                  active_request: Activerequest,
                  enach: Enach,
                  document_verified: DocumentsVerified,
                  repaymentButton: RepaymentCellRenderer,
                }}
              />
            </div>
            {masterViewList?.result?.length ? (
              <div
                style={{
                  display: "flex",
                  margin: "0px 15px 10px 15px",
                }}
              >
                {selectedRows?.length ? (
                  <div
                    style={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "flex-end",
                      fontSize: "14px",
                      lineHeight: "16px",
                      color: "#000",
                    }}
                  >
                    Selected Rows: {selectedRows?.length || 0}
                  </div>
                ) : null}
                <Pagination
                  style={{
                    justifyContent: "right",
                    textAlign: "right",
                    marginLeft: "auto",
                  }}
                  current={masterViewApiParams?.page}
                  onChange={onChangePage}
                  total={Number(masterViewList?.count || 0)}
                  pageSize={masterViewApiParams?.pageSize}
                  defaultPageSize={masterViewApiParams?.page}
                  showSizeChanger={false}
                  showTotal={showTotal}
                />
              </div>
            ) : null}
          </Col>
        </Row>
      </div>
    </>
  );
}
