import { Row, Col, Tag, Divider, Tooltip, Upload, Button, Select } from 'antd'
import { ExclamationCircleOutlined, DeleteOutlined, DownloadOutlined, CheckCircleFilled, CloseCircleFilled, CloseOutlined, UploadOutlined } from "@ant-design/icons";
import React from 'react';
const { Option } = Select;


export const DocumentDetails = (props: any) => {
	props = props.props;
	return (
		<div>
			<Row
				style={{
					background: "#fafafa",
					padding: "1%",
					marginBottom: "1%",
				}}
			>
				<Col span={5} style={{}}>
					<h6 style={{ fontSize: "15px" }}>Name</h6>
				</Col>
				<Col span={2} style={{}}>
					<h6 style={{ fontSize: "15px" }}>Downloads</h6>
				</Col>
				<Col span={2} style={{}}>
					<h6 style={{ fontSize: "15px" }}>Verification</h6>
				</Col>

				<Col span={3} style={{}}>
					<h6 style={{ fontSize: "15px" }}>Validation Status</h6>
				</Col>
				<Col span={4} style={{}}>
					<h6 style={{ fontSize: "15px" }}>Rejected Fields</h6>
				</Col>
				<Col span={3} style={{}}>
					<h6 style={{ fontSize: "15px" }}>Update Verification</h6>
				</Col>
				<Col span={1} style={{}}>
					<h6 style={{ marginLeft: "5%", fontSize: "15px" }}>
						Upload
					</h6>
				</Col>
				<Col span={3} style={{}}>
					<h6 style={{ marginLeft: "30%", fontSize: "15px" }}>
						Delete Document
					</h6>
				</Col>
			</Row>

			{props.documents.map((element: any, index: any) => {
				return (
					<React.Fragment key={`documents-${element.document_name}-${index}`}>
						<li>
							<Row
								style={{
									marginBottom: "1%",
									paddingLeft: "1%",
									paddingRight: "1%",
								}}
							>
								<Col span={5}>
									<a
										style={{ fontSize: "15px", color: "#1890ff" }}
										onClick={() =>
											props.handleClick(element.document_url, index)
										}
									>
										{element.document_name.toUpperCase()}
									</a>
								</Col>
								<Col span={2}>
									<Tooltip
										title={element.document_name.toUpperCase()}
									>
										<a href={element.document_url} target="_blank">
											<DownloadOutlined
												style={{ fontSize: "25px" }}
											></DownloadOutlined>
										</a>
									</Tooltip>
								</Col>
								<Col span={2}>
									{element.state === "APPROVED" ? (
										<Tooltip title="APPROVED">
											<CheckCircleFilled
												style={{ fontSize: "30px", color: "#389e0d" }}
											/>
										</Tooltip>
									) : element.state === "REJECTED" ? (
										<Tooltip title="REJECTED">
											<CloseCircleFilled
												style={{ fontSize: "30px", color: "#cf1322" }}
											/>
										</Tooltip>
									) : (
										<Tooltip title="PENDING">
											<ExclamationCircleOutlined
												style={{ fontSize: "30px", color: "#fa8c16" }}
											/>
										</Tooltip>
									)}
								</Col>

								<Col span={3}>
									{element.validation_state === "APPROVED" ? (
										<Tooltip title="VALIDATION APPROVED">
											<CheckCircleFilled
												style={{ fontSize: "30px", color: "#389e0d" }}
											/>
										</Tooltip>
									) : element.validation_state === "REJECTED" ? (
										<Tooltip title="VALIDATION REJECTED">
											<CloseCircleFilled
												style={{ fontSize: "30px", color: "#cf1322" }}
											/>
										</Tooltip>
									) : (
										<Tooltip title="VALIDATION PENDING">
											<ExclamationCircleOutlined
												style={{ fontSize: "30px", color: "#fa8c16" }}
											/>
										</Tooltip>
									)}
								</Col>
								<Col span={4}>
									{element.rejected_fields.map((element: any, index: number) => {
										return <Tag color="red" key={`color-${element}-${index}`}>{element}</Tag>;
									})}
								</Col>

								<Col span={3}>
									<Select
										defaultValue="status"
										style={{ width: "75%" }}
										onChange={async (el) => {
											await props.onChangeDoc(element, el);
										}}
									>
										{props.docstatuses.map((element: any, index: number) => {
											return (
												<Option key={`docstatuses-${element}-${index}`} value={element}>{element}</Option>
											);
										})}
									</Select>
								</Col>
								<Col span={1}>
									<Upload {...props}>
										<Button
											icon={<UploadOutlined />}
											onClick={() => {
												props.setCurrentDocument(element);
											}}
											style={{}}
										>
											Upload
										</Button>
									</Upload>
								</Col>
								<Col span={3} style={{ marginLeft: "3%" }}>
									<DeleteOutlined
										onClick={async () => {
											props.setIsModalDeleteId(element.id);
											props.setIsModalDelete(true);
										}}
										style={{ fontSize: "30px", marginLeft: "25%" }}
									/>
								</Col>
							</Row>
						</li>
						{props.fileType === "pdf" &&
							props.currentIndex === index &&
							props.closeFile === true ? (
							<>
								<div style={{ textAlign: "right", width: "91%" }}>
									{props.closeFile === true ? (
										<CloseOutlined
											onClick={() => props.setCloseFile(false)}
											style={{ fontSize: "20px", marginLeft: "0px" }}
										></CloseOutlined>
									) : (
										<></>
									)}
								</div>
								<div
									style={{
										textAlign: "center",
										width: "91%",
										paddingBottom: "25px",
									}}
								>
									<iframe src={props.fileURL} width="91%" height="500" />
								</div>
							</>
						) : props.fileType === "image" && props.currentIndex === index && props.closeFile === true ? (
							<>
								<div style={{ textAlign: "right", width: "91%" }}>
									{props.closeFile === true ? (
										<CloseOutlined
											onClick={() => props.setCloseFile(false)}
											style={{ fontSize: "20px", marginLeft: "0px" }}
										></CloseOutlined>
									) : (
										<></>
									)}
								</div>
								<div style={{ textAlign: "center", width: "91%", paddingBottom: "25px" }}>
									<img src={props.fileURL} alt={props.fileURL} />
								</div>
							</>
						) : (
							<></>
						)}
					</React.Fragment>
				);
			})}

			{
				<div style={{ textAlign: "right", width: "91%", marginTop: "25px" }}>
					<Button
						type="primary"
						shape="round"
						style={{
							fontSize: "15px",
							background: "#dc1a22",
						}}
						onClick={() => props.deleteResource(props.internalEmpId || -1, 'document')}
					>
						Delete Documents
					</Button>
				</div>
			}

			{/* <Table columns={documentCols} dataSource={documents} /> */}
			{props.docpresent === true ? (
				<>
					<Row>
						<Col span={1}></Col>
						<Col span={1}></Col>
						<Col span={24} style={{ marginBottom: "" }}>
							<Divider
								style={{ fontFamily: "monospace", fontSize: "20px" }}
							>
								UPLOAD DOCUMENTS
							</Divider>
						</Col>
					</Row>
					<Row>
						<Col span={6}></Col>
						<Col span={6}>
							{" "}
							<Select
								defaultValue="Please select a document"
								style={{ width: "100%" }}
								onChange={props.handleChangeDocument}
							>
								{props.docstates.map((element: any, index: number) => {
									return (
										<Option key={`docstates1-${element}-${index}`} value={element["type"]}>
											{element["type"].toUpperCase()}
										</Option>
									);
								})}
							</Select>
						</Col>
						<Col span={2}></Col>
						<Col span={6}>
							<Upload {...props.propsNewDocument}>
								<Button icon={<UploadOutlined />}>
									Upload a new Document
								</Button>
							</Upload>
						</Col>
					</Row>
				</>
			) : (
				""
			)}
		</div>)
}