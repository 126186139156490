import { Button, Modal, Input, DatePicker } from 'antd';
import { BankOutlined } from '@ant-design/icons';
import React, { useState } from 'react';

interface ChargeModalProps {
    visible: boolean;
    onCancel: () => void;
    onCreate: (amount: number | undefined, date: any) => void;
  }

const ChargeModal: React.FC<ChargeModalProps> = ({ visible, onCancel, onCreate }) => {
    const [amount, setAmount] = useState<number | undefined>();
    const [date, setDate] = useState(null);
  
    const handleAmountChange = (value:any) => {
      setAmount(value);
    };
  
    const handleDateChange = (value:any) => {
      setDate(value);
    };
  
    return (
      <Modal
        visible={visible}
        title="Create Charge"
        onCancel={onCancel}
        onOk={() => onCreate(amount, date)}
      >
        <Input
          type="number"
          placeholder="Amount"
          value={amount}
          onChange={(e) => handleAmountChange(e.target.value)}
        />
        <DatePicker
          style={{ marginTop: '10px', width: '100%' }}
          placeholder="Select Date"
          onChange={(date, dateString) => handleDateChange(date)}
        />
      </Modal>
    );
  };

export default ChargeModal;