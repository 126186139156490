export const DynamicList = (values: Array<string>) => {
  const array: any = [
    {
      displayKey: 'empty',
      displayName: "Please select an option from the list",
      test: function (filterValue: any, cellValue: any) {
        return true
      },
      hideFilterInput: true,
    },
    {
      displayKey: "blanks",
      displayName: "Not Assigned",
      test: function (filterValue: any, cellValue: any) {
        return cellValue.length === 0 ? true : false;
      },
      hideFilterInput: true,

    }
  ]

  values.forEach((element: string) => {
      array.push(
        {
          displayKey: element,
          displayName: element,
          test: function (filterValue: any, cellValue: any) {
            return cellValue === element;
          },
          hideFilterInput: true,
        },
      )
    
  })

  return array;
}

export const DynamicMandateSignature = (values: Array<string>) => {
  const array: any = [
    ]
  values.forEach((element: string) => {
    if(element === 'empty'){
      array.push(
        {
          displayKey: element,
          displayName: "Please select an option from the list",
          test: function (filterValue: any, cellValue: any) {
            return true
          },
          hideFilterInput: true,
        },
      )
    }
    else{
      array.push(
        {
          displayKey: element,
          displayName: element,
          test: function (filterValue: any, cellValue: any) {
            return String(cellValue).toLowerCase() === String(element).toLowerCase();
          },
          hideFilterInput: true,
        },
      )
    }
  
  })

  return array;
}
