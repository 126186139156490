import { ColumnsType } from 'antd/es/table';
import { ExistingLoan, ExistingLoanRequest, MainReducer } from "../types";

interface Existing_Loan extends ExistingLoan, ExistingLoanRequest {

}

export const existing_loan_columns: ColumnsType<any> = [
  {
    title: "Loan ID",
    dataIndex: "ops_id",
    key: "ops_id",
    render: text => text ?? "-"
  },
  {
    title: "Loan Amount",
    dataIndex: "amount",
    key: "amount",
    render: text => text ?? "-"
  },
  {
    title: "Request Status",
    dataIndex: "loan_status",
    key: "loan_status",
    render: text => text ?? "-"
  },
  {
    title: "Loan Status",
    dataIndex: "category",
    key: "category",
    render: text => text ?? "-"
  },
  {
    title: "NBFC Loan ID",
    dataIndex: "nbfc_loan_id",
    key: "nbfc_loan_id",
    render: text => text ?? "-"
  },
  {
    title: "DPD",
    dataIndex: "dpd",
    key: "dpd",
    render: text => text ?? "-"
  },
  {
    title: "Total EMIs",
    dataIndex: "no_of_emi",
    key: "no_of_emi",
    render: text => text ?? "-"
  },
  {
    title: "NBFC Status",
    dataIndex: "nbfc_status",
    key: "nbfc_status",
    render: text => text ?? "-"
  },
  {
    title: "Channel Cashfree",
    dataIndex: "channel_Cashfree",
    key: "channel_Cashfree",
    render: text => text ?? "-"
  },
  {
    title: "Channel Salary deduction",
    dataIndex: "channel_Salary_deduction",
    key: "channel_Salary_deduction",
    render: text => text ?? "-"
  },
  {
    title: "Channel eNach",
    dataIndex: "channel_eNach",
    key: "channel_eNach",
    render: text => text ?? "-"
  },
];
