import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useLocation } from "react-router-dom";
import { MainReducer } from "../../types";
import { Card, Space, Input, Button, Tooltip, List } from "antd";
import { SendOutlined } from '@ant-design/icons'
import { addLoanComment } from "../../utitlities/request";
import { store } from "../../state";
import {
  fetchLoanRequest,
  fetchEmployee,
  fetchLoan,
  reset,
  fetchLogs,
  fetchComments,
  fetchArthmate,
} from "../../state/action-creators";

const Comments: React.FC = () => {
  const dispatch = useDispatch();
  const storeComments: any = useSelector((state: MainReducer) => {
    return state.loan.comments.body.data;
  }) || [];
  const search = useLocation().search;
  const request_id = new URLSearchParams(search).get("request_id");

  const [newComment, setNewComment] = useState("");
  const handleCommentOk = async () => {
    let user: any = {};
    //@ts-ignore
    user = JSON.parse(localStorage.getItem("user"));
    await addLoanComment(user["id"], newComment, request_id);
    dispatch(fetchComments(request_id));
    setNewComment("");
  };
  return (
    <div style={{ height: '100%' }}>
      <Card
        title="Add Your Comments"
        bordered={false}
        className="comments-card"
      >
        <div className="comments-list" style={{ overflowY: 'auto', display: 'flex', flexDirection: 'column-reverse', flex: 9, maxHeight: '300px' }}>
          <List
            itemLayout="vertical"
            dataSource={storeComments}
            renderItem={(comment: any) => (
              <List.Item>
                <div>
                  <div style={{ fontSize: '16px', marginBottom: '8px' }}>{comment?.comment}</div>
                  <div style={{ fontSize: '14px', color: '#888' }}>{comment?.author}</div>
                  <div style={{ fontSize: '12px', color: '#aaa' }}>{comment?.created_at}</div>
                </div>
              </List.Item>
            )}
          />
        </div>
        <div className="fixed-input" style={{ position: 'absolute', bottom: '0', left: '0', right: '0', flex: 1 }}>
          <Space.Compact style={{ width: '100%', paddingLeft: "5px", paddingRight: "5px", paddingBottom: "5px" }}>
            <Input defaultValue="" value={newComment} onChange={(e) => setNewComment(e.target.value)}
              onKeyDown={(e) => {
                if (e.key == 'Enter') {
                  handleCommentOk();
                }
              }}
            />
            <Tooltip title="Send">
              <Button
                type="primary"
                shape="circle"
                icon={<SendOutlined />}
                style={{ backgroundColor: '#006400', borderColor: '#006400' }}
                onClick={() => handleCommentOk()}
              />
            </Tooltip>
          </Space.Compact>
        </div>
      </Card>
    </div>
  );
};
export default Comments;
