import { Row, Col, Typography, Input, Table, Divider } from 'antd'
import { HighlightOutlined, PlusCircleTwoTone } from "@ant-design/icons";
const { Text } = Typography;

export const EmploymentDetails = (props: any) => {
	props = props.props;
	return (<div>
		<Row>
			<Col span={6} style={{ padding: "2%" }}>
				<h6>
					<b>Employer Name</b>
				</h6>
				<Text style={{ fontSize: "14px" }}>{props.employerName}</Text>
			</Col>
			<Col
				span={6}
				style={{ padding: "2%", background: "#f5f5f5" }}
			>
				<h6>
					<b>Designation</b>
				</h6>
				<Text
					style={{ fontSize: "14px" }}
					editable={{
						icon: <HighlightOutlined />,
						tooltip: "click to edit text",
						onChange: props.setDesignation,
					}}
				>
					{props.designation}
				</Text>
			</Col>
			<Col span={6} style={{ padding: "2%" }}>
				<h6>
					<b>Average Salary</b>
				</h6>
				<Text
					style={{ fontSize: "14px" }}
				// editable={{
				//   icon: <HighlightOutlined />,
				//   tooltip: "click to edit text",
				//   onChange: setEmpSalary,
				// }}
				>
					{props.empsalary}
				</Text>
			</Col>
			<Col
				span={6}
				style={{ padding: "2%", background: "#f5f5f5" }}
			>
				<h6>
					<b>Work Location</b>
				</h6>
				<Text
					style={{ fontSize: "14px" }}
					editable={{
						icon: <HighlightOutlined />,
						tooltip: "click to edit text",
						onChange: props.setWorkLocation,
					}}
				>
					{props.workLocation}
				</Text>
			</Col>
		</Row>
		<Row>
			<Col
				span={6}
				style={{ padding: "2%", background: "#f5f5f5" }}
			>
				<h6>
					<b>Date of Joining</b>
				</h6>

				<Input
					type="date"
					value={props.doj != null ? props.doj.split("T")[0] : " "}
					onChange={(e) => {
						props.setDoj(e.target.value);
					}}
				/>
			</Col>

			<Col span={6} style={{ padding: "2%" }}>
				<h6>
					<b>Department</b>
				</h6>
				<Text
					style={{ fontSize: "14px" }}
					editable={{
						icon: <HighlightOutlined />,
						tooltip: "click to edit text",
						onChange: props.setDepartMent,
					}}
				>
					{props.department}
				</Text>
			</Col>

			<Col
				span={6}
				style={{ padding: "2%", background: "#f5f5f5" }}
			>
				<h6>
					<b>Job Type</b>
				</h6>

				<Text
					style={{ fontSize: "14px" }}
					editable={{
						icon: <HighlightOutlined />,
						tooltip: "click to edit text",
						onChange: props.setJobType,
					}}
				>
					{props.job_type}
				</Text>
			</Col>
			<Col span={6} style={{ padding: "2%" }}>
				<h6>
					<b>Deputed At From OCR</b>
				</h6>
				<Text
					style={{ fontSize: "14px" }}
					editable={{
						icon: <HighlightOutlined />,
						tooltip: "click to edit text",
						onChange: props.setDeputedAt,
					}}
				>
					{props.deputed_at}
				</Text>
			</Col>
		</Row>

		<Table
			style={{ margin: "1%" }}
			dataSource={props.salary}
			columns={props.columns}
		/>
		<Row>
			<Col span={1}>
				{" "}
				<Divider style={{ fontFamily: "", fontSize: "20px" }}>
					{" "}
					<span style={{ fontWeight: 340 }}>Upload Salary</span>
					<PlusCircleTwoTone
						style={{ margin: "5%", fontSize: "30px" }}
						onClick={() => {
							props.updateValuesSalary();
							props.setIsSalaryModalVisible(true);
						}}
					/>
				</Divider>
			</Col>
		</Row>
		<Row>
			<Col span={20} style={{ padding: "2%" }}></Col>
			<Col span={2} style={{ padding: "2%" }}>
			</Col>
		</Row>
	</div>);
}