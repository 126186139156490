import { Row, Col, Typography, Input } from "antd";
const { Text } = Typography;
export const AdditionalInfo = (props: any) => {
  props = props.props?.meta_data;
  
  return (
    <div>

      <Row>
        <Col span={8} style={{ padding: "2%" }}>
          <h6><b>Active Status</b></h6>
          <Text style={{ fontSize: "14px" }}>{props?.ActiveStatus ? props.ActiveStatus : "Unknown"}</Text>
        </Col>
        <Col span={8} style={{ padding: "2%", background: "#f5f5f5" }}>
          <h6><b>Deduction Status</b></h6>
          <Text style={{ fontSize: "14px" }}>{props?.DeductionStatus ? props.DeductionStatus : "Unknown"}</Text>
        </Col>
        <Col span={8} style={{ padding: "2%" }}>
          <h6><b>FnF Status</b></h6>
          <Text style={{ fontSize: "14px" }}>{props?.FnFStatus ? props.FnFStatus : "Unknown"}</Text>
        </Col>
      </Row>

      <Row>
        <Col span={8} style={{ padding: "2%" }}>
          <h6><b>Last Salary Release Date</b></h6>
          <Text style={{ fontSize: "14px" }}>{props?.LastSalaryReleaseDate ? props.LastSalaryReleaseDate : "Unknown"}</Text>
        </Col>
        <Col span={8} style={{ padding: "2%", background: "#f5f5f5" }}>
          <h6><b>Resignation Date</b></h6>
          <Text style={{ fontSize: "14px" }}>{props?.ResignationDate ? props.ResignationDate : "Unknown"}</Text>
        </Col>
        <Col span={8} style={{ padding: "2%" }}>
          <h6><b>Resignation Status</b></h6>
          <Text style={{ fontSize: "14px" }}>{props?.ResignationStatus ? props.ResignationStatus : "Unknown"}</Text>
        </Col>
      </Row>

      <Row>
        <Col span={8} style={{ padding: "2%" }}>
          <h6><b>Total Non-Deducted Last Month</b></h6>
          <Text style={{ fontSize: "14px" }}>{props?.TotalNondeductedLastMonth ? props.TotalNondeductedLastMonth : "Unknown"}</Text>
        </Col>
        <Col span={8} style={{ padding: "2%", background: "#f5f5f5" }}>
          <h6><b>Total Outstanding EMI</b></h6>
          <Text style={{ fontSize: "14px" }}>{props?.TotalOutstandingEMI ? props.TotalOutstandingEMI : "Unknown"}</Text>
        </Col>
        <Col span={8} style={{ padding: "2%" }}>
          <h6><b>Message</b></h6>
          <Text style={{ fontSize: "14px" }}>{props?.Message ? props.Message : "Unknown"}</Text>
        </Col>
      </Row>

    </div>
  );
};
