import { Button, Tooltip } from "antd";
import { useHistory } from "react-router-dom";
import { EyeOutlined } from "@ant-design/icons";

export function ShowDetailsIcon(props: any) {
  const history = useHistory();
  const redirectToEmployeeDetails = () => {
    history.push(
      `/appraisal-detail?request_id=${props["data"]["loan_id"]}&employee_id=${props["data"]["internal_emp_id"]}&type=operations`
    );
  };
  return (
    <Tooltip
      title={
        !props["data"]["employee_present"]
          ? "Employee data not present"
          : "Click here to go to details"
      }
    >
      <Button
        type="text"
        icon={<EyeOutlined />}
        onClick={redirectToEmployeeDetails}
      />
    </Tooltip>
  );
}
