import { Row, Col, Button, Table, Divider } from 'antd'
import { PlusCircleTwoTone } from "@ant-design/icons";

export const ReferenceDetails = (props: any) => {
	props = props.props;
	return (<div>
		<Table columns={props.refCols} dataSource={props.references} />
		{
			<div style={{ textAlign: "right", width: "92%", marginTop: "25px" }}>
				<Button
					type="primary"
					shape="round"
					style={{
						fontSize: "15px",
						background: "#dc1a22",
					}}
					onClick={() => props.deleteResource(props.internalEmpId || -1, 'reference')}
				>
					Delete References
				</Button>
			</div>
		}
		<Row>
			<Col span={1}>
				{" "}
				<Divider style={{ fontFamily: "", fontSize: "20px" }}>
					{" "}
					<span style={{ fontWeight: 340 }}>Upload Reference</span>
					<PlusCircleTwoTone
						style={{ margin: "5%", fontSize: "30px" }}
						onClick={() => {
							props.setNewReference(true);
							props.updateValues();
							props.setIsModalVisible(true);
						}}
					/>
				</Divider>
			</Col>
		</Row>
	</div>)
}
