import * as _ from "lodash";
import {
  Layout,
  Menu,
  Tabs,
  Card,
  Spin,
  Divider,
  Row,
  Col,
  Input,
  Typography,
  Button,
  Table,
  Anchor,
  Statistic,
  Switch,
  Tooltip,
  Upload,
  message,
  Select,
  Progress,
  Modal,
  Form,
  Checkbox,
  Drawer,
  Tag,
} from "antd";
import { useLocation } from "react-router-dom";
import React, { useEffect, useRef, useState } from "react";
import axios from "axios";
import {
  HighlightOutlined,
  FileOutlined,
  CheckCircleFilled,
  CloseCircleFilled,
  UploadOutlined,
  PlusCircleTwoTone,
  LogoutOutlined,
  ExclamationCircleOutlined,
  DeleteOutlined,
  FileDoneOutlined,
  FileExcelOutlined,
  FileExclamationOutlined,
  DownloadOutlined,
  BankFilled,
  BankTwoTone,
  BankOutlined,
  CloseOutlined,
  LeftOutlined,
} from "@ant-design/icons";
import {
  Personal,
  Employment,
  Salary,
  BankDetails,
  Data,
  DocumentSchema,
  MainReducer,
  ExistingLoanRequest,
  ExistingLoan,
  BankStatementData,
} from "../types";
import {
  addLoanComment,
  addSalary,
  appraisalNotifications,
  BankDetailsFetch,
  BankDetailsUpdateDatabase,
  deleteDocument,
  deleteSalary,
  employeeDetails,
  employeeSave,
  enach,
  esign,
  fileUpload,
  fileUploadNew,
  getLoanComments,
  getLoanRequest,
  getStatusLogs,
  insertReferences,
  PostalCodeApi,
  updateBulk,
  updateBulkAppraised,
  updateEmployment,
  updateLoanRequest,
  updateReferences,
  analyseStatements,
  generateCibil,
  abbSave,
  nbfc,
  deleteResources,
  refreshNotif,
  getExistingLoans,
  getExistingLoanRequests,
  createLoanReportExport,
  getBankStatementData,
  bankValidate,
} from "../utitlities/request";
import { config } from "../config";
import { Loan } from "./loan";
import { calculateAverageSalary } from "../utitlities/calculations";
import {
  AppraisalStatus,
  document_status,
  OperationsStatus,
  reference_status,
} from "../utitlities/status";
import usePrevious from "../utitlities/previous";
import { TopHeader } from "./common/topHeader";
import { useDispatch, useSelector } from "react-redux";
import {
  fetchLoanRequest,
  fetchEmployee,
  fetchLoan,
  reset,
  fetchLogs,
  fetchStream,
  fetchComments,
  fetchArthmate,
} from "../state/action-creators";
import { PersonalDetails } from "./employee/personalDetails";
import { EmpBankDetails } from "./employee/bankDetails";
import { DocumentDetails } from "./employee/documents";
import { EmploymentDetails } from "./employee/employment";
import { ReferenceDetails } from "./employee/references";
import { AddressDetails } from "./employee/addresses";
import { OpsComments } from "./employee/comments";
import { StatusLogs } from "./employee/statusLogs";
import { ExistingLoans } from "./employee/existingLoans";
import moment from "moment";
import { useHistory } from "react-router-dom";
import { AdditionalInfo } from "./employee/additionalInfo";
import ViewToggle from "./ViewToggle/ViewToggle";

const { Paragraph, Text, Title } = Typography;
const { Link } = Anchor;
const { SubMenu } = Menu;
const { Header, Content, Sider } = Layout;
const { TabPane } = Tabs;
type RequiredMark = boolean | "optional";

export function Employees() {
  const [ toggle, setToggle ] = useState(false);
  const history = useHistory();
  const { Option } = Select;
  const [restrictToCallSaveEmployeeApi, setRestrictToCallSaveEmployeeApi] =
    useState(false);
  const [states, setStates] = useState<any>(null);
  const [docstatuses, setDocStatuses] = useState<any>(document_status);
  const [docstates, setDocStates] = useState<any>([]);
  const [option, setOption] = useState("");
  const [docoption, setDocOption] = useState("");
  const [docpresent, setDocPresent] = useState(true);
  //
  const { innerWidth: width, innerHeight: height } = window;
  const [visible, setVisible] = useState(false);
  const [result, setResult] = useState({});
  const [temp, setTemp] = useState<Data>({});
  const search = useLocation().search;
  const [salary, setSalary] = useState<Array<Salary> | undefined>(undefined);
  const [employment, setEmployment] = useState<Employment>({});
  const [bank, setBank] = useState<BankDetails>({});
  const [personal, setPersonal] = useState<Personal>({});
  const [documents, setDocument] = useState<Array<DocumentSchema>>([]);
  const firstUpdate = useRef(true);
  const [emp_count, setEmpCount] = useState(0);
  const [loan_count, setLoanCount] = useState(0);

  // let emp_count = 0
  // let loan_count = 0
  // personal
  const [first_name, setFirstName] = useState("");
  const [last_name, setLastName] = useState("");

  // permanent address
  const [address, setAddress] = useState("");
  const prev_address = usePrevious(address);
  const [state, setState] = useState("");
  const [city, setCity] = useState("");
  const [pincode, setPincode] = useState("");
  const prevpincode = usePrevious(pincode);
  // temporary address

  const [address_1, setAddress_1] = useState("");
  const prev_address_1 = usePrevious(address_1);
  const [state_1, setState_1] = useState("");
  const [city_1, setCity_1] = useState("");
  const [pincode_1, setPincode_1] = useState("");
  const prevpincode_1 = usePrevious(pincode_1);
  // suggested loan amount and period

  const [suggested_amount, setSuggestedAmount] = useState("");
  const [suggested_period, setSuggestedPeriod] = useState("");

  const [loan_purpose, setLoanPurpose] = useState("");
  const [fathername, setFatherName] = useState("");
  const [dob, setDob] = useState("");
  const [email, setEmail] = useState("");
  const [mobile, setMobile] = useState("");
  const [temp_mobile, setTempMobile] = useState("");
  const [whatsapp_no, setWhatsAppNo] = useState("");
  const [gender, setGender] = useState("");
  const [maritalStatus, setMaritalStatus] = useState("");
  const [pan, setPan] = useState("");
  const [aadhaar, setAadhaar] = useState("");
  // previuos
  // personal
  const prev_first_name = usePrevious(first_name);
  const prev_last_name = usePrevious(last_name);
  const prev_fathername = usePrevious(fathername);
  const prev_dob = usePrevious(dob);
  const prev_email = usePrevious(email);
  const prev_gender = usePrevious(gender);
  const prev_marital_status = usePrevious(maritalStatus);
  const prev_pan = usePrevious(pan);
  const prev_aadhaar = usePrevious(aadhaar);
  const temp_contact = usePrevious(temp_mobile);
  const prev_whatsapp_no = usePrevious(whatsapp_no);
  // bank

  const prev_loan_purpose = usePrevious(loan_purpose);
  const prev_suggested_amount = usePrevious(suggested_amount);
  const prev_suggested_period = usePrevious(suggested_period);

  // bank
  const [bank_statement_password, setPassword] = useState("");
  const [bank_code, setBankCode] = useState("");
  const [abb, setAbb] = useState("");

  const [abb_modified, setAbbModified] = useState("");
  const prev_abb_modified = usePrevious(abb_modified);

  const [cibil, setCibil] = useState("");
  const prev_password = usePrevious(bank_statement_password);
  const [beneficiary_name, setBeneficiaryName] = useState("");
  const prev_beneficiary_name = usePrevious(beneficiary_name);
  const [ifsc, setIfsc] = useState("");
  //previous
  const prev_ifsc = usePrevious(ifsc);
  const [account, setAccount] = useState("");
  //previuos
  const prev_account_no = usePrevious(account);
  //employment
  const [employerName, setEmployerName] = useState("");
  const [designation, setDesignation] = useState("");
  const [empsalary, setEmpSalary] = useState("");
  const [workLocation, setWorkLocation] = useState("");
  const [doj, setDoj] = useState("");
  const [department, setDepartMent] = useState("");
  const [deputed_at, setDeputedAt] = useState("");
  const [job_type, setJobType] = useState("permanent");
  // previous
  const prev_designation = usePrevious(designation);
  const prev_emp_salary = usePrevious(empsalary);
  const prev_workLocation = usePrevious(workLocation);
  const prev_doj = usePrevious(doj);
  const prev_department = usePrevious(department);
  const prev_job_type = usePrevious(job_type);
  const prev_deputed_at = usePrevious(deputed_at);
  //
  const loan_id = new URLSearchParams(search).get("loan_id");
  const employee_id = new URLSearchParams(search).get("employee_id");
  const request_id = new URLSearchParams(search).get("request_id");
  const type = new URLSearchParams(search).get("type");
  //
  const [salaryData, setSalaryData] = useState([]);
  const [loanStatus, setLoanStatus] = useState(true);
  //
  const [opsId, setOpsId] = useState("");
  const [internalEmpId, setInternalEmpId] = useState(null);
  const [arthmateEmpId, setarthmateEmpId] = useState("");
  const [arthmateLoanId, setarthmateLoanId] = useState("");
  const [arthmatePostLoanId, setarthmatePostLoanId] = useState("");
  const [nbfcStatus, sendNfcStatus] = useState("");
  const [nbfcError, setNbfcError] = useState("");
  const [active, setActive] = useState("1");
  const [references, setReferences] = useState([]);
  const [comments, setComments] = useState([]);
  const [otheraddress, setOtherAddress] = useState([]);
  const [logs, setlogs] = useState([]);
  const [currentDocument, setCurrentDocument] = useState<DocumentSchema>({});
  // should call employee api
  const [callEmployee, setcallEmployee] = useState<Number>(Math.random);
  //should call loan api
  const [callLoan, setcallLoan] = useState<Number>(Math.random);
  const [callArthmate, setcallArthamte] = useState<Number>(Math.random);
  // button
  //  modal
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [isModalDelete, setIsModalDelete] = useState(false);
  const [isModalDeleteId, setIsModalDeleteId] = useState<any>("");
  const [isCommentModalVisible, setIsCommentModalVisible] = useState(false);
  const [isSalaryModalVisible, setIsSalaryModalVisible] = useState(false);
  // form
  const [newref, setNewReference] = useState(false);
  const [current_ref, setCurrenRef] = useState<any>({});
  const [form] = Form.useForm();
  const [requiredMark, setRequiredMarkType] =
    useState<RequiredMark>("optional");

  const [status_comment, setStatusComment] = useState("");

  const [loading, setLoading] = useState(false);

  // Document viewer
  const [fileType, setFileType] = useState("");
  const [currentIndex, setCurrentIndex] = useState<any>();
  const [fileURL, setFileURL] = useState("");
  const [closeFile, setCloseFile] = useState(true);

  // Existing Loans Tab
  const [loans, setLoans] = useState<Array<ExistingLoan>>();
  const [loanRequests, setLoanRequests] =
    useState<Array<ExistingLoanRequest>>();

  // Bank Statement Data
  const [bankStatementData, setBankStatementData] =
    useState<BankStatementData>();

  // Additional Information
  const [additionalInfo, setAdditionalInfo] = useState<any>();

  const onRequiredTypeChange = ({
    requiredMarkValue,
  }: {
    requiredMarkValue: RequiredMark;
  }) => {
    setRequiredMarkType(requiredMarkValue);
  };

  const handleCancel = () => {
    setNewReference(false);
    setIsModalVisible(false);
  };

  const handleCommentCancel = () => {
    //setNewReference(false);
    setComment("");
    setIsCommentModalVisible(false);
  };
  const handleSalaryCancel = () => {
    //setNewReference(false);
    setSalaryAmount("");
    setSalaryDate("");
    setIsSalaryModalVisible(false);
  };

  // form relation
  const [ref_name, setRefName] = useState("");
  const [ref_address, setRefAddress] = useState("");
  const [ref_mobile_no, setRefMobileNo] = useState<any>(null);
  const [ref_relation, setRefRelation] = useState("");
  const [ref_verified, setRefVerified] = useState(false);
  // form salary
  const [salary_amount, setSalaryAmount] = useState("");
  const [salary_date, setSalaryDate] = useState("");
  const [comment, setComment] = useState("");
  // bank details

  const [bank_name, setBankName] = useState("");
  const [bankStatus, setBankStatus] = useState("");
  const [bank_branch, setBankBranch] = useState("");
  const [bank_state, setBankState] = useState("");

  // drawer
  const [isDrawer, setIsDrawer] = useState(false);
  const showDrawer = () => {
    setIsDrawer(true);
  };
  const onClose = () => {
    setIsDrawer(false);
  };
  const dispatch = useDispatch();

  // redux

  const employee = useSelector((state: MainReducer) => {
    return state.loan.employee.body;
  });
  const loanRequest = useSelector((state: MainReducer) => {
    return state.loan.loanRequest.body;
  });
  const employeeStatus = useSelector((state: MainReducer) => {
    return state.loan.employee.status;
  });
  const loanRequestStatus = useSelector((state: MainReducer) => {
    return state.loan.loanRequest.status;
  });
  const Logs = useSelector((state: MainReducer) => {
    return state.loan.logs.body;
  });
  const Comments = useSelector((state: MainReducer) => {
    return state.loan.comments.body;
  });
  const LogsStatus = useSelector((state: MainReducer) => {
    return state.loan.logs.status;
  });
  const CommentsStatus = useSelector((state: MainReducer) => {
    return state.loan.comments.status;
  });
  const loanDataStatus = useSelector((state: MainReducer) => {
    return state.loan.loan.status;
  });
  const loanData = useSelector((state: MainReducer) => {
    return state.loan.loan.body;
  });
  const arthmate = useSelector((state: MainReducer) => {
    return state.loan.arthmate.body;
  });
  const arthmateStatus = useSelector((state: MainReducer) => {
    return state.loan.arthmate.status;
  });

  const props = {
    name: "file",
    action: `${config.base_url}/utils/upload`,
    headers: {
      authorization: "authorization-text",
    },
    async onChange(info: any) {
      if (info.file.status !== "uploading") {
      }
      if (info.file.status === "done") {
        let data = {
          document_type: currentDocument.document_type,
          document_url: info.file.response.Location,
          is_verified: currentDocument.is_verified,
          emp_id: internalEmpId,
        };
        setVisible(false);
        {
          // @ts-ignore
          await fileUpload(data, currentDocument.id);
        }
        setcallEmployee(Math.random);
        setVisible(true);
        message.success(`${info.file.name} file uploaded successfully`);
      } else if (info.file.status === "error") {
        message.error(`${info.file.name} file upload failed.`);
      } else {
        // message.error(`${info.file.name} file upload failed.`);
      }
    },
  };
  const handleOk = async () => {
    if (newref) {
      let data = {
        address: ref_address,
        is_verified: ref_verified,
        mobile_no: ref_mobile_no,
        name: ref_name,
        relation: ref_relation,
        // request_id: request_id,
        emp_id: employee_id,
      };
      setIsModalVisible(false);
      setVisible(false);
      await insertReferences(data);
      setNewReference(false);
      setcallEmployee(Math.random);
    } else {
      handleRefUpdate();
    }
  };

  const handleCommentOk = async () => {
    setIsCommentModalVisible(false);
    setVisible(false);
    let user: any = {};
    //@ts-ignore
    user = JSON.parse(localStorage.getItem("user"));
    await addLoanComment(user["id"], comment, request_id);
    setComment("");
    // what happens
    setcallEmployee(Math.random);
  };
  const handleSalaryOk = async () => {
    setIsSalaryModalVisible(false);
    setVisible(false);
    await addSalary(employee_id, salary_date, salary_amount);
    setSalaryAmount("");
    setSalaryDate("");
    // what happens
    setcallEmployee(Math.random);
  };
  const handleRefUpdate = async () => {
    setIsModalVisible(false);
    let data = {
      address: ref_address,
      mobile_no: ref_mobile_no,
      name: ref_name,
      relation: ref_relation,
      is_verified: ref_verified,
    };

    const result = await updateReferences(data, current_ref["id"]);
    setcallLoan(Math.random);

    setRefAddress("");
    setRefMobileNo("");
    setRefName("");
    setRefRelation("");
    setRefVerified(false);
  };
  const refVerifiedUpdate = async (is_verified: any, id: number) => {
    let data = {
      state: is_verified,
    };
    const result = await updateReferences(data, id);
    setcallEmployee(Math.random);
  };

  async function refreshTrigger(mobile: string) {
    const res = await refreshNotif(mobile);
  }

  const propsNewDocument = {
    name: "file",
    action: `${config.base_url}/utils/upload`,
    headers: {
      authorization: "authorization-text",
    },
    async onChange(info: any) {
      if (info.file.status !== "uploading") {
      }
      if (info.file.status === "done") {
        let data = {
          document_type: docoption,
          document_url: info.file.response.Location,
          is_verified: true,
          emp_id: internalEmpId,
        };
        setVisible(false);
        {
          // @ts-ignore
          await fileUploadNew(data, employee_id);
        }
        setRestrictToCallSaveEmployeeApi(true);
        setcallEmployee(Math.random);
        message.success(`${info.file.name} file uploaded successfully`);
      } else if (info.file.status === "error") {
        message.error(`${info.file.name} file upload failed.`);
      } else {
        // message.error(`${info.file.name} file upload failed.`);
      }
    },
  };
  function callback(key: any) {
    setActive(key);
  }
  async function onChangeDoc(element: any, verify: any) {
    let data = {
      state: verify,
      document_type: element.document_type,
      document_url: element.document_url,
    };
    try {
      const result = await fileUpload(data, element.id);
      setVisible(false);
      setcallEmployee(Math.random);
    } catch (err) {}
  }

  async function onChangeRef(element: any, verify: boolean) {
    let data = {
      is_verified: verify,
    };
    setVisible(false);
    try {
      const result = await updateReferences(data, element.id);
      setcallLoan(Math.random);
    } catch (err) {}
  }

  // Document viewer function to handle url
  const handleClick = (url: string, index: number) => {
    setCurrentIndex(index);
    const downloadUrlArray = url.split(".");
    const extension = downloadUrlArray.pop();

    if (extension === "pdf") {
      setFileType("pdf");
      setCloseFile(true);
      const xhr = new XMLHttpRequest();
      // Open the request to the S3 bucket link
      xhr.open("GET", url, true);
      xhr.responseType = "blob";
      // Wait for the request to complete
      xhr.onload = () => {
        if (xhr.status === 200) {
          // Get the blob from the response
          const blob = xhr.response;
          const contentType = xhr.getResponseHeader("content-type");
          // Set the file name and type based on the content-type
          let fileName = "",
            fileType;
          if (contentType === "application/pdf") {
            fileName = "fileName.pdf";
            fileType = "application/pdf";
          }
          // Create a new File object
          const fileURL = new File([blob], fileName, { type: fileType });
          setFileURL(URL.createObjectURL(fileURL));
        } else {
          console.log("Error: ", xhr.status);
        }
      };
      xhr.send();
    } else {
      setFileType("image");
      setCloseFile(true);
      setFileURL(url);
    }
  };

  const columns = [
    {
      title: "Amount",
      dataIndex: "amount",
      key: "amount",
    },
    {
      title: "Payout Date",
      dataIndex: "payout_date",
      key: "payout_date",
    },
    {
      title: "Delete",
      render: (text: any, record: any) => {
        return (
          <Button
            type="primary"
            onClick={async () => {
              await deleteSalary(record.id);
              setcallEmployee(Math.random);
            }}
          >
            Delete Salary
          </Button>
        );
      },
      key: "delete_salary",
    },
  ];
  async function updateLoan(
    state_value: any = null,
    district: any = null,
    type: any = null,
    reload: any = null
  ) {
    try {
      // setVisible(false);
      let state_value_1: any = state;
      let city_value_1: any = city;
      let state_value_2: any = state_1;
      let city_value_2: any = city_1;
      if (type == 1) {
        state_value_1 = state_value;
        city_value_1 = district;
      }
      if (type == 2) {
        state_value_2 = state_value;
        city_value_2 = district;
      }
      let data = {
        first_name: first_name,
        last_name: last_name,
        address: address,
        state: state_value_1,
        city: city_value_1,
        pincode: pincode,
        father_name: fathername,
        dob: dob,
        // contact_no: mobile,
        contact_no_2: temp_mobile,
        whatsapp_no: whatsapp_no,
        gender: gender,
        marital_status: maritalStatus,
        pan: pan,
        ifsc_code: ifsc,
        account_no: account,
        company_name: employerName,
        aadhaar: aadhaar,
        email_id: email,
        address_1: address_1,
        pincode_1: pincode_1,
        state_1: state_value_2,
        city_1: city_value_2,
        bank_statement_password: bank_statement_password,
        beneficiary_name: beneficiary_name,
      };
      {
        // @ts-ignore
        const result = await employeeSave(data, internalEmpId);
        if (reload) {
          setcallEmployee(Math.random);
        }
      }
    } catch (err) {
      message.error("Could not Save Data!");
    }
  }

  async function updateAbb(
    state_value: any = null,
    district: any = null,
    type: any = null,
    reload: any = null
  ) {
    try {
      // setVisible(false);
      let state_value_1: any = state;
      let city_value_1: any = city;
      let state_value_2: any = state_1;
      let city_value_2: any = city_1;

      {
        // @ts-ignore
        const result = await abbSave(
          { balance: Number(abb_modified) },
          internalEmpId || ""
        );
        if (reload) {
          setcallEmployee(Math.random);
        }
      }
    } catch (err) {
      message.error("Could not Save Data!");
    }
  }
  async function onChange(checked: any) {
    setLoanStatus(checked);
  }

  async function updateLoanRequesData(request_id: any) {
    try {
      let loan_request_data = {
        suggested_amount_requested: Number(suggested_amount),
        suggested_loan_period: Number(suggested_period),
        purpose_of_loan: String(loan_purpose),
      };
      const updateloanrequest = await updateLoanRequest(
        request_id,
        loan_request_data
      );
    } catch (err) {
      message.error("Could not update data!");
    }
  }
  async function updateLoanStatus(type: any) {
    setVisible(false);
    if (type == "operations") {
      let data = {
        loan_ids: [request_id],
        status: option,
        request_status_comment: status_comment,
      };
      const loans = await updateBulk(data);
      window.location.reload();
    } else if (type == "appraisal") {
      let data = {
        loan_ids: [loan_id],
        status: option,
        request_status_comment: status_comment,
      };
      const loans = await updateBulkAppraised(data);
      window.location.reload();
    }
  }

  async function deleteResource(emp_id: number, type: string) {
    if (emp_id != -1) {
      const resources = await deleteResources(emp_id, type);
      if (resources.statusCode == 200) {
        message.success(resources.message);
        setTimeout(() => {
          window.location.reload();
        }, 2000); // Reload after 2 seconds
      } else message.error(resources.message);
    }
  }

  async function updateEmploymentSingle(key: string) {
    try {
      // setVisible(false);
      let data = {
        date_of_joining: doj,
        designation: designation,
        //monthly_salary: Number(empsalary),
        work_location: workLocation,
        job_type: job_type,
        department: department,
        deputed_at: deputed_at,
      };
      {
        // @ts-ignore
        const result = await updateEmployment(key, data);
        // setcallEmployee(Math.random);
      }
    } catch (err) {
      message.error("could not update data");
    }
  }

  const refCols: any = [
    {
      title: "Name",
      dataIndex: "name",
      key: "name",
    },
    {
      title: "Address",
      dataIndex: "address",
      key: "address",
    },
    {
      title: "Phone",
      dataIndex: "mobile_no",
      key: "mobile_no",
    },

    {
      title: "Relation",
      dataIndex: "relation",
      key: "relation",
    },
    {
      title: "Update State",
      render: (text: any, record: any) => {
        return (
          <Select
            defaultValue="please select status"
            style={{ width: "100%", marginLeft: "2%" }}
            onChange={async (el) => {
              await refVerifiedUpdate(el, Number(record.id));
            }}
          >
            {reference_status.map((element: any, index: number) => {
              return (
                <Option value={element} key={`${element}-${index}`}>
                  {element}
                </Option>
              );
            })}
          </Select>
        );
      },
      key: "verified_button",
    },
    {
      title: "State",
      render: (text: any, record: any) => {
        return record.state === "APPROVED" ? (
          <Tooltip title="APPROVED">
            <CheckCircleFilled style={{ fontSize: "30px" }} />
          </Tooltip>
        ) : record.state === "REJECTED" ? (
          <Tooltip title="REJECTED">
            <CloseCircleFilled style={{ fontSize: "30px" }} />
          </Tooltip>
        ) : (
          <Tooltip title="PENDING">
            <ExclamationCircleOutlined style={{ fontSize: "30px" }} />
          </Tooltip>
        );
      },
      key: "is_verified",
    },
    {
      title: "Update",
      render: (text: any, record: any) => {
        return (
          <Button
            type="primary"
            onClick={() => {
              updateModalSelectedReference(record);
            }}
          >
            Update
          </Button>
        );
      },
      key: "update",
      dataIndex: "update",
    },
  ];
  const commentCols: any = [
    {
      title: "Comment",
      dataIndex: "comment",
      key: "comment",
    },
    {
      title: "Author",
      dataIndex: "author",
      key: "author",
    },
    {
      title: "Created",
      dataIndex: "created_at",
      key: "created_at",
    },
  ];

  const addressCols: any = [
    {
      title: "Address",
      dataIndex: "address",
      key: "address",
    },
    {
      title: "Document Type",
      dataIndex: "type",
      key: "type",
    },
  ];

  const statusLogsCols: any = [
    {
      title: "Status",
      dataIndex: "status",
      key: "status",
    },
    {
      title: "Updated",
      dataIndex: "status_time",
      key: "status_time",
    },
  ];
  const documentCols: any = [
    {
      title: "Document",
      render: (text: any, record: any) => {
        return (
          <a
            style={{ fontSize: "15px" }}
            target="_blank"
            href={record.document_url}
          >
            {record.document_name.toUpperCase()}
          </a>
        );
      },
      key: "name",
    },
    {
      title: "Verification",
      render: (text: any, record: any) => {
        return record.state == "APPROVED" ? (
          <Tooltip title="APPROVED">
            <CheckCircleFilled style={{ fontSize: "30px" }} />
          </Tooltip>
        ) : record.state == "REJECTED" ? (
          <Tooltip title="REJECTED">
            <CloseCircleFilled style={{ fontSize: "30px" }} />
          </Tooltip>
        ) : (
          <Tooltip title="PENDING">
            <ExclamationCircleOutlined style={{ fontSize: "30px" }} />
          </Tooltip>
        );
      },
      key: "state",
    },
    {
      title: "Delete",
      render: (text: any, record: any) => {
        return (
          <DeleteOutlined
            onClick={async () => {
              setIsModalDeleteId(record.id);
              setIsModalDelete(true);
            }}
            style={{ fontSize: "30px" }}
          />
        );
      },
      key: "delete",
    },
    {
      title: "Validation",
      render: (text: any, record: any) => {
        return record.validation_state == "APPROVED" ? (
          <Tooltip title="VALIDATION APPROVED">
            <FileDoneOutlined style={{ fontSize: "30px" }} />
          </Tooltip>
        ) : record.validation_state == "REJECTED" ? (
          <Tooltip title="VALIDATION REJECTED">
            <FileExcelOutlined style={{ fontSize: "30px" }} />
          </Tooltip>
        ) : (
          <Tooltip title="VALIDATION PENDING">
            <FileExclamationOutlined style={{ fontSize: "30px" }} />
          </Tooltip>
        );
      },
      key: "validation_state",
    },
    {
      title: "Update State",
      render: (text: any, record: any) => {
        return (
          <Select
            defaultValue="please select status"
            style={{ width: "100%" }}
            onChange={async (el) => {
              await refVerifiedUpdate(el, Number(record.id));
            }}
          >
            {reference_status.map((element: any, index: number) => {
              return (
                <Option value={element} key={`${element}-${index}`}>
                  {element}
                </Option>
              );
            })}
          </Select>
        );
      },
      key: "verified_button",
    },
    {
      title: "Upload New",
      render: (text: any, record: any) => {
        return (
          <Button
            icon={<UploadOutlined />}
            onClick={() => {
              setCurrentDocument(record);
            }}
            style={{}}
          >
            Upload
          </Button>
        );
      },
      key: "upload_document",
    },
    {
      title: "Rejected Fields",
      render: (text: any, record: any) => {
        return record.rejected_fields.map((element: any, index: number) => {
          return (
            <Tag color="red" key={`${element}-${index}`}>
              {element}
            </Tag>
          );
        });
      },
      key: "rejected_fields",
    },
  ];

  const existingLoanRequestsCols: any = [
    {
      title: "Request ID",
      dataIndex: "request_id",
      key: "request_id",
    },
    {
      title: "Request Status",
      dataIndex: "request_status",
      key: "request_status",
    },
    {
      title: "Request Date",
      dataIndex: "request_date",
      key: "request_date",
    },
  ];

  const existingLoansCols: any = [
    {
      title: "Loan ID",
      dataIndex: "loan_id",
      key: "loan_id",
    },
    {
      title: "Ops ID",
      dataIndex: "ops_id",
      key: "ops_id",
    },
    {
      title: "Borrower ID",
      dataIndex: "borrower_id",
      key: "borrower_id",
    },
    {
      title: "Loan Status",
      dataIndex: "loan_status",
      key: "loan_status",
    },
    {
      title: "Action",
      render: (text: any, record: any) => {
        return (
          <Button
            type="primary"
            shape="round"
            style={{ paddingInline: '10px' }}
            onClick={() => {
              createLoanReportExport(record.borrower_id, false);
            }}
          >
            Generate Report
          </Button>
        );
      },
      key: "action",
      dataIndex: "action",
    },
  ];

  async function updateModalSelectedReference(element: any) {
    setCurrenRef(element);
    setRefAddress(element.address);
    setRefMobileNo(element.mobile_no);
    setRefName(element.name);
    setRefRelation(element.relation);
    setRefVerified(element.is_verified);
    setIsModalVisible(true);
  }
  async function updateValues() {
    setCurrenRef({});
    setRefAddress("");
    setRefMobileNo("");
    setRefName("");
    setRefRelation("");
    setRefVerified(false);
  }
  async function updateValuesSalary() {
    setSalaryDate("");
    setSalaryAmount("");
  }
  function parseDocuments(docs: Array<DocumentSchema>) {
    let parsed_docs = _.chain(docs)
      // Group the elements of Array based on `color` property
      .groupBy("document_type")
      // `key` is group's name (color), `value` is the array of objects
      .map((value, key) => ({ document_type: key, docs: value }))
      .value();

    let new_array: any = [];

    Object.keys(parsed_docs).forEach((el: any) => {
      // sort them by id
      parsed_docs[el]["docs"].sort((a: any, b: any) => {
        if (a.id > b.id) {
          return 1;
        } else {
          return -1;
        }
      });
      parsed_docs[el]["docs"].forEach((element) => {
        new_array.push(element);
      });
    });
    // docs.sort((a: any, b: any) => {
    //   if (a.document_type > b.document_type) {
    //     return 1;
    //   } else {
    //     return -1;
    //   }
    // });

    let counter: number = 0;
    let key: any = "";
    if (docs[0]) {
      key = docs[0]["document_type"];
    }

    new_array.forEach((el: any) => {
      let status = el["document_type"];
      if (status == key) {
        counter++;
      } else {
        counter = 1;
      }
      el["document_name"] = `${el["document_type"] + " " + counter}`;
      key = status;
    });
    return docs;
  }
  function RouteGuard() {
    let check: boolean = false;
    if (localStorage.getItem("token") && localStorage.getItem("user")) {
      check = true;
    }
    return check;
  }

  async function getExistingLoansData(empId: any) {
    const existingLoansResponse = await getExistingLoans(empId);
    let existingLoansData = [];
    for (let loanRequest of existingLoansResponse.data) {
      existingLoansData.push({
        loan_id: loanRequest.id,
        ops_id: loanRequest.ops_id,
        borrower_id: loanRequest.borrower_id,
        loan_status: loanRequest.request_status,
      });
    }
    setLoans(existingLoansData);

    const existingLoanRequestsResponse = await getExistingLoanRequests(empId);
    let existingLoanRequestsData = [];
    for (let loanRequest of existingLoanRequestsResponse.data) {
      existingLoanRequestsData.push({
        request_id: loanRequest.id,
        request_status: loanRequest.request_status,
        request_date: moment.utc(loanRequest.created_at).format("DD-MM-YYYY"),
      });
    }
    setLoanRequests(existingLoanRequestsData);
  }

  async function bankStatementDataFunction(empId: any) {
    const bankStatementDataResponse = await getBankStatementData(empId);
    const bankStatementDataObject = bankStatementDataResponse.data;
    setBankStatementData(bankStatementDataObject);
  }

  async function additionalInfoFunction(empId: any) {
    const employeeDetailsData = await employeeDetails(empId);
    setAdditionalInfo(employeeDetailsData);
  }

  useEffect(() => {
    getExistingLoansData(internalEmpId);
    bankStatementDataFunction(internalEmpId);
    additionalInfoFunction(internalEmpId);
  }, [internalEmpId]);

  useEffect(() => {
    dispatch(reset());
    dispatch(fetchLoanRequest(Number(request_id)));
    dispatch(fetchEmployee(String(employee_id)));
    dispatch(fetchLogs(request_id));
    dispatch(fetchStream('loan_request',request_id));
    dispatch(fetchComments(request_id));
    if (loan_id) {
      dispatch(fetchLoan(Number(loan_id)));
      dispatch(fetchArthmate(loan_id));
    }
  }, [callEmployee, callLoan]);

  useEffect(() => {
    (async () => {
      const routeguard: boolean = RouteGuard();
      if (routeguard) {
        if (type == "operations") {
          setStates(OperationsStatus);
        } else if (type == "appraisal") {
          setStates(AppraisalStatus);
        }
        if (loanRequestStatus) {
          //@ts-ignore

          //@ts-ignore
          const products = loanRequest;
          setTemp(products);

          if (type == "operations") {
            setStatusComment(products["request_status_comment"]);
          }
          // set suggested amount
          setSuggestedAmount(String(products["suggested_amount_requested"]));
          setSuggestedPeriod(String(products["suggested_loan_period"]));
          setLoanPurpose(String(products["purpose_of_loan"]));
          // setReferences(products["references"]);
          setTimeout(() => {
            setLoanCount(1);
          }, 100);
          setVisible(true);
        }
        if (CommentsStatus) {
          setComments(Comments.data);
        }
        if (LogsStatus) {
          setlogs(Logs.data);
        }
      } else {
        window.location.href = "/no_cred";
      }
    })();
  }, [loanRequestStatus, LogsStatus, CommentsStatus]);

  useEffect(() => {
    if (loanDataStatus && type == "appraisal") {
      setStatusComment(loanData["request_status_comment"]);
    }
  }, [loanDataStatus]);

  useEffect(() => {
    (async () => {
      // @ts-ignore
      if (employeeStatus) {
        const res = employee;
        // call bank api

        // bank api
        const json: any = res;
        // if (bank_name.length !== 0) {
        //   const bank = await BankDetailsFetch(
        //     json["bank_details"]["ifsc_code"]
        //   );
        //   if (bank) {
        //     setBankName(bank["BANK"]);
        //     setBankBranch(bank["BRANCH"]);
        //     setBankState(bank["STATE"]);
        //   }
        // }
        setInternalEmpId(json["id"]);
        setSalary(modifiedSalary(json["salaries"]));
        setEmployment(json["employment"]);
        setBank(json["bank_details"]);

        setDocument(parseDocuments(json["documents"]));
        // documents
        CheckDocuments(json["documents"]);
        // set Personal Details
        setFirstName(json["first_name"]);
        setLastName(json["last_name"]);
        setAddress(json["address"]);
        setOtherAddress(json["other_address"]);
        setState(json["state"]);
        setCity(json["city"]);
        setPincode(json["pincode"]);

        setAddress_1(json["address_1"]);
        setState_1(json["state_1"]);
        setCity_1(json["city_1"]);
        setPincode_1(json["pincode_1"]);

        setMaritalStatus(json["marital_status"]);
        setGender(json["gender"]);
        setMobile(json["mobile"]);
        setTempMobile(json["mobile_2"]);
        setWhatsAppNo(json["whatsapp_no"]);
        setPan(json["pan_no"]);
        setAadhaar(json["aadhaar"]);
        setFatherName(json["father_name"]);
        setDob(json["dob"]);
        setEmail(json["email_id"]);
        setPersonal(json);
        // set ops id
        setOpsId(json["ops_id"]);
        // set Bank Details
        setIfsc(json["bank_details"]["ifsc_code"]);
        setBankBranch(json["bank_details"]["bank_branch"]);
        setBankName(json["bank_details"]["bank_name"]);

        if (json["is_bank_account_verified"] == 0) {
          setBankStatus("NOT CHECKED");
        } else if (json["is_bank_account_verified"] == 1) {
          setBankStatus("VALID BANK ACCOUNT");
        } else if (json["is_bank_account_verified"] == 2) {
          setBankStatus("INVALID BANK ACCOUNT");
        }
        setBankState(json["bank_details"]["bank_state"]);
        setPassword(json["bank_details"]["bank_statement_password"]);
        setAccount(String(json["bank_details"]["account_no"]));
        setBankCode(String(json["bank_details"]["bank_code"]));
        setAbb(String(json["bank_details"]["abb"]));
        setAbbModified(String(json["bank_details"]["abb_required"]));
        setCibil(String(json["bank_details"]["cibil_score"]));
        setBeneficiaryName(String(json["bank_details"]["beneficiary_name"]));
        // set Employment
        setEmployerName(json["employment"]["employer_name"]);
        setDesignation(json["employment"]["designation"]);
        setEmpSalary(json["employment"]["salary"]);
        setDoj(json["employment"]["date_of_joinig"]);
        setWorkLocation(json["employment"]["work_location"]);
        setDepartMent(json["employment"]["department"]);
        setDeputedAt(json["employment"]["deputed_at"]);
        setJobType(
          json["employment"]["job_type"].length == 0
            ? "permanent"
            : json["employment"]["job_type"]
        );

        const { district, state } = await PostalCodeApi(json["pincode"]);
        setState(state);
        setCity(district);
        if (!restrictToCallSaveEmployeeApi) {
          updateLoan(state, district, 1);
        }

        const data = await PostalCodeApi(json["pincode_1"]);
        setState_1(data["state"]);
        setCity_1(data["district"]);
        if (!restrictToCallSaveEmployeeApi) {
          updateLoan(data["state"], data["district"], 2);
        }
        // set references
        setReferences(json["references"]);
        // do the bank details set
        // const { BANK, BRANCH, STATE } = await BankDetailsFetch(
        //   json["bank_details"]["ifsc_code"]
        // );
        // setBankBranch(BRANCH);
        // setBankName(BANK);
        // setBankState(STATE);
        if (!restrictToCallSaveEmployeeApi) {
          updateLoan();
        }
        setTimeout(() => {
          setEmpCount(1);
        }, 100);

        // set Visible
        setVisible(true);
        setRestrictToCallSaveEmployeeApi(false);
      }
    })();
  }, [employeeStatus]);

  useEffect(() => {
    (async () => {
      {
        // @ts-ignore
        if (arthmateStatus) {
          const res = arthmate;
          // call bank api

          // bank api
          const arthmateJson: any = res;
          if (arthmateJson["data"].length > 0) {
            setarthmateEmpId(arthmateJson["data"][0]["arthmate_emp_id"]);
            setarthmateLoanId(arthmateJson["data"][0]["arthmate_loan_id"]);
            setarthmatePostLoanId(
              arthmateJson["data"][0]["arthmate_post_loan_id"]
            );
            sendNfcStatus(arthmateJson["data"][0]["status"]);
            setNbfcError(arthmateJson["data"][0]["error"]);
          } else {
            setarthmateEmpId("NOT KNOWN");
            setarthmateLoanId("NOT KNOWN");
            setarthmatePostLoanId("NOT KNOWN");
            sendNfcStatus("NOT KNOWN");
            setNbfcError("NOT KNOWN");
          }

          // set Visible
          setVisible(true);
        }
      }
    })();
  }, [arthmateStatus]);

  useEffect(() => {
    if (firstUpdate.current) {
      firstUpdate.current = false;
      return;
    }
    if (firstUpdate.current == false && loan_count == 1 && emp_count == 1)
      (async () => {
        if (prevpincode !== pincode) {
          const { district, state } = await PostalCodeApi(pincode);
          setState(state);
          setCity(district);
          updateLoan(state, district, 1);
        }
        if (prevpincode_1 !== pincode_1) {
          const { district, state } = await PostalCodeApi(pincode_1);
          setState_1(state);
          setCity_1(district);
          updateLoan(state, district, 2);
        }
        if (prev_ifsc !== ifsc) {
          //  const { BANK, BRANCH, STATE } = await BankDetailsFetch(ifsc);
          // setBankBranch(BRANCH);
          // setBankName(BANK);
          // setBankState(STATE);
          const result = await BankDetailsUpdateDatabase(ifsc);
          // setcallEmployee(Math.random);
          updateLoan(null, null, null, true);
        }
        if (prev_account_no !== account) {
          updateLoan();
        }

        if (prev_abb_modified !== abb_modified) {
          updateAbb();
        }

        if (prev_first_name !== first_name) {
          updateLoan();
        }
        if (prev_last_name !== last_name) {
          updateLoan();
        }
        if (prev_fathername !== fathername) {
          updateLoan();
        }
        if (prev_dob !== dob) {
          updateLoan();
        }
        if (prev_email !== email) {
          updateLoan();
        }
        if (prev_gender !== gender) {
          updateLoan();
        }
        if (prev_marital_status !== maritalStatus) {
          updateLoan();
        }
        if (prev_pan !== pan) {
          updateLoan();
        }
        if (prev_aadhaar !== aadhaar) {
          updateLoan();
        }
        if (temp_contact !== temp_mobile) {
          updateLoan();
        }
        if (prev_whatsapp_no !== whatsapp_no) {
          updateLoan();
        }
        if (prev_address_1 !== address_1) {
          updateLoan();
        }
        if (prev_address !== address) {
          updateLoan();
        }
        if (prev_password !== bank_statement_password) {
          updateLoan();
        }
        if (prev_beneficiary_name !== beneficiary_name) {
          updateLoan();
        }
        if (prev_designation !== designation) {
          updateEmploymentSingle(String(employment?.id));
        }
        if (prev_emp_salary !== empsalary) {
          updateEmploymentSingle(String(employment?.id));
        }
        if (prev_workLocation !== workLocation) {
          updateEmploymentSingle(String(employment?.id));
        }
        if (prev_doj !== doj) {
          updateEmploymentSingle(String(employment?.id));
        }
        if (prev_department !== department) {
          updateEmploymentSingle(String(employment?.id));
        }
        if (prev_job_type !== job_type) {
          updateEmploymentSingle(String(employment?.id));
        }
        if (prev_deputed_at !== deputed_at) {
          updateEmploymentSingle(String(employment?.id));
        }
        if (loan_purpose !== prev_loan_purpose) {
          updateLoanRequesData(request_id);
        }
        if (suggested_period !== prev_suggested_period) {
          updateLoanRequesData(request_id);
        }

        if (suggested_amount !== prev_suggested_amount) {
          updateLoanRequesData(request_id);
        }
      })();
  }, [
    pincode,
    pincode_1,
    ifsc,
    account,
    first_name,
    last_name,
    fathername,
    dob,
    email,
    gender,
    maritalStatus,
    deputed_at,
    pan,
    aadhaar,
    temp_mobile,
    whatsapp_no,
    address,
    address_1,
    designation,
    empsalary,
    workLocation,
    doj,
    department,
    job_type,
    loan_purpose,
    suggested_amount,
    suggested_period,
    bank_statement_password,
    beneficiary_name,
    abb_modified,
  ]);

  function handleChange(value: any) {
    setOption(value);
  }
  function handleChangeDocument(value: any) {
    setDocOption(value);
  }
  //const delay = (ms: number) => new Promise(res => setTimeout(res, ms));

  function modifiedSalary(data: typeof modifiedSalary | any) {
    for (let index = 0; index < data.length; index++) {
      let today = new Date(data[index].payout_date);
      let dd = String(today.getDate()).padStart(2, "0");
      let mm = String(today.getMonth() + 1).padStart(2, "0");
      let yyyy = today.getFullYear();
      data[index].payout_date = dd + "/" + mm + "/" + yyyy;
    }
    return data;
  }
  async function callCibil() {
    setLoading(true);
    await generateCibil(loan_id);
    //await delay(5000);
    setcallEmployee(Math.random);
    setLoading(false);
  }

  async function callValidateBank() {
    setLoading(true);
    await bankValidate(request_id);
    setLoading(false);
  }

  function CheckDocuments(data: any) {
    let array = [
      "pan",
      "aadhaar_front",
      "aadhaar_back",
      "bank_statement",
      "selfie",
      "current_address_proof",
      "salary_slip",
      "passbook",
      "permanent_address_front",
      "permanent_address_back",
    ];
    let result: any = [];
    let doc_array: any = [];
    data.forEach((e: any) => {
      doc_array.push(e.document_type);
    });
    array.forEach((el: any) => {
      // if (!doc_array.includes(el))
      result.push({ type: el });
    });
    setDocStates(result);
    if (result.length == 0) {
      setDocPresent(false);
    }
  }
  function returnName(name: string) {
    let result = "";
    name.split("_").forEach((el) => {
      result = result + " " + el.toUpperCase();
    });
    return result;
  }

  const eNach = async () => {
    let data = {
      loan_ids: [loan_id],
    };
    setVisible(true);
    const loans = await enach(data);
    message.success("Enach generated Successfully");
    setTimeout(() => {
      window.location.reload();
    }, 1000);
  };
  const eSign = async () => {
    let data = {
      loan_ids: [loan_id],
    };
    setVisible(true);
    const loans = await esign(data);

    message.success("Esignature generated Successfully");
    setTimeout(() => {
      window.location.reload();
    }, 1000);
  };
  const appraisalNotify = async () => {
    let data = {
      loan_ids: [loan_id],
    };
    setVisible(true);
    const loans = await appraisalNotifications(data);
    message.success("Appraisal notifications sent");
    setTimeout(() => {
      window.location.reload();
    }, 1000);
  };
  const deleteDoc = async () => {
    try {
      await deleteDocument(isModalDeleteId);
      setVisible(false);
      setRestrictToCallSaveEmployeeApi(true);
      setcallEmployee(Math.random);
    } catch (err) {
      message.error("could not delete document");
    }
  };

  const personalProps = {
    first_name: first_name,
    setFirstName,
    last_name,
    setLastName,
    fathername,
    setFatherName,
    dob,
    setDob,
    email,
    setEmail,
    gender,
    setGender,
    maritalStatus,
    setMaritalStatus,
    mobile,
    pan,
    setPan,
    aadhaar,
    setAadhaar,
    temp_mobile,
    setTempMobile,
    temp,
    opsId,
    whatsapp_no,
    setWhatsAppNo,
  };

  const bankProps = {
    ifsc,
    setIfsc,
    account,
    setAccount,
    bank_name,
    bank_branch,
    bank_state,
    bank_code,
    bank_statement_password,
    setPassword,
    beneficiary_name,
    setBeneficiaryName,
    abb,
    type,
    cibil,
    arthmateLoanId,
    arthmateEmpId,
    setAbbModified,
    abb_modified,
    arthmatePostLoanId,
    nbfcStatus,
    nbfcError,
    analyseStatements,
    loading,
    callCibil,
    bankStatus,
    employee_id,
    callValidateBank
  };

  const documentProps = {
    documents,
    handleClick,
    onChangeDoc,
    docstatuses,
    setCurrentDocument,
    setIsModalDelete,
    setIsModalDeleteId,
    fileType,
    currentIndex,
    closeFile,
    setCloseFile,
    fileURL,
    deleteResource,
    internalEmpId,
    docpresent,
    handleChangeDocument,
    docstates,
    propsNewDocument,
  };

  const employmentProps = {
    employerName,
    designation,
    setDesignation,
    empsalary,
    workLocation,
    setWorkLocation,
    doj,
    setDoj,
    department,
    setDepartMent,
    job_type,
    setJobType,
    deputed_at,
    setDeputedAt,
    salary,
    columns,
    updateValuesSalary,
    setIsSalaryModalVisible,
  };

  const refProps = {
    refCols,
    references,
    deleteResource,
    internalEmpId,
    setNewReference,
    updateValues,
    setIsModalVisible,
  };

  const addressProps = {
    address,
    setAddress,
    state,
    city,
    pincode,
    setPincode,
    address_1,
    setAddress_1,
    state_1,
    city_1,
    pincode_1,
    setPincode_1,
    employeeStatus,
    addressCols,
    otheraddress,
  };

  const commentProps = {
    CommentsStatus,
    comments,
    commentCols,
    setIsCommentModalVisible,
  };

  const statusProps = {
    LogsStatus,
    statusLogsCols,
    logs,
  };

  const existingLoansProps = {
    existingLoanRequestsCols,
    existingLoansCols,
    loans,
    setLoans,
    loanRequests,
    setLoanRequests,
  };

  return (
    <div
      style={{
        backgroundColor: "#f0f0f0",
        height: height,
        width: width * 0.9,
        overflowY: "scroll",
        overflowX: "hidden",
      }}
    >
      {visible === true ? (
        <div>
          {/** Loan Details Overview Section */}
          <Row>
            <Col span={23}>
            <ViewToggle 
              defaultValue={false}
              redirectUrl={`/appraisal-detail${search}`}
              />
            </Col>
          </Row>
          <Row>
            <Col span={20}>
              <Card
                bordered={true}
                style={{
                  width: width * 0.83,
                  margin: "2.5%",
                  marginTop: "0px",
                  boxShadow: "0px 2px 4px 0px rgba(0, 0, 0, 0.2)",
                }}
              >
                <Row>
                  <Col span={20}>
                    <p
                      style={{
                        fontFamily: "Helvetica Neue",
                        fontSize: "14px",
                        // marginLeft: "2%",
                        // marginTop: "1%",
                        color: "grey",
                        lineHeight: "16px",
                        display: "flex",
                      }}
                    >
                      <LeftOutlined
                        onClick={() => history.goBack()}
                        style={{
                          paddingRight: "10px",
                        }}
                      />
                      {type === "appraisal"
                        ? "Appraisal Section"
                        : "Operations Section"}
                    </p>
                  </Col>
                  <Col span={4} style={{ marginBottom: "2%" }}>
                    <Button
                      type="primary"
                      shape="round"
                      onClick={showDrawer}
                      style={{ background: "#096dd9" }}
                    >
                      Update Actions
                    </Button>
                  </Col>
                </Row>
                <Divider></Divider>
                <Row
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    alignItems: "center",
                    justifyContent: "space-between",
                  }}
                >
                  <Col span={5} style={{ marginBottom: "1%" }}>
                    <Statistic
                      title="Amount Requested:"
                      value={temp?.["amount_requested"]}
                      valueStyle={{ fontSize: "14px", fontWeight: "bold" }}
                    />
                  </Col>

                  <Col span={5} style={{ marginBottom: "1%" }}>
                    <p style={{ color: "#8c8c8c", marginBottom: "1%" }}>
                      Suggested Amount:
                    </p>
                    <Text
                      style={{ fontSize: "14px", fontWeight: "bold" }}
                      editable={{
                        icon: <HighlightOutlined />,
                        tooltip: "click to edit text",
                        onChange: setSuggestedAmount,
                      }}
                    >
                      {suggested_amount}
                    </Text>
                  </Col>

                  <Col span={5} style={{ marginBottom: "1%" }}>
                    <Statistic
                      valueStyle={{ fontSize: "14px", fontWeight: "bold" }}
                      groupSeparator=""
                      title="Mobile Number:"
                      value={temp?.["mobile_no"]}
                    />
                  </Col>
                </Row>
                <Row
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    alignItems: "center",
                    justifyContent: "space-between",
                  }}
                >
                  <Col span={5} style={{ marginBottom: "1%" }}>
                    <Statistic
                      valueStyle={{ fontSize: "14px", fontWeight: "bold" }}
                      title="Request Id:"
                      value={temp?.["ops_id"]}
                    />
                  </Col>
                  <Col span={5} style={{ marginBottom: "1%" }}>
                    <Statistic
                      valueStyle={{ fontSize: "14px", fontWeight: "bold" }}
                      title="Request Status Comment:"
                      value={
                        temp?.["request_status_comment"]?.length == 0
                          ? "  "
                          : temp?.["request_status_comment"]
                      }
                    />
                  </Col>

                  {type == "operations" ? (
                    <Col span={5} style={{ marginBottom: "1%" }}>
                      <Statistic
                        title={"Request Status:"}
                        value={temp?.["request_status"]}
                        valueStyle={{ fontSize: "14px", fontWeight: "bold" }}
                      />
                    </Col>
                  ) : (
                    ""
                  )}

                  {type == "appraisal" ? (
                    <Col span={5} style={{ marginBottom: "1%" }}>
                      <Statistic
                        title={"Loan Status Comment:"}
                        value={
                          status_comment.length == 0 ? " " : status_comment
                        }
                        valueStyle={{ fontSize: "14px", fontWeight: "bold" }}
                      />
                    </Col>
                  ) : (
                    ""
                  )}
                </Row>

                <Row
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    alignItems: "center",
                    justifyContent: "space-between",
                  }}
                >
                  <Col span={5} style={{ marginBottom: "1%" }}>
                    <Statistic
                      title="Salary Amount:"
                      value={bankStatementData?.["salary_amount"]}
                      valueStyle={{ fontSize: "14px", fontWeight: "bold" }}
                    />
                  </Col>
                  <Col span={5} style={{ marginBottom: "1%" }}>
                    <Statistic
                      title="Existing EMI:"
                      value={bankStatementData?.["existing_emi"]}
                      valueStyle={{ fontSize: "14px", fontWeight: "bold" }}
                    />
                  </Col>
                  <Col span={5} style={{ marginBottom: "1%" }}>
                    <Statistic
                      valueStyle={{ fontSize: "14px", fontWeight: "bold" }}
                      groupSeparator=""
                      title="Average Balance:"
                      value={bankStatementData?.["avg_balance"]}
                    />
                  </Col>
                </Row>

                <Row
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    alignItems: "center",
                    justifyContent: "space-between",
                  }}
                >
                  <Col span={5} style={{ marginBottom: "1%" }}>
                    <Statistic
                      title="Bounced Charges Count:"
                      value={bankStatementData?.["bounced_charges_count"]}
                      valueStyle={{ fontSize: "14px", fontWeight: "bold" }}
                    />
                  </Col>
                  <Col span={5} style={{ marginBottom: "1%" }}>
                    <Statistic
                      title="Bounced Charges Amount:"
                      value={bankStatementData?.["bounced_charges_amount"]}
                      valueStyle={{ fontSize: "14px", fontWeight: "bold" }}
                    />
                  </Col>
                  <Col span={5} style={{ marginBottom: "1%" }}>
                    <Statistic
                      valueStyle={{ fontSize: "14px", fontWeight: "bold" }}
                      groupSeparator=""
                      title="New Loan Taken:"
                      value={bankStatementData?.["new_loan_total_emi"]}
                    />
                  </Col>
                </Row>

                <Divider></Divider>
                <Row
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    alignItems: "center",
                    justifyContent: "space-around",
                  }}
                >
                  <Button
                    shape="round"
                    type="primary"
                    onClick={() => {
                      refreshTrigger(temp["mobile_no"] || "");
                    }}
                    style={{
                      marginRight: "1%",
                      marginTop: "0.5%",
                      background: "#003a8c",
                    }}
                  >
                    24 Hour Template
                  </Button>
                </Row>
                <Row>
                  <Col span={6}></Col>
                </Row>
              </Card>
            </Col>
            <Col span={4}></Col>
          </Row>

          {/** Employee Details Tabbed Section - Personal, Employment, Bank Details, Documents etc*/}
          <Card
            bordered={true}
            style={{
              width: width * 0.83,
              margin: "2%",
              boxShadow: "0px 2px 4px 0px rgba(0, 0, 0, 0.2)",
            }}
          >
            <Tabs
              onChange={callback}
              size="large"
              style={{}}
              activeKey={active}
            >
              {/** Personal Details*/}
              <TabPane style={{ fontSize: "14px" }} tab="Personal" key="1">
                <PersonalDetails props={personalProps}></PersonalDetails>
              </TabPane>

              <TabPane tab="Bank and Financial" key="2">
                <EmpBankDetails props={bankProps}></EmpBankDetails>
              </TabPane>

              <TabPane tab="Documents" key="3">
                <DocumentDetails props={documentProps}></DocumentDetails>
              </TabPane>

              <TabPane tab="Employment" key="4">
                <EmploymentDetails props={employmentProps}></EmploymentDetails>
              </TabPane>

              <TabPane tab="References" key="6">
                <ReferenceDetails props={refProps}></ReferenceDetails>
              </TabPane>

              <TabPane tab="Address" key="7">
                <AddressDetails props={addressProps}></AddressDetails>
              </TabPane>

              <TabPane tab="Comments" key="8">
                <OpsComments props={commentProps}></OpsComments>
              </TabPane>

              <TabPane tab="Status Logs" key="9">
                <StatusLogs props={statusProps}></StatusLogs>
              </TabPane>

              <TabPane tab="Existing Loans" key="10">
                <ExistingLoans props={existingLoansProps}></ExistingLoans>
              </TabPane>

              <TabPane tab="Additional Info" key="11">
                <AdditionalInfo props={additionalInfo}></AdditionalInfo>
              </TabPane>
            </Tabs>
          </Card>
          {type === "appraisal" ? (
            <div style={{ width: width * 0.83, margin: "2%" }}>
              <Loan
                loan_id={loan_id}
                request_id={request_id}
                employee_id={employee_id}
              ></Loan>

              {/* <Row
                style={{
                  backgroundColor: "#f0f0f0",
                  paddingTop: "1%",
                  paddingBottom: "1%",
                  width: width * 0.9,
                  zIndex: 999,
                }}
              >
                <Col span={12}></Col>
                <Col span={10}></Col>
              </Row> */}
            </div>
          ) : (
            ""
          )}

          <Modal
            title="Reference"
            visible={isModalVisible}
            onOk={handleOk}
            onCancel={handleCancel}
          >
            <Row>
              <Col span={24}>
                <Form
                  form={form}
                  layout="vertical"
                  initialValues={{ requiredMarkValue: requiredMark }}
                  onValuesChange={onRequiredTypeChange}
                  requiredMark={requiredMark}
                >
                  <Form.Item
                    label="Name"
                    required
                    tooltip="This is a required field"
                  >
                    <Input
                      placeholder="name"
                      type="text"
                      value={ref_name}
                      onChange={(e) => {
                        setRefName(e.target.value);
                      }}
                    />
                  </Form.Item>
                  <Form.Item
                    label="Mobile No"
                    required
                    tooltip="This is a required field"
                  >
                    <Input
                      placeholder="mobile no"
                      type="number"
                      value={ref_mobile_no}
                      onChange={(e) => {
                        setRefMobileNo(e.target.value);
                      }}
                    />
                  </Form.Item>

                  <Form.Item
                    label="Address"
                    required
                    tooltip="This is a required field"
                  >
                    <Input
                      placeholder="address"
                      type="text"
                      value={ref_address}
                      onChange={(e) => {
                        setRefAddress(e.target.value);
                      }}
                    />
                  </Form.Item>

                  <Form.Item
                    label="Relation"
                    required
                    tooltip="This is a required field"
                  >
                    <Input
                      placeholder="relation"
                      type="text"
                      value={ref_relation}
                      onChange={(e) => {
                        setRefRelation(e.target.value);
                      }}
                    />
                  </Form.Item>
                  {/* <Form.Item
                    label="Verified"
                    required
                    tooltip="This is a required field"
                  >
                    <Checkbox
                      checked={ref_verified}
                      onChange={(e: any) => {
                        setRefVerified(e.target.checked);
                      }}
                    >
                      Is Verified
                    </Checkbox>
                  </Form.Item> */}
                </Form>
              </Col>
            </Row>
          </Modal>
          <Modal
            title="Salary"
            visible={isSalaryModalVisible}
            onOk={handleSalaryOk}
            onCancel={handleSalaryCancel}
          >
            <Row>
              <Col span={24}>
                <Form
                  form={form}
                  layout="vertical"
                  initialValues={{ requiredMarkValue: requiredMark }}
                  onValuesChange={onRequiredTypeChange}
                  requiredMark={requiredMark}
                >
                  <Form.Item
                    label="Amount"
                    required
                    tooltip="This is a required field"
                  >
                    <Input
                      placeholder="amount"
                      type="text"
                      value={salary_amount}
                      onChange={(e) => {
                        setSalaryAmount(e.target.value);
                      }}
                    />
                  </Form.Item>
                  <Form.Item
                    label="Payout Date"
                    required
                    tooltip="This is a required field"
                  >
                    <Input
                      placeholder="salary_date"
                      type="date"
                      value={salary_date}
                      onChange={(e) => {
                        setSalaryDate(e.target.value);
                      }}
                    />
                  </Form.Item>
                </Form>
              </Col>
            </Row>
          </Modal>
          <Modal
            title="Comment"
            visible={isCommentModalVisible}
            onOk={handleCommentOk}
            onCancel={handleCommentCancel}
          >
            <Row>
              <Col span={24}>
                <Form
                  form={form}
                  layout="vertical"
                  initialValues={{ requiredMarkValue: requiredMark }}
                  onValuesChange={onRequiredTypeChange}
                  requiredMark={requiredMark}
                >
                  <Form.Item
                    label="Comment"
                    required
                    tooltip="This is a required field"
                  >
                    <Input
                      placeholder="comment"
                      type="text"
                      value={comment}
                      onChange={(e) => {
                        setComment(e.target.value);
                      }}
                    />
                  </Form.Item>
                </Form>
              </Col>
            </Row>
          </Modal>

          <Drawer
            title="Actions"
            placement="right"
            onClose={onClose}
            visible={isDrawer}
            width={550}
          >
            <>
              <Card>
                <Row style={{ margin: "5%" }}>
                  <Col span={16}>
                    <Select
                      defaultValue="Option"
                      style={{
                        width: "100%",
                        marginRight: "10%",
                        marginBottom: "2%",
                      }}
                      onChange={handleChange}
                    >
                      {states.map((element: any, index: number) => {
                        return (
                          <Option value={element} key={`${element}-${index}`}>
                            {element}
                          </Option>
                        );
                      })}
                    </Select>
                    <Input
                      type="text"
                      placeholder="comment"
                      value={status_comment}
                      onChange={(e) => {
                        setStatusComment(e.target.value);
                      }}
                    ></Input>
                  </Col>
                  <Col span={8}>
                    <Button
                      type="primary"
                      style={{ marginTop: "", marginLeft: "10%" }}
                      shape="round"
                      onClick={() => {
                        if (option) {
                          updateLoanStatus(type);
                        } else {
                          message.warning(
                            "Please select an option to proceed!"
                          );
                        }
                      }}
                    >
                      Update Status
                    </Button>
                  </Col>
                </Row>
              </Card>
            </>
          </Drawer>
          <Modal
            title="Alert"
            visible={isModalDelete}
            onOk={() => {
              deleteDoc();
              setIsModalDeleteId("");
              setIsModalDelete(false);
            }}
            onCancel={() => {
              setIsModalDeleteId("");
              setIsModalDelete(false);
            }}
          >
            <p>Are you sure you want to delete this document ?</p>
          </Modal>
        </div>
      ) : (
        <Spin size="large" style={{ marginLeft: "40%", marginTop: "20%" }} />
      )}
    </div>
  );
}
