import {
  Row,
  Col,
  Divider,
  Card,
  Pagination,
  Statistic,
  Spin,
  Descriptions,
  Badge,
  Tooltip,
  Button,
  Select,
  Typography,
  Drawer,
  message,
  DatePicker,
  Upload,
  Input,
  PaginationProps,
} from "antd";
import moment from "moment";
import React from "react";
import { render } from "react-dom";
import { AgGridColumn, AgGridReact } from "ag-grid-react";
import { useEffect, useState, useRef, useLayoutEffect } from "react";
import "ag-grid-community/dist/styles/ag-grid.css";
import "ag-grid-community/dist/styles/ag-theme-alpine.css";
import { DrawerProps } from "antd/es/drawer";
import Entitled from "../assets/ent-logo-min.png";
import { BtnCellRenderer } from "./button";
import { Document } from "./icons/documents";
import { Message } from "./icons/message";
import { Employee } from "./icons/employee";
import { Activerequest } from "./icons/activerequests";
import { Enach } from "./icons/enach";
import {
  LogoutOutlined,
  AlignCenterOutlined,
  DoubleLeftOutlined,
  UploadOutlined,
  RetweetOutlined,
} from "@ant-design/icons";
import axios from "axios";
import { Loan } from "../types";
import {
  getLoans,
  getUsers,
  updateBulk,
  updateBulkUser,
} from "../utitlities/request";
import {
  OriginatedStatus,
  OriginatedStatusDisplay,
} from "../utitlities/status";
import { TopHeader } from "./common/topHeader";
import { DynamicList } from "../utitlities/filteroptions";
import { Duplicaterequest } from "./icons/duplicaterequests";
import { config } from "../config";
import usePrevious from "../utitlities/previous";
export default function Originated() {
  const gridRef: any = useRef(null);
  const [gridApi, setGridApi] = useState(null);
  const groupDisplayType = "singleColumn";
  const [gridColumnApi, setGridColumnApi] = useState(null);
  const gridApiRef = useRef<any>(null);
  const [view, setView] = useState(false);
  let r: any = 0;
  const [selectedRows, setSelectedRows] = useState(0);
  const prev_selectedRows = usePrevious(selectedRows);
  const [count, setCount] = useState(0);
  const [width, setWidth] = useState(window.innerWidth);
  const [height, setHeight] = useState(window.innerHeight);
  const [states, setStates] = useState<any>(null);
  const [rowData, setRowData] = useState<Array<Loan>>([]);
  const [visible, setVisible] = useState(false);
  const [currentState, setCurrentState] = useState("");
  const [option, setOption] = useState("");
  const [optionuser, setOptionUser] = useState("");
  const [isDrawer, setIsDrawer] = useState(false);
  const { Option } = Select;
  const [currentpage, setCurrentPage] = useState(1);
  const [total, setTotal] = useState(0);
  const [size, setSize] = useState(20);
  const [users, setUsers] = useState([]);
  const [role, setRole] = useState("");
  // @ts-ignore
  const [pagination, setPagination] = useState<number>(20);
  // @ts-ignore
  const [current_page, setCurrentpage] = useState<number>(0);
  const prev_page = usePrevious(pagination);
  const [limit, setLimit] = useState(true);
  const [status_comment, SetComment] = useState("");
  const { RangePicker } = DatePicker;
  const [start_date, setStartDate] = useState(
    moment().subtract(1, "weeks").format("YYYY-MM-DD")
  );
  const [end_date, setEndDate] = useState(
    moment().add(1, "days").format("YYYY-MM-DD")
  );
  const showDrawer = () => {
    setIsDrawer(true);
  };
  const onClose = () => {
    setIsDrawer(false);
  };
  const [status, setStatus] = useState("Please select a status");
  function updateDimension() {
    setWidth(window.innerWidth);
    setHeight(window.innerHeight);
  }
  function parseSalarydate1(params: any) {
    if (params.data.salary_1) {
      // let date = params.data.salary_1.includes("T")
      //   ? params.data.salary_1.split("T")[0].split("-")
      //   : params.data.salary_1.split(" ")[0].split("-");
      return moment(params.data.salary_1).format("DD/MM/YYYY");
    } else {
      return "";
    }
  }
  function parseSalarydate2(params: any) {
    if (params.data.salary_2) {
      // let date = params.data.salary_2.includes("T")
      //   ? params.data.salary_2.split("T")[0].split("-")
      //   : params.data.salary_2.split(" ")[0].split("-");
      return moment(params.data.salary_2).format("DD/MM/YYYY");
    } else {
      return "";
    }
  }
  function parseSalarydate3(params: any) {
    if (params.data.salary_3) {
      // let date = params.data.salary_3.includes("T")
      //   ? params.data.salary_3.split("T")[0].split("-")
      //   : params.data.salary_3.split(" ")[0].split("-");
      return moment(params.data.salary_3).format("DD/MM/YYYY");
    } else {
      return "";
    }
  }

  function AssignedTo(params: any) {
    if (params.data.user_name) {
      return params.data.user_name;
    } else {
      return "";
    }
  }

  const propsNewDocument = {
    name: "file",
    action: `${config.base_url}/utils/upload/active`,
    headers: {
      authorization: "authorization-text",
    },
    async onChange(info: any) {
      if (info.file.status !== "uploading") {
      }
      if (info.file.status === "done") {
        setVisible(false);
        message.success(`${info.file.name} file uploaded successfully`);
      } else if (info.file.status === "error") {
        message.error(`${info.file.name} file upload failed.`);
      } else {
        //message.error(`${info.file.name} file upload failed.`);
      }
    },
  };
  function parseAssignedToDate(params: any) {
    if (params.data.user_assigned_at) {
      let date = params.data.user_assigned_at.includes("T")
        ? params.data.user_assigned_at.split("T")[0].split("-")
        : params.data.user_assigned_at.split(" ")[0].split("-");
      return `${date[2]}/${date[1]}/${date[0]}`;
    } else {
      return "";
    }
  }
  function parseLatestSalaryDays(params: any) {
    let salarydate = params.data.salary_1
      ? params.data.salary_1
      : params.data.salary_2
        ? params.data.salary_2
        : params.data.salary_3;
    if (salarydate) {
      let latest_salary_day = moment(salarydate);
      let today = moment(new Date(params.data.date_of_enquiry).toISOString());
      return today.diff(latest_salary_day, "days");
    } else {
      return 0;
    }
  }
  function parseLoanData(data: any) {
    //  first salary date is latest salary 1
    data.forEach((element: any) => {
      if (element["salaries"]) {
        element["salaries"].sort((a: any, b: any) => {
          if (moment(a.date).diff(moment(b.date), "days") < 0) {
            return 1;
          } else {
            return -1;
          }
        });
        element["salary_1"] = element["salaries"][0]
          ? element["salaries"][0]["date"]
          : "";
        element["salary_2"] = element["salaries"][1]
          ? element["salaries"][1]["date"]
          : "";
        element["salary_3"] = element["salaries"][2]
          ? element["salaries"][2]["date"]
          : "";
      } else {
        element["salary_1"] = "";
        element["salary_2"] = "";
        element["salary_3"] = "";
      }
    });

    return data;
  }
  function Experience(params: any) {
    if (params.data.experience) {
      return Number(params.data.experience);
    } else {
      return "";
    }
  }
  function RouteGuard() {
    let check: boolean = false;
    if (localStorage.getItem("token") && localStorage.getItem("user")) {
      check = true;
    }
    return check;
  }
  useEffect(() => {
    (async () => {
      const routeguard: boolean = RouteGuard();
      if (routeguard) {
        let user: any = {};
        //@ts-ignore
        user = JSON.parse(localStorage.getItem("user"));
        setRole(user["role"]);
        const loans = await getLoans(
          currentpage,
          size,
          ["ORIGINATED"],
          user["id"],
          user["role"],
          start_date,
          end_date
        );
        // set filter options status
        const users = await getUsers();

        setUsers(users.filter((user: any) => {
          return user.role !== "deactivated"
        }));
        parseLoanData(loans["result"]);
        setRowData(loans["result"]);
        setTotal(loans["count"]);
        setStates(OriginatedStatus);
        setVisible(true);
        setTimeout(() => {
          reApplyFilter();
        }, 200);
      } else {
        window.location.href = "/no_cred";
      }
    })();
  }, [currentpage, size, start_date, end_date]);

  useLayoutEffect(() => {
    window.addEventListener("resize", updateDimension);
    updateDimension();
    return () => window.removeEventListener("resize", updateDimension);
  }, []);

  const reApplyFilter = () => {
    var hardcodedFilter = {
      status: {
        type: "equals",
        filter: status,
      },
    };

    if (status !== "Please select a status") {
      if (gridApiRef?.current) {
        const filter = gridApiRef?.current.getFilterModel();
        delete filter["status"];
        gridApiRef?.current?.setFilterModel({ ...filter, ...hardcodedFilter });
        gridApiRef?.current?.paginationGoToPage(current_page);
      }
    } else {
      if (gridApiRef.current) {
        const filter = gridApiRef.current.getFilterModel();
        delete filter["status"];
        gridApiRef.current.setFilterModel(filter);
        gridApiRef.current.paginationGoToPage(current_page);
        //  gridRef.current.api.deselectAll();
      }
    }
  };

  useEffect(() => {
    // if(gridApiRef.current){
    //   gridRef.current.api.deselectAll();
    // }
    reApplyFilter();
  }, [status]);

  const deselectAll = () => {
    if (gridApiRef.current) {
      gridRef.current.api.deselectAll();
    }
  };

  useEffect(() => {
    localStorage.setItem("originated_pagination", JSON.stringify(pagination));
    if (gridApiRef.current) {
      gridRef.current.api.deselectAll();
    }
  }, [pagination]);

  const onGridReady = (params: any) => {
    gridApiRef.current = params.api;
    setGridApi(params.api);
    setGridColumnApi(params.columnApi);
  };
  const onButtonClick = async () => {
    const selectedNodes = gridRef.current.api.getSelectedNodes();
    const selectedData = selectedNodes.map((node: any) => node.data);
    let loan_ids: any = [];
    selectedData.forEach((element: any) => {
      loan_ids.push(Number(element["loan_id"]));
    });
    let data = {
      loan_ids: loan_ids,
      status: option,
    };
    if (loan_ids.length == 0) {
      message.warning("Please select a row  to proceed");
    } else if (option.length == 0) {
      message.warning("Please select an option  to proceed");
    } else {
      setVisible(true);
      const loans = await updateBulk(data);
      window.location.reload();
    }
  };
  const assignUser = async () => {
    const selectedNodes = gridRef.current.api.getSelectedNodes();
    const selectedData = selectedNodes.map((node: any) => node.data);
    let loan_ids: any = [];
    selectedData.forEach((element: any) => {
      loan_ids.push(Number(element["loan_id"]));
    });
    let data = {
      loan_ids: loan_ids,
      user_id: Number(optionuser),
    };
    if (loan_ids.length == 0) {
      message.warning("Please select a row  to proceed");
    } else if (optionuser.length == 0) {
      message.warning("Please select an option  to proceed");
    } else {
      setVisible(true);
      const loans = await updateBulkUser(data);
      window.location.reload();
    }
  };

  const filterOptions = [
    "empty",
    {
      displayKey: "blanks",
      displayName: "Blanks",
      test: function (filterValue: any, cellValue: any) {
        return cellValue == "";
      },
      hideFilterInput: true,
      minwidth: 500,
    },
    {
      displayKey: "notblanks",
      displayName: "Not Blanks",
      test: function (filterValue: any, cellValue: any) {
        return cellValue !== "";
      },
      hideFilterInput: true,
    },
    "equals",
    "notEqual",
    "lessThan",
    "lessThanOrEqual",
    "greaterThan",
    "greaterThanOrEqual",
    "inRange",
  ];

  const filterOptionsAssigned: any = DynamicList(
    users.map((element: any) => {
      return element.user_name;
    })
  );

  const filterOptionsText: any = [
    "empty",
    {
      displayKey: "blanks",
      displayName: "Blanks",
      test: function (filterValue: any, cellValue: any) {
        return cellValue == "";
      },
      hideFilterInput: true,
    },
    {
      displayKey: "notblanks",
      displayName: "Not Blanks",
      test: function (filterValue: any, cellValue: any) {
        return cellValue !== "";
      },
      hideFilterInput: true,
    },
    "equals",
    "notEqual",
    "contains",
    "lessThan",
    "lessThanOrEqual",
    "greaterThan",
    "greaterThanOrEqual",
    "inRange",
  ];
  const dateFilterParams = {
    filterOptions: filterOptions,

    // provide comparator function
    comparator: (filterLocalDateAtMidnight: any, cellValue: any) => {
      const dateAsString = cellValue;

      if (!dateAsString) {
        return;
      }

      // In the example application, dates are stored as dd/mm/yyyy
      // We create a Date object for comparison against the filter date
      const dateParts = dateAsString.split("/");
      const day = Number(dateParts[0]);
      const month = Number(dateParts[1]) - 1;
      const year = Number(dateParts[2]);
      const cellDate = new Date(year, month, day);

      // Now that both parameters are Date objects, we can compare
      if (cellDate < filterLocalDateAtMidnight) {
        return -1;
      } else if (cellDate > filterLocalDateAtMidnight) {
        return 1;
      }
      return 0;
    },
  };

  const filterOptionsPresent: any = [
    "empty",
    {
      displayKey: "present",
      displayName: "Present",
      test: function (filterValue: any, cellValue: any) {
        return cellValue == "true";
      },
      hideFilterInput: true,
    },
    {
      displayKey: "notpresent",
      displayName: "Not Present",
      test: function (filterValue: any, cellValue: any) {
        return cellValue == "false";
      },
      hideFilterInput: true,
    },
  ];

  function resetAllFilters() {
    setStatus("Please select a status");
    gridApiRef.current.setFilterModel(null);
    message.info("Filters Reset Sucessfully");
  }

  function parseDate(params: any) {
    if (params.data.date_of_enquiry) {
      let date = params.data.date_of_enquiry.split("T")[0].split("-");
      return `${date[2]}/${date[1]}/${date[0]}`;
    } else {
      return "";
    }
  }

  const columnDefs = [
    // {
    //   field: "loan_id",
    //   minWidth: 160,
    //   headerName: "Request Id",
    //   filterParams: {
    //     filterOptions: filterOptionsText,
    //   },
    //   filter: true,
    //   cellStyle: { fontSize: "12px" },
    // },

    {
      field: "borrower_id",
      minWidth: 120,
      headerName: "Borrower Id",
      filter: true,
      cellStyle: { fontSize: "12px" },
      pinned: "left",
      headerCheckboxSelection: true,
      checkboxSelection: true,
      headerCheckboxSelectionFilteredOnly: true,
    },
    {
      field: "ops_id",
      minWidth: 150,
      headerName: "Request Id",
      filter: true,
      cellStyle: { fontSize: "12px" },
    },
    {
      field: "full_name",
      headerName: "Full Name",
      filter: true,
      filterParams: {
        filterOptions: filterOptionsText,
      },
      cellStyle: { fontSize: "12px" },
      minWidth: 200,
    },
    {
      field: "mobile_number",
      headerName: "Mobile",
      filter: true,
      filterParams: {
        filterOptions: filterOptionsText,
      },
      cellStyle: { fontSize: "12px" },
      minWidth: 140,
    },
    {
      headerName: "Status",
      field: "status",
      cellStyle: { fontSize: "12px" },
      minWidth: 180,
    },
    {
      field: "request_status_comment",
      headerName: "Status Comment",
      filter: true,
      minWidth: 160,
      suppressSizeToFit: true,
      cellStyle: { fontSize: "12px" },
    },
    {
      headerName: "Amount",
      field: "amount",
      sortable: true,
      filter: "agNumberColumnFilter",
      filterParams: {
        filterOptions: filterOptionsText,
      },
      cellStyle: { fontSize: "12px" },
      minWidth: 140,
    },
    {
      headerName: "Employee Id",
      field: "employee_id",
      filter: true,
      cellStyle: { fontSize: "12px" },
      minWidth: 300,
    },
    {
      headerName: "Date of Enquiry",
      field: "date_of_enquiry",
      filterParams: dateFilterParams,
      filter: "agDateColumnFilter",
      valueGetter: parseDate,
      sortable: true,
      cellStyle: { fontSize: "12px" },
      minWidth: 190,
    },
    {
      headerName: "Client Name",
      field: "client_name",
      filter: true,
      cellStyle: { fontSize: "12px" },
      minWidth: 180,
    },
    {
      headerName: "First Salary Date",
      field: "salary_1",
      filterParams: dateFilterParams,
      filter: "agDateColumnFilter",
      valueGetter: parseSalarydate1,
      sortable: true,
      cellStyle: { fontSize: "12px" },
      minWidth: 190,
    },
    {
      headerName: "Second Salary Date",
      field: "salary_2",
      filterParams: dateFilterParams,
      filter: "agDateColumnFilter",
      valueGetter: parseSalarydate2,
      sortable: true,
      cellStyle: { fontSize: "12px" },
      minWidth: 190,
    },
    {
      headerName: "Third Salary Date",
      field: "salary_3",
      filterParams: dateFilterParams,
      filter: "agDateColumnFilter",
      valueGetter: parseSalarydate3,
      sortable: true,
      cellStyle: { fontSize: "12px" },
      minWidth: 190,
    },
    {
      headerName: "Days From Latest Salary",
      field: "latest_salary",
      sortable: true,
      filter: "agNumberColumnFilter",
      filterParams: {
        filterOptions: filterOptionsText,
      },
      valueGetter: parseLatestSalaryDays,
      cellStyle: { fontSize: "12px" },
      minWidth: 240,
    },

    {
      field: "user_assigned_at",
      headerName: "Assigned on ",
      valueGetter: parseAssignedToDate,
      pinned: "right",
      sortable: true,
      filterParams: dateFilterParams,
      filter: "agDateColumnFilter",
      cellStyle: { fontSize: "12px" },
      minWidth: 140,
    },
    {
      field: "user_name",
      headerName: "Assigned to ",
      valueGetter: AssignedTo,
      pinned: "right",
      filterParams: {
        filterOptions: filterOptionsAssigned,
      },
      filter: true,
      cellStyle: { fontSize: "12px" },
      minWidth: 140,
    },

    {
      field: "experience",
      headerName: "Experience (Months)",
      filter: "agNumberColumnFilter",
      valueGetter: Experience,
      filterParams: {
        filterOptions: filterOptionsText,
      },
      sortable: true,
      cellStyle: { fontSize: "12px" },
      minWidth: 190,
    },
    {
      field: "employee_present",
      headerName: "Employee Present",
      filter: true,
      filterParams: {
        filterOptions: filterOptionsPresent,
      },
      cellRenderer: "employee",
      cellStyle: { fontSize: "12px" },
      minWidth: 180,
    },
    {
      field: "enach_possible",
      headerName: "Enach Possible",
      filter: true,
      filterParams: {
        filterOptions: filterOptionsPresent,
      },
      cellRenderer: "enach",
      cellStyle: { fontSize: "12px" },
      minWidth: 180,
    },
    {
      field: "active_requests",
      headerName: "Active Requests",
      cellRenderer: "active_request",
      filter: true,
      filterParams: {
        filterOptions: filterOptionsPresent,
      },
      cellStyle: { fontSize: "12px" },
      minWidth: 180,
    },
    {
      field: "duplicate_requests",
      headerName: "Duplicate Requests",
      cellRenderer: "duplicate_request",
      filter: true,
      filterParams: {
        filterOptions: filterOptionsPresent,
      },
      cellStyle: { fontSize: "12px" },
      minWidth: 180,
    },
    {
      field: "message_sent",
      minWidth: 180,
      headerName: "Notified",
      filter: true,
      filterParams: {
        filterOptions: filterOptionsPresent,
      },
      cellRenderer: "message",
      cellStyle: { fontSize: "12px" },
    },
  ];
  function returnName(name: string) {
    let result = "";
    name.split("_").forEach((el) => {
      result = result + " " + el.toUpperCase();
    });
    return result;
  }

  const onRowSelected = (event: any) => {
    let selectedRowsTemp = gridApiRef.current.getSelectedRows().length;
    setSelectedRows(selectedRowsTemp);
  };

  const onSelectionChanged = async (event: any) => {
    let selectedRowsTemp = gridApiRef.current.getSelectedRows().length;
    let displayedRows = gridApiRef.current.getDisplayedRowCount();
    if (selectedRowsTemp == displayedRows) {
      // header checkbox selected
      let currentPage = gridApiRef.current.paginationGetCurrentPage();
      let start = currentPage * pagination;
      let end = start + pagination;
      let totalPage = gridApiRef.current.paginationGetTotalPages();
      if (totalPage !== 1) gridApiRef.current.deselectAll();
      // prev_selectedRows == displayedRows ? selectedRows ==  0 :   prev_selectedRows == 0
      if (totalPage !== 1) {
        // all selected
        for (let i = start; i < end; i++) {
          var rowNode = gridApiRef.current.getDisplayedRowAtIndex(i);
          if (rowNode) {
            rowNode.setSelected(true);
          }
        }
      }
    }
  };
  const onPaginationChanged = (event: any) => {
    if (event.newPage) {
      if (gridApiRef.current) {
        localStorage.setItem(
          "originated_page",
          gridApiRef.current.paginationGetCurrentPage()
        );
        setCurrentpage(gridApiRef.current.paginationGetCurrentPage());
        gridApiRef.current.deselectAll();
      }
    }
  };

  function handleChange(value: any) {
    setOption(value);
  }
  function handleChangeUser(value: any) {
    setOptionUser(value);
  }
  function onChangePage(page: number, pageSize?: number) {
    if (pageSize) {
      setSize(pageSize);
    }
    setCurrentPage(page);
  }

  const onDateChange = (dates: any, dateStrings: any) => {
    setStartDate(dates[0].format("YYYY-MM-DD"));
    setEndDate(dates[1].format("YYYY-MM-DD"));
  };

  const showTotal: PaginationProps["showTotal"] = (total) => `Total  ${total}`;

  return (
    <>
      {visible === true ? (
        <div style={{ backgroundColor: "#f0f0f0" }}>
          <p
            style={{
              textAlign: "center",
              marginLeft: "-5%",
              fontSize: "17px",
              marginBottom: "-1%",
            }}
          >
            {" "}
            <span style={{ color: "#002766" }}>{selectedRows}</span> rows
            selected
          </p>
          <Row
            style={{
              background: "",
              marginLeft: "1%",
              marginRight: "5%",
              marginTop: "1%",
              boxShadow: "10px 10px grey",
            }}
          >
            <Col span={10} style={{ padding: "1%" }}>
              <div>
                <RetweetOutlined
                  style={{ fontSize: "20px", marginTop: "2%" }}
                  onClick={deselectAll}
                />{" "}
                <span
                  onClick={deselectAll}
                  style={{ cursor: "pointer", marginRight: "2%" }}
                >
                  Deselect Rows
                </span>
                <DoubleLeftOutlined
                  style={{ fontSize: "20px", marginTop: "2%" }}
                  onClick={resetAllFilters}
                />{" "}
                <span onClick={resetAllFilters} style={{ cursor: "pointer" }}>
                  Reset Filters
                </span>
              </div>

              {/* <p style={{fontWeight:380, fontSize:"15px"}}>Toolbar</p> */}
            </Col>
            <Col span={6} style={{ padding: "1%" }}>
              <Select
                defaultValue="Please select a status"
                value={status}
                style={{ width: "100%" }}
                onChange={(value: any) => {
                  setStatus(value);
                }}
              >
                {OriginatedStatusDisplay.map((element: any) => {
                  return <Option value={element}>{element}</Option>;
                })}
              </Select>
            </Col>
            <Col span={6} style={{ padding: "1%" }}>
              <RangePicker
                format="DD-MM-YYYY"
                onChange={onDateChange}
                defaultValue={[moment(start_date), moment(end_date)]}
              />
            </Col>
            <Col span={2} style={{ background: "#f0f0f0", padding: "1%" }}>
              <Tooltip title="Click here for actions">
                <AlignCenterOutlined
                  style={{ fontSize: "20px", cursor: "pointer" }}
                  onClick={showDrawer}
                />
              </Tooltip>
            </Col>
          </Row>
          <Drawer
            title="Actions"
            placement="right"
            onClose={onClose}
            visible={isDrawer}
            width={450}
          >
            <Card style={{ padding: "", marginTop: "4%" }}>
              <Col>
                <h6 style={{ marginBottom: "5%" }}>Update Status</h6>
              </Col>
              <Col span={24}>
                <Select
                  defaultValue="Please select an option"
                  style={{ width: "100%", marginBottom: "2%" }}
                  onChange={handleChange}
                >
                  {states.map((element: any) => {
                    return <Option value={element}>{element}</Option>;
                  })}
                </Select>
                {/* <Input
                  type="text"
                  placeholder="comment"
                  value={status_comment}
                  onChange={(e) => {
                   SetComment(e.target.value);
                  }}
                ></Input> */}
              </Col>
              <Col style={{ marginTop: "5%" }}>
                <Button
                  type="primary"
                  style={{ backgroundColor: "#1B875A", border: 0 }}
                  block
                  onClick={() => {
                    onButtonClick();
                  }}
                >
                  Bulk Update
                </Button>
              </Col>
            </Card>

            <Card style={{ padding: "", marginTop: "4%" }}>
              <Col>
                <h6 style={{ marginBottom: "5%" }}>Page Size</h6>
              </Col>
              <Col span={24}>
                <Input
                  type="number"
                  value={pagination}
                  onChange={(e) => {
                    setPagination(Number(e.target.value));
                    onChangePage(1, Number(e.target.value));
                  }}
                ></Input>
              </Col>
            </Card>

            <Card>
              <Col style={{ marginTop: "5%" }}>
                <Button
                  type="primary" style={{ backgroundColor: "#1B875A", border: 0 }}
                  block
                  onClick={() => {
                    gridApiRef.current.exportDataAsCsv({ onlySelected: true });
                  }}
                >
                  Export Selected Rows
                </Button>
              </Col>
            </Card>

            {role == "doc_check" ||
              role == "appraisal" ||
              role == "admin" ||
              role == "super_admin" ||
              role == "operations" ? (
              <>
                <Card style={{ padding: "", marginTop: "4%" }}>
                  <Col>
                    <h6 style={{ marginBottom: "5%" }}>Assign User</h6>
                  </Col>
                  <Col span={24}>
                    <Select
                      defaultValue="Please select an option"
                      showSearch
                      style={{ width: "100%" }}
                      onChange={handleChangeUser}
                      filterOption={(input, option: any) =>
                        option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                      }
                    >
                      {users.map((element: any) => {
                        return (
                          <Option value={element["id"]}>
                            {element["user_name"]}
                          </Option>
                        );
                      })}
                    </Select>
                  </Col>
                  <Col style={{ marginTop: "5%" }}>
                    <Button
                      type="primary" style={{ backgroundColor: "#1B875A", border: 0 }}
                      block
                      onClick={() => {
                        assignUser();
                      }}
                    >
                      Assign
                    </Button>
                  </Col>
                </Card>
              </>
            ) : (
              ""
            )}

            {(role === "admin" || role === "super_admin") ? (
              <>
                <Card style={{ padding: "", marginTop: "4%" }}>
                  <Col>
                    <h6 style={{ marginBottom: "5%" }}>
                      Upload Active requests sheet
                    </h6>
                  </Col>
                  <Col span={24}>
                    <Upload {...propsNewDocument}>
                      <Button
                        type="primary"
                        style={{ width: "160%", backgroundColor: "#1B875A", border: 0 }}
                        icon={<UploadOutlined />}
                      >
                        Upload a new Document
                      </Button>
                    </Upload>
                  </Col>
                </Card>
              </>
            ) : (
              ""
            )}
          </Drawer>
          <Row>
            <Col span={24}>
              <div
                className="ag-theme-alpine"
                style={{
                  height: height * 0.76,
                  width: width * 0.87,
                  paddingLeft: "1%",
                  paddingRight: "",
                  paddingTop: "1%",
                  marginTop: "",
                  fontSize: "12px",
                }}
              >
                <AgGridReact
                  ref={gridRef}
                  rowData={rowData}
                  rowSelection="multiple"
                  columnDefs={columnDefs}
                  enableCellTextSelection={true}
                  // pagination={true}
                  // paginationPageSize={pagination}
                  rowMultiSelectWithClick={true}
                  groupDisplayType="singleColumn"
                  onGridReady={onGridReady}
                  onRowSelected={onRowSelected}
                  onPaginationChanged={onPaginationChanged}
                  onSelectionChanged={onSelectionChanged}
                  suppressRowClickSelection={true}
                  defaultColDef={{
                    flex: 1,
                    minWidth: 110,
                    resizable: true,
                  }}
                  frameworkComponents={{
                    btnCellRenderer: BtnCellRenderer,
                    document: Document,
                    employee: Employee,
                    active_request: Activerequest,
                    duplicate_request: Duplicaterequest,
                    enach: Enach,
                    message: Message,
                  }}
                ></AgGridReact>
              </div>
              {rowData.length && (
                <Pagination
                  style={{
                    justifyContent: "right",
                    textAlign: "right",
                    marginTop: "15px",
                    marginRight: "25px",
                  }}
                  current={currentpage}
                  onChange={onChangePage}
                  total={total}
                  defaultPageSize={20}
                  showSizeChanger={false}
                  showTotal={showTotal}
                  pageSize={size}
                />
              )}
            </Col>
          </Row>
        </div>
      ) : (
        <Spin size="large" style={{ marginLeft: "40%", marginTop: "20%" }} />
      )}
    </>
  );
}
