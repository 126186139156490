import {
  Row,
  Col,
  Card,
  Pagination,
  Tooltip,
  Button,
  Select,
  Drawer,
  message,
  DatePicker,
  Input,
  PaginationProps,
  Spin
} from "antd";
import { useEffect, useState, useLayoutEffect, useRef } from "react";
import "ag-grid-community/dist/styles/ag-grid.css";
import "ag-grid-community/dist/styles/ag-theme-alpine.css";
import { Document } from "./icons/documents";
import { Employee } from "./icons/employee";
import { Activerequest } from "./icons/activerequests";
import { Enach } from "./icons/enach";
import moment from "moment";
import {
  AlignCenterOutlined,
  DoubleLeftOutlined,
  RetweetOutlined,
} from "@ant-design/icons";
import { DocumentsVerified } from "./icons/documentsVerified";
import { getUsers, updateBulk, updateBulkUser } from "../utitlities/request";
import {
  OperationsStatus,
  OperationsStatusDataFetch,
  client_name,
} from "../utitlities/status";
import { useDispatch, useSelector } from "react-redux";
import {
  setOperationsApiParams,
  getOperationsList,
} from "../state/action-creators";
import { DynamicList, DynamicMandateSignature } from "../utitlities/filteroptions";
import AgGridComponent from "./common/AgGridComponent";
import CustomDropdownFilter from "./common/customSelectFilter";
import { ShowDetailsIcon } from "./showDetailsIcon";
import { operationsApiParamsInitState } from "../state/reducers/loanReducer";

const { RangePicker } = DatePicker;
let setTimeoutId: any;

export default function OpsReview() {
  const [gridApi, setGridApi] = useState<any>(null);
  const [selectedRows, setSelectedRows] = useState([]);
  const [height, setHeight] = useState(window.innerHeight);
  const [states, setStates] = useState<any>(null);
  const [bulkUpdateStatus, setBulkUpdateStatus] = useState("");
  const [optionUser, setOptionUser] = useState("");
  const [isDrawer, setIsDrawer] = useState(false);
  const [gridLoader, setgridLoader] = useState(false);
  const [pageSize, setPageSize] = useState(
    operationsApiParamsInitState?.pageSize || 50
  );
  const { Option } = Select;
  const [users, setUsers] = useState([]);
  const [role, setRole] = useState("");

  const defaultColDef = {
    flex: 1,
    resizable: true,
  };
  const dispatch = useDispatch();
  const gridApiRef = useRef<any>(null);
  const operationsApiParams = useSelector(
    (state: any) => state?.loan?.operationsApiParams
  );
  const filterData = operationsApiParams?.filterData;
  const filterModel = operationsApiParams?.filterModel;
  const sortModel = operationsApiParams?.sortModel;

  const [filters, setFilters] = useState({
    search: filterData?.search || '',
    status: filterData?.status || '',
    start_date: filterData?.start_date || null,
    end_date: filterData?.end_date || null,
  });

  useEffect(() => {
    setFilters({
      search: filterData?.search,
      status: filterData?.status,
      start_date: filterData?.start_date,
      end_date: filterData?.end_date,
    });
  }, [filterData]);

  const filterOptionsClientName: any = DynamicMandateSignature(client_name);

  const operationsList = useSelector(
    (state: any) => state?.loan?.operationsApiResponse?.response
  );
  const operationsApiLoader = useSelector(
    (state: any) => state?.loan?.operationsApiResponse?.loading
  );

  useEffect(() => {
    async function fetchUserApi() {
      const users = await getUsers();
      setUsers(users.filter((user: any) => {
        return user.role !== "deactivated"
      }));
    }
    fetchUserApi();
    const user = JSON.parse(localStorage?.getItem("user") || "");
    setRole(user?.role);
    setStates(OperationsStatus.filter((status: string) => status !== 'Appraisal'));
    const _operationsApiParams = localStorage?.getItem("OPERATIONS_API_PARAMS")
      ? // @ts-ignore
      JSON.parse(localStorage?.getItem("OPERATIONS_API_PARAMS"))
      : "";
    if (_operationsApiParams) {
      dispatch(
        setOperationsApiParams({
          ..._operationsApiParams,
          page: 1,
        })
      );
      setPageSize(_operationsApiParams?.pageSize);
      gridApiRef?.current?.setFilterModel(_operationsApiParams?.filterModel);
      gridApiRef?.current?.setSortModel(_operationsApiParams?.sortModel);
    }
  }, []);

  useEffect(() => {
    if (gridApi) {
      if (operationsApiLoader) {
        gridApi.showLoadingOverlay();
      } else {
        gridApi.hideOverlay();
      }
    }
  }, [operationsApiLoader, gridApi]);

  useEffect(() => {
    if (setTimeoutId) clearTimeout(setTimeoutId);
    setTimeoutId = setTimeout(() => {
      localStorage.setItem(
        "OPERATIONS_API_PARAMS",
        JSON.stringify(operationsApiParams)
      );
      let request = {
        page: operationsApiParams?.page,
        limit: operationsApiParams?.pageSize,
        ...filterData,
        userid: "all",
      };
      for (const [key, value] of Object.entries(filterModel || {})) {
        // @ts-ignore
        const { type, filterType, filter, dateFrom } = value;
        let _value: any;
        if (filterType === "date") {
          _value = dateFrom;
        } else if ((key === "user_name" || key === "client_name") && filterType === "text") {
          _value = type;
        } else {
          _value = filter;
        }
        request[key] = _value;
      }
      for (const _sort of sortModel || []) {
        // @ts-ignore
        request["sort_key"] = _sort?.colId;
        // @ts-ignore
        request["sort_order"] = _sort?.sort;
      }
      if (!request?.status) {
        request["status"] = [...OperationsStatusDataFetch];
      }
      dispatch(getOperationsList(request));
    }, 500);
  }, [operationsApiParams, dispatch, filterData, filterModel, sortModel]);

  // const onFilterInputChangeHandler = (e: any) => {
  //   const { name, value } = e?.target;
  //   gridApiRef?.current?.setFilterModel(null);
  //   gridApiRef?.current?.setSortModel(null);
  //   gridApiRef?.current?.deselectAll();
  //   setTimeout(() => {
  //     dispatch(
  //       setOperationsApiParams({
  //         ...operationsApiParams,
  //         filterData: {
  //           ...operationsApiParams?.filterData,
  //           [name]: value,
  //         },
  //         filterModel: null,
  //         sortModel: null,
  //       })
  //     );
  //   }, 0);
  //   setSelectedRows([]);
  // };

  const onFilterDateChange = (dates: any) => {
    setFilters((prevFilters) => ({
      ...prevFilters,
      start_date: dates ? moment(dates[0]).format("YYYY-MM-DD") : null,
      end_date: dates ? moment(dates[1]).format("YYYY-MM-DD") : null,
    }));
  };


  const onFilterInputChangeHandlerSearch = (e: any) => {
    let { name, value } = e.target;
    value = name == "status" && value == "All status" ? "" : value;
    setFilters((prevFilters) => ({
      ...prevFilters,
      [name]: value,
    }));
  };

  const applyFilter = () => {
    try {
      setgridLoader(true);
      gridApiRef?.current?.setFilterModel(null);
      gridApiRef?.current?.setSortModel(null);
      gridApiRef?.current?.deselectAll();
      setTimeout(() => {
        dispatch(
          setOperationsApiParams({
            ...operationsApiParams,
            filterData: {
              ...operationsApiParams?.filterData,
              ...filters,
            },
            filterModel: null,
            sortModel: null,
          })
        );
      }, 0);
      setSelectedRows([]);
    } catch (error) {
      console.log(error);
    } finally {
      setgridLoader(false);
    }
  };

  const handleFilterChanged = () => {
    // Perform actions when filters change
    const filterModel = gridApiRef?.current?.getFilterModel();
    gridApiRef?.current?.deselectAll();
    dispatch(
      setOperationsApiParams({
        ...operationsApiParams,
        filterModel,
      })
    );
    setSelectedRows([]);
  };

  const handleSortChanged = (event: any) => {
    const sortModel = gridApiRef?.current?.getSortModel();
    gridApiRef?.current?.deselectAll();
    dispatch(
      setOperationsApiParams({
        ...operationsApiParams,
        sortModel,
      })
    );
    setSelectedRows([]);
  };

  const handleRowDataChanged = (event: any) => {
    // Row data has been changed
    gridApiRef?.current?.setFilterModel(filterModel);
    gridApiRef?.current?.setSortModel(sortModel);
    // Perform any necessary actions or updates here
  };

  const onRowSelectedHandle = (event: any) => {
    const isSelected = !!event?.node?.selected;
    if (isSelected && event?.data) {
      // @ts-ignore
      setSelectedRows((current: Array) => [
        ...current,
        {
          borrower_id: event?.data?.borrower_id,
          loan_id: event?.data?.loan_id,
          mobile_number: event?.data?.mobile_number,
        },
      ]);
    } else {
      // @ts-ignore
      setSelectedRows((current: Array) => [
        ...current?.filter((obj: any) => {
          return obj?.borrower_id !== event?.data?.borrower_id;
        }),
      ]);
    }
  };

  const showDrawer = () => {
    setBulkUpdateStatus("");
    setOptionUser("");
    setIsDrawer(true);
  };

  const closeDrawer = () => {
    setIsDrawer(false);
  };

  function handleChangeUser(value: any) {
    setOptionUser(value);
  }

  function updateDimension() {
    setHeight(window.innerHeight);
  }

  const onGridReady = async (params: any) => {
    gridApiRef.current = params.api;
    setGridApi(params.api);
  };

  useLayoutEffect(() => {
    window.addEventListener("resize", updateDimension);
    updateDimension();
    return () => window.removeEventListener("resize", updateDimension);
  }, []);

  const desSelectAllRow = () => {
    if (gridApiRef?.current) {
      gridApiRef?.current?.deselectAll();
    }
    setSelectedRows([]);
  };

  const bulkUpdateClickHandler = async () => {
    if (!selectedRows?.length) {
      message.warning("Please select a row to proceed");
      return;
    }
    if (!bulkUpdateStatus) {
      message.warning("Please select status");
      return;
    }
    let loan_ids: any = [];
    let phone_numbers: any = [];
    selectedRows?.forEach((element: any) => {
      loan_ids.push(Number(element["loan_id"]));
      phone_numbers.push(element["mobile_number"]);
    });
    let data = {
      loan_ids: loan_ids,
      phone_numbers,
      status: bulkUpdateStatus,
    };
    await updateBulk(data);
    // gridApiRef?.current?.setFilterModel(null);
    // gridApiRef?.current?.setSortModel(null);
    gridApiRef?.current?.deselectAll();
    setTimeout(() => {
      dispatch(
        setOperationsApiParams({
          ...operationsApiParams,
          // filterModel: null,
          // sortModel: null,
          page: 1,
        })
      );
    }, 0);
    setSelectedRows([]);
    closeDrawer();
    message.success("loan requests details updated");
  };

  function parseDate(params: any) {
    if (params.data.date_of_enquiry) {
      let date = params.data.date_of_enquiry.split("T")[0].split("-");
      return `${date[2]}/${date[1]}/${date[0]}`;
    } else {
      return "";
    }
  }

  const filterOptionsAssigned: string[] = users
    .map((element: any) => element.user_name)
    .sort((a, b) => a.localeCompare(b));

  const dateFilterParams = {
    comparator: (filterLocalDateAtMidnight: any, cellValue: any) => {
      var dateAsString = cellValue;
      if (dateAsString === null) return -1;
      var dateParts = dateAsString.split("/");
      var cellDate = new Date(
        Number(dateParts[2]),
        Number(dateParts[1]) - 1,
        Number(dateParts[0])
      );
      if (filterLocalDateAtMidnight.getTime() === cellDate.getTime()) {
        return 0;
      }
      if (cellDate < filterLocalDateAtMidnight) {
        return -1;
      }
      if (cellDate > filterLocalDateAtMidnight) {
        return 1;
      }
      return 0;
    },
    minValidYear: 2000,
    maxValidYear: 2021,
    inRangeFloatingFilterDateFormat: "Do MMM YYYY",
    buttons: ["apply", "reset", "cancel"],
    closeOnApply: true,
    filterOptions: ["equals"],
    maxNumConditions: 1,
    trimInput: true,
  };

  const resetAllFilters = () => {
    gridApiRef.current.setFilterModel(null);
    gridApiRef.current.setSortModel(null);
    gridApiRef?.current?.deselectAll();
    setTimeout(() => {
      dispatch(
        setOperationsApiParams({
          ...operationsApiParamsInitState,
          pageSize: operationsApiParams?.pageSize,
          filterData: {
            ...operationsApiParamsInitState?.filterData,
            start_date: operationsApiParams?.filterData?.start_date,
            end_date: operationsApiParams?.filterData?.end_date,
          },
        })
      );
    }, 0);
    setSelectedRows([]);
    message.info("Filters Reset Successfully");
  };

  const columnDefs = [
    {
      headerName: "Borrower Id",
      field: "borrower_id",
      headerCheckboxSelection: true,
      headerCheckboxSelectionFilteredOnly: true,
      checkboxSelection: true,
      pinned: "left",
      width: 180,
      minWidth: 180,
      maxWidth: 180,
      suppressSizeToFit: true,
      cellStyle: { fontSize: "12px" },
      sortable: true,
      filter: "agTextColumnFilter",

      filterParams: {
        buttons: ["apply", "reset", "cancel"],
        closeOnApply: true,
        filterOptions: ["contains"],
        trimInput: true,
        maxNumConditions: 1,
      },
    },
    {
      field: "percent_complete",
      minWidth: 80,
      headerName: "Docs %",
      cellStyle: { fontSize: "12px" },
      sortable: true,
    },
    {
      field: "ops_id",
      minWidth: 140,
      headerName: "Request Id",
      cellStyle: { fontSize: "12px" },
      sortable: true,
      filter: "agTextColumnFilter",
      filterParams: {
        buttons: ["apply", "reset", "cancel"],
        closeOnApply: true,
        filterOptions: ["contains"],
        trimInput: true,
        maxNumConditions: 1,
      },
    },
    {
      field: "full_name",
      headerName: "Full Name",
      minWidth: 200,
      suppressSizeToFit: true,
      cellStyle: { fontSize: "12px" },
      filter: "agTextColumnFilter",

      filterParams: {
        buttons: ["apply", "reset", "cancel"],
        closeOnApply: true,
        filterOptions: ["contains"],

        trimInput: true,
        maxNumConditions: 1,
      },
    },
    {
      field: "pan",
      headerName: "PAN",
      filter: false,
      minWidth: 160,
      suppressSizeToFit: true,
      cellStyle: { fontSize: "12px" },
    },
    {
      field: "mobile_number",
      headerName: "Mobile",
      cellStyle: { fontSize: "12px" },
      suppressSizeToFit: true,
      minWidth: 120,
      filter: "agNumberColumnFilter",
      maxNumConditions: 1,
      filterParams: {
        buttons: ["apply", "reset", "cancel"],
        closeOnApply: true,
        filterOptions: ["contains"],
        maxNumConditions: 1,
        trimInput: true,
      },
    },
    {
      field: "whatsapp_no",
      headerName: "Whatsapp",
      cellStyle: { fontSize: "12px" },
      suppressSizeToFit: true,
      minWidth: 120,
    },
    {
      field: "status",
      headerName: "Status",
      minWidth: 150,
      suppressSizeToFit: true,
      cellStyle: { fontSize: "12px" },
    },
    {
      field: "request_status_comment",
      headerName: "Status Comment",
      minWidth: 150,
      suppressSizeToFit: true,
      cellStyle: { fontSize: "12px" },
    },
    {
      headerName: "Amount",
      field: "amount",
      minWidth: 120,
      suppressSizeToFit: true,
      cellStyle: { fontSize: "12px" },
      filter: "agNumberColumnFilter",
      filterParams: {
        buttons: ["apply", "reset", "cancel"],
        closeOnApply: true,
        filterOptions: ["contains"],
        maxNumConditions: 1,
        trimInput: true,
      },
    },
    {
      field: "employee_id",
      headerName: "Employee Id",
      cellStyle: { fontSize: "12px" },
      minWidth: 260,
      suppressSizeToFit: true,
    },
    {
      headerName: "Client Name",
      field: "client_name",
      cellStyle: { fontSize: "12px" },
      minWidth: 160,
      filter: "agTextColumnFilter",
      filterParams: {
        filterOptions: filterOptionsClientName,
        buttons: ["apply", "reset", "cancel"],
        closeOnApply: true,
        trimInput: true,
        maxNumConditions: 1,
      },
    },
    {
      field: "user_name",
      headerName: "Assigned to",
      valueGetter: AssignedTo,
      pinned: "right",
      filterParams: {
        options: filterOptionsAssigned,
        type: "loan",
      },
      filterFramework: CustomDropdownFilter,
      cellStyle: { fontSize: "12px" },
      width: 160,
      minWidth: 160,
      maxWidth: 160,
    },
    {
      field: "loan_id",
      cellRenderer: "ShowDetailsIcon",
      width: 80,
      minWidth: 80,
      maxWidth: 80,
      headerName: "Details",
      cellStyle: { fontSize: "12px" },
      pinned: "right",
    },
    {
      headerName: "Date of enquiry",
      field: "date_of_enquiry",
      filterParams: dateFilterParams,
      filter: "agDateColumnFilter",
      sortable: true,
      cellStyle: { fontSize: "12px" },
      width: 160,
      minWidth: 160,
      maxWidth: 160,
      valueFormatter: (params: any) => {
        if (params.value) {
          return moment(params.value).format('DD/MM/YYYY');
        }
        return '';
      }
    },
  ];

  function handleBulkUpdateStatusChange(value: any) {
    setBulkUpdateStatus(value);
  }

  const onChangePage = (page: number, pageSize?: number) => {
    if (page && pageSize) {
      dispatch(
        setOperationsApiParams({
          ...operationsApiParams,
          page,
          pageSize,
        })
      );
    }
  };

  function AssignedTo(params: any) {
    if (params.data.user_name) {
      return params.data.user_name;
    } else {
      return "";
    }
  }

  const handleBulkUpdateAssignUser = async () => {
    if (!selectedRows?.length) {
      message.warning("Please select a row to proceed");
      return;
    }
    if (!optionUser) {
      message.warning("Please select user.");
      return;
    }
    let loan_ids: any = [];
    selectedRows?.forEach((element: any) => {
      loan_ids.push(Number(element["loan_id"]));
    });
    let data = {
      loan_ids: loan_ids,
      user_id: Number(optionUser),
    };
    await updateBulkUser(data);
    // gridApiRef?.current?.setFilterModel(null);
    // gridApiRef?.current?.setSortModel(null);
    gridApiRef?.current?.deselectAll();
    setTimeout(() => {
      dispatch(
        setOperationsApiParams({
          ...operationsApiParams,
          // filterModel: null,
          // sortModel: null,
          page: 1,
        })
      );
    }, 0);
    setSelectedRows([]);
    closeDrawer();
    message.success("loan requests details updated.");
  };

  const pageSizeBtnClickHandler = (e: any) => {
    gridApiRef?.current?.deselectAll();
    setTimeout(() => {
      dispatch(
        setOperationsApiParams({
          ...operationsApiParams,
          filterModel: null,
          sortModel: null,
          page: 1,
          pageSize: Number(pageSize),
        })
      );
    }, 0);
    setSelectedRows([]);
    closeDrawer();
    message.success("Page size updated.");
  };

  const showTotal: PaginationProps["showTotal"] = (total) =>
    `Total Rows: ${total}`;

  return (
    <>
      <div style={{ backgroundColor: "#f0f0f0" }}>
        <Row
          style={{
            padding: "15px 15px 5px 15px",
          }}
        >
          <Col span={16}>
            <Row>
              {/* <Col
                span={5}
                style={{
                  paddingRight: "15px",
                }}
              >
                <Input
                  placeholder="Whatsapp No"
                  name="whatsapp_no"
                  value={filterData?.whatsapp_no}
                  onChange={onFilterInputChangeHandler}
                />
              </Col>
              <Col
                span={4}
                style={{
                  paddingRight: "15px",
                }}
              >
                <Input
                  placeholder="Employee ID"
                  name="employee_id"
                  value={filterData?.employee_id}
                  onChange={onFilterInputChangeHandler}
                />
              </Col> */}
              <Col
                span={7}
                style={{
                  paddingRight: "15px",
                }}
              >
                <Select
                  value={filters?.status}
                  style={{ width: "100%" }}
                  placeholder="Select status"
                  onChange={(value: any) =>
                    onFilterInputChangeHandlerSearch({
                      target: {
                        name: "status",
                        value,
                      },
                    })
                  }
                >
                  {["", ...OperationsStatusDataFetch]?.map(
                    (element: any, index: number) => {
                      return (
                        <Option
                          value={element}
                          key={`${element
                            ?.split(" ")
                            .join("-")}-status-${index}`}
                        >
                          {element || "All status"}
                        </Option>
                      );
                    }
                  )}
                </Select>
              </Col>
              <Col span={8}
                style={{
                  paddingRight: "15px"
                }}>
                <RangePicker
                  format="DD-MM-YYYY"
                  onChange={onFilterDateChange}
                  value={[
                    moment(filters?.start_date),
                    moment(filters?.end_date),
                  ]}
                  defaultValue={[
                    moment(filterData?.start_date),
                    moment(filterData?.end_date),
                  ]}
                />
              </Col>
              <Col
                span={5}
                style={{
                  paddingRight: "15px",
                }}
              >
                <Input
                  placeholder="Search ..."
                  name="search"
                  value={filters.search}
                  onChange={onFilterInputChangeHandlerSearch}
                />
              </Col>
              <div className="loading-overlay">
                <Spin spinning={operationsApiLoader} tip="Loading...">
                  <Col
                    span={3}
                    style={{
                      paddingRight: "15px",
                    }}
                  >
                    <Button type="primary" style={{ backgroundColor: "#1B875A", border: 0 }} onClick={applyFilter}>
                      Apply Filter
                    </Button>
                  </Col>
                </Spin>
              </div>
            </Row>
          </Col>
          <Col
            span={8}
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "flex-end",
            }}
          >
            <Button
              type="text"
              icon={<RetweetOutlined />}
              onClick={desSelectAllRow}
              style={{
                display: "inline-flex",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              Deselect Rows
            </Button>
            <Button
              type="text"
              icon={<DoubleLeftOutlined />}
              onClick={resetAllFilters}
              style={{
                display: "inline-flex",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              Reset Filters
            </Button>
            <Tooltip title="Click here for actions">
              <AlignCenterOutlined
                style={{
                  fontSize: "20px",
                  cursor: "pointer",
                  padding: "0px 0px 0px 10px",
                }}
                onClick={showDrawer}
              />
            </Tooltip>
          </Col>
        </Row>
        <Drawer
          title="Actions"
          placement="right"
          onClose={closeDrawer}
          visible={isDrawer}
          width={450}
        >
          <Card style={{ padding: "", marginTop: "4%" }}>
            <Col>
              <h6 style={{ marginBottom: "5%" }}>Update Status</h6>
            </Col>
            <Col span={24}>
              <Select
                defaultValue="Please select an option"
                style={{ width: "100%", marginBottom: "2%" }}
                value={bulkUpdateStatus}
                onChange={handleBulkUpdateStatusChange}
              >
                {states?.map((element: any, index: number) => {
                  return (
                    <Option value={element} key={`${element}-${index}`}>
                      {element}
                    </Option>
                  );
                })}
              </Select>
            </Col>
            <Col style={{ marginTop: "5%" }}>
              <Button type="primary" style={{ backgroundColor: "#1B875A", border: 0 }} block onClick={bulkUpdateClickHandler}>
                Bulk Update
              </Button>
            </Col>
          </Card>
          <Card style={{ padding: "", marginTop: "4%" }}>
            <Col>
              <h6 style={{ marginBottom: "5%" }}>Page Size</h6>
            </Col>
            <Row>
              <Col span={18}>
                <Input
                  type="number"
                  name="pageSize"
                  value={pageSize}
                  onChange={(e: any) => {
                    setPageSize(e?.target?.value);
                  }}
                ></Input>
              </Col>
              <Col span={6}>
                <Button
                  type="primary"
                  block
                  onClick={pageSizeBtnClickHandler}
                  style={{
                    marginLeft: "10px",
                    backgroundColor: "#1B875A", border: 0
                  }}
                >
                  Apply
                </Button>
              </Col>
            </Row>
          </Card>
          {role === "doc_check" ||
            role === "appraisal" ||
            role === "admin" ||
            role === "super_admin" ||
            role === "operations" ? (
            <>
              <Card style={{ padding: "", marginTop: "4%" }}>
                <Col>
                  <h6 style={{ marginBottom: "5%" }}>Assign User</h6>
                </Col>
                <Col span={24}>
                  <Select
                    defaultValue="Please select an option"
                    showSearch
                    style={{ width: "100%" }}
                    onChange={handleChangeUser}
                    filterOption={(input, option: any) =>
                      option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                    }
                  >
                    {users.map((element: any, index: number) => {
                      return (
                        <Option
                          value={element["id"]}
                          key={`${element["id"]}-${index}`}
                        >
                          {element["user_name"]}
                        </Option>
                      );
                    })}
                  </Select>
                </Col>
                <Col style={{ marginTop: "5%" }}>
                  <Button
                    type="primary"
                    style={{ backgroundColor: "#1B875A", border: 0 }}
                    block
                    onClick={handleBulkUpdateAssignUser}
                  >
                    Assign
                  </Button>
                </Col>
              </Card>
              <Card>
                <Col style={{ marginTop: "5%" }}>
                  <Button
                    type="primary"
                    style={{ backgroundColor: "#1B875A", border: 0 }}
                    block
                    onClick={() => {
                      gridApiRef.current.exportDataAsCsv({
                        onlySelected: true,
                      });
                    }}
                  >
                    Export Selected Rows
                  </Button>
                </Col>
              </Card>
            </>
          ) : (
            ""
          )}
        </Drawer>
        <Row>
          <Col span={24}>
            <div
              className="ag-theme-alpine"
              style={{
                height: height * 0.76,
                padding: "5px 15px 15px 15px",
                fontSize: "12px",
              }}
            >
              <AgGridComponent
                rowData={operationsList?.result || []}
                onFilterChanged={handleFilterChanged}
                onSortChanged={handleSortChanged}
                onGridReady={onGridReady}
                onRowDataChanged={handleRowDataChanged}
                onRowSelected={onRowSelectedHandle}
                columnDefs={columnDefs}
                defaultColDef={defaultColDef}
                totalPages={Math.ceil(
                  Number(operationsList?.count) / operationsApiParams?.pageSize
                )}
                frameworkComponents={{
                  ShowDetailsIcon: ShowDetailsIcon,
                  document: Document,
                  employee: Employee,
                  active_request: Activerequest,
                  enach: Enach,
                  document_verified: DocumentsVerified,
                }}
              />
            </div>
            {operationsList?.result?.length ? (
              <div
                style={{
                  display: "flex",
                  margin: "0px 15px 10px 15px",
                }}
              >
                {selectedRows?.length ? (
                  <div
                    style={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "flex-end",
                      fontSize: "14px",
                      lineHeight: "16px",
                      color: "#000",
                    }}
                  >
                    Selected Rows: {selectedRows?.length || 0}
                  </div>
                ) : null}
                <Pagination
                  style={{
                    justifyContent: "right",
                    textAlign: "right",
                    marginLeft: "auto",
                  }}
                  current={operationsApiParams?.page}
                  onChange={onChangePage}
                  total={Number(operationsList?.count || 0)}
                  pageSize={operationsApiParams?.pageSize}
                  defaultPageSize={operationsApiParams?.page}
                  showSizeChanger={false}
                  showTotal={showTotal}
                />
              </div>
            ) : null}
          </Col>
        </Row>
      </div>
    </>
  );
}
