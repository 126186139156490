import React, { useState, useRef } from "react";
import { useSelector, useDispatch } from "react-redux";
import { document_status } from "../../utitlities/status";
import * as _ from "lodash";
import { Divider, Row, Col, Button, Table, Input, Space, Dropdown, Select, Modal, Tag, Upload, message, Image } from "antd";
import { ColumnsType } from "antd/lib/table";
import type { MenuProps } from 'antd';
import { DeleteTwoTone, UserOutlined, DownOutlined, DownloadOutlined, UploadOutlined, CheckCircleTwoTone, DeleteOutlined, CloseCircleOutlined, MinusCircleTwoTone } from "@ant-design/icons"
import { MainReducer } from "../../types";
import { fileUpload, deleteResources, deleteDocument, s3fileUpload, fileUploadNew } from "../../utitlities/request";
import { fetchEmployee } from "../../state/action-creators";


const { Option } = Select;
// type RotationStates = Record<string, number>;

const Documents: React.FC = () => {
  const dispatch = useDispatch();
  const employee = useSelector((state: MainReducer) => {
    return state.loan.employee.body;
  });
  const fileInputRef = useRef<HTMLInputElement | null>(null);
  const docStates = [
    "pan",
    "aadhaar_front",
    "aadhaar_back",
    "bank_statement",
    "selfie",
    "current_address_proof",
    "salary_slip",
    "passbook",
    "permanent_address_front",
    "permanent_address_back",
    "emp_id_doc"
  ];
  const [isModalDelete, setIsModalDelete] = useState(false);
  const [isModalDeleteId, setIsModalDeleteId] = useState<number>(-1);
  const [docoption, setDocOption] = useState('');


  // const [rotationStates, setRotationStates] = useState<RotationStates>({});
  // const rotateImage = (recordId:any) => {
  //   setRotationStates((prevRotationStates) => ({
  //     ...prevRotationStates,
  //     [recordId]: (prevRotationStates[recordId] || 0) + 90,
  //   }));
  // };


  const [showUpload, setShowUpload] = useState(false);
  const documents = parseDocuments(employee.documents);
  const onClick: MenuProps['onClick'] = (key: any) => {
    key = key.key;
    setDocOption(docStates[key]);
    setShowUpload(true);
    console.log('show up', showUpload);
    if (fileInputRef.current) {
      fileInputRef.current.click();
    }
  };
  async function onChangeDoc(element: any, verify: any) {
    let data = {
      state: verify,
      document_type: element.document_type,
      document_url: element.document_url,
    };
    try {
      const result = await fileUpload(data, element.id);
      dispatch(fetchEmployee(String(employee.id)))
    } catch (err) {
      message.error('Could not update status')
    }
  }

  const items: MenuProps['items'] = docStates.map((label, index) => ({
    label: label.toUpperCase().replace(/_/g, ' '),
    key: index,
    icon: <UserOutlined />,
  }));

  const handleFileUpload = async (event: React.ChangeEvent<HTMLInputElement>) => {
    const selectedFile = event.target.files && event.target.files[0];

    if (selectedFile) {
      try {

        const formData = new FormData();
        formData.append('file', selectedFile);

        const response = await s3fileUpload(formData);
        console.log('Selected file:', response);
        let data = {
          document_type: docoption,
          document_url: response.Location,
          is_verified: true,
          emp_id: employee.id,
        };
        await fileUploadNew(data, employee.id);
        dispatch(fetchEmployee(String(employee.id)));
      }
      catch (err) {
        message.error("could not upload document");
      }
    }
  };

  async function deleteRejectedDocs(emp_id: number, type: string) {
    if (emp_id != -1) {
      const resources = await deleteResources(emp_id, type);
      if (resources.statusCode == 200) {
        message.success(resources.message);
        // add loading spinner
        dispatch(fetchEmployee(String(emp_id)));
      } else message.error(resources.message);
    }
  }

  const columns: ColumnsType<any> = [
    {
      title: "Name",
      dataIndex: "document_type",
      key: "name",
      render: (text, record, index) => {
        if (record.isEdit) {
          return (
            <input
              value={text}
              onChange={(e) => { /* Handle input change */ }}
            />
          );
        } else if (record.document_type === "salary_slip") {
          return (
            <div>
              <a onClick={() => onChangeDoc(record.document_url, index)} target="_blank">
                {record.document_name.toUpperCase()}
              </a>
              {record.meta ? (
                <p style={{ fontSize: 12, color: 'gray' }}>{record.meta}</p>
              ) : (
                <p style={{ fontSize: 12, color: 'gray' }}>Date unavailable</p>
              )}
            </div>
          );
        } else {
          return (
            <a onClick={() => onChangeDoc(record.document_url, index)} target="_blank">
              {record.document_name.toUpperCase()}
            </a>
          );
        }
      },
    },
    {
      title: "Document Verification",
      dataIndex: "state",
      key: "state",
      render: (_, record, index) => (
        <Space size="middle">
          {record.state === 'APPROVED' ? (
            <CheckCircleTwoTone
              twoToneColor="#52c41a" // Green color
              style={{ fontSize: '20px', backgroundColor: 'green', borderRadius: '50%' }}
            />
          ) : record.state === 'PENDING' ? (
            <MinusCircleTwoTone
              twoToneColor="orange" // Grey color
              style={{ fontSize: '20px', backgroundColor: 'grey', borderRadius: '50%' }}
            />
          ) : (
            <CloseCircleOutlined
              style={{ fontSize: '20px', color: 'red' }}
            />
          )}
        </Space>
      ),
    },
    {
      title: "Update Verification",
      dataIndex: "state",
      key: "state",
      render: (text, record, index) => (
        <Select
          value={record.state}
          style={{ width: "75%" }}
          onChange={async (el) => { await onChangeDoc(record, el); }}
        >
          {document_status.map((element: any, index: number) => {
            return (
              <Option key={`docstatuses-${element}-${index}`} value={element}>{element}</Option>
            );
          })}
        </Select>
      ),
    },
    {
      title: "Data Validation",
      key: "validation_state",
      fixed: "right",
      render: (_, record, index) => (
        <Space size="middle">
          {record.validation_state === 'APPROVED' ? (
            <CheckCircleTwoTone
              twoToneColor="#52c41a" // Green color
              style={{ fontSize: '20px', backgroundColor: 'green', borderRadius: '50%' }}
            />
          ) : record.validation_state === 'PENDING' ? (
            <MinusCircleTwoTone
              twoToneColor="orange" // Grey color
              style={{ fontSize: '20px', backgroundColor: 'grey', borderRadius: '50%' }}
            />
          ) : (
            <CloseCircleOutlined
              style={{ fontSize: '20px', color: 'red' }}
            />
          )}
        </Space>
      ),

    },
    {
      title: "Rejected Fields",
      dataIndex: "rejected_fields",
      key: "rejected_fields",
      render: (text, record, index) => (
        <span>
          {record.rejected_fields.map((status: string, idx: number) => (
            <Tag key={idx} style={{ backgroundColor: 'rgba(255, 0, 0, 0.2)', color: 'darkred', }}>{status}</Tag>
          ))}
        </span>
      ),
    },
    {
      title: "Action",
      key: "action",
      fixed: "right",
      render: (_, record, index) => (
        <Space size="middle">
          {record.isEdit ? (
            <Button onClick={() => { }}>Save</Button>
          ) : (
            <div style={{ display: "flex", gap: "10px" }}>
              <DownloadOutlined
                twoToneColor="#b53328"
                style={{ fontSize: "20px" }}
                onClick={() => window.open(record.document_url, "_blank")} />
              <DeleteTwoTone twoToneColor="#b53328" style={{ fontSize: "20px" }}
                onClick={() => {
                  setIsModalDelete(true);
                  setIsModalDeleteId(record.id);
                  console.log('record', record);
                }} />
            </div>
          )}
        </Space>
      ),
    },
    {
      title: "Uploaded on",
      dataIndex: "created_at",
      key: "created_at",
      render: (text, record, index) => {
        return <span style={{ fontSize: '12px' }}>{text.split('T')[0]}</span>;
      },
    },
  ];

  function parseDocuments(docs: Array<any>) {
    let parsed_docs = _.chain(docs)
      .groupBy("document_type")
      .map((value, key) => ({ document_type: key, docs: value }))
      .value();

    let new_array: any = [];

    Object.keys(parsed_docs).forEach((el: any) => {
      // sort them by id
      parsed_docs[el]["docs"].sort((a: any, b: any) => {
        if (a.id > b.id) {
          return 1;
        } else {
          return -1;
        }
      });
      parsed_docs[el]["docs"].forEach((element) => {
        new_array.push(element);
      });
    });

    let counter: number = 0;
    let key: any = "";
    if (docs[0]) {
      key = docs[0]["document_type"];
    }

    new_array.forEach((el: any) => {
      let status = el["document_type"];
      if (status == key) {
        counter++;
      } else {
        counter = 1;
      }
      el["document_name"] = `${el["document_type"] + " " + counter}`;
      key = status;
    });
    return docs;
  }

  const deleteDoc = async () => {
    try {
      if (isModalDeleteId !== -1) {
        await deleteDocument(isModalDeleteId);
        dispatch(fetchEmployee(String(employee.id)))
      }
    } catch (err) {
      message.error("could not delete document");
    }
  };
  const expandedRowRender = (record: any) => {
    const url: string = record.document_url;
    let render_url: string = record.document_url;
    let file_type: string = "image";
    const downloadUrlArray = url.split(".");
    const extension = downloadUrlArray.pop();

    if (extension === "pdf") {
      // setFileType("pdf");
      file_type = "pdf";
      // setCloseFile(true);
      const xhr = new XMLHttpRequest();
      // Open the request to the S3 bucket link
      xhr.open("GET", url, true);
      xhr.responseType = "blob";
      // Wait for the request to complete
      xhr.onload = () => {
        if (xhr.status === 200) {
          // Get the blob from the response
          const blob = xhr.response;
          const contentType = xhr.getResponseHeader("content-type");
          // Set the file name and type based on the content-type
          let fileName = "", fileType;
          if (contentType === "application/pdf") {
            fileName = "fileName.pdf";
            fileType = "application/pdf";
          }
          // Create a new File object
          const fileURL = new File([blob], fileName, { type: fileType });
          render_url = URL.createObjectURL(fileURL);
        } else {
          console.log("Error: ", xhr.status);
        }
      };
      xhr.send();
    } else {
      file_type = "image";
      // setCloseFile(true);
      render_url = url;
    }
    // const rotation = rotationStates[record.id] || 0;
    return file_type === "image" ?
      <div style={{ display: "flex", alignItems: "center" }}>
        <Image
          width={400}
          src={render_url}
        // style={{ transform: `rotate(${rotation}deg)`, transition: 'transform 0.5s ease' }}
        />
        {/* <Button onClick={() => rotateImage(record.id)}>Rotate 90°</Button> */}
      </div> :
      <div
        style={{
          textAlign: "center",
          width: "100%",
        }}
      >
        <iframe src={render_url} width="100%" height="700" />
      </div>
  }
  return (
    <div className="emp-info-form">
      <div className="title">Documents</div>
      <Divider style={{ borderColor: "#E5E7EA", margin: "20px 0px" }} />
      <Table rowKey={(record) => record.id} columns={columns} dataSource={documents} expandable={{ expandedRowRender }} />
      <div style={{ display: 'flex', alignItems: 'center' }}>
        <Dropdown menu={{ items, onClick }}>
          <Button
            type="primary"
            style={{
              marginLeft: "20px",
              backgroundColor: "#1B875A",
              border: 0
            }}
          >
            <Space>
              Upload Documents
              <DownOutlined />
            </Space>
          </Button>
        </Dropdown>
        <Button
          type="primary"
          style={{
            background: "red",
            borderColor: "red",
            color: "white",
            marginLeft: '10px',
          }}
          icon={<DeleteOutlined style={{ fontSize: '16px' }} />}
          onClick={() => deleteRejectedDocs(employee.id || -1, 'document')}
        >
          Delete All Rejected Documents
        </Button>
      </div>
      <Modal
        title="Alert"
        open={isModalDelete}
        onOk={() => {
          deleteDoc();
          setIsModalDeleteId(-1);
          setIsModalDelete(false);
        }}
        onCancel={() => {
          setIsModalDeleteId(-1);
          setIsModalDelete(false);
        }}
      >
        <p>Are you sure you want to delete this document ?</p>
      </Modal>
      <input
        type="file"
        ref={fileInputRef}
        style={{ display: 'none' }}
        onChange={handleFileUpload}
      />
    </div>
  );
};
export default Documents;
