import { Table } from "antd";

export const ExistingLoans = (props: any) => {
  props = props.props;
  const loans = props.loans;
  const loanRequests = props.loanRequests;

  return (
    <div style={{ display: "flex" }}>
      <div style={{ flex: 1, height: "200px", overflow: "auto" }}>
        <Table
          columns={props.existingLoanRequestsCols}
          dataSource={loanRequests}
          pagination={false}
          scroll={{ y: 150 }}
          style={{ borderSpacing: "0px" }}
        />
      </div>
      <div style={{ flex: 2, height: "200px", overflow: "auto", marginLeft: "5%" }}>
        <Table
          columns={props.existingLoansCols}
          dataSource={loans}
          pagination={false}
          scroll={{ y: 150 }}
          style={{ borderSpacing: "0px" }}
        />
      </div>
    </div>
  );
};
