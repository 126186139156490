import { useEffect, useState } from "react";
import { CheckOutlined, CloseOutlined } from "@ant-design/icons";

export function Employee(props: any) {
  const [state, setState] = useState(null);

  useEffect(() => {
    setState(props["data"]["employee_present"]);
  }, []);

  return (
    <>
      {" "}
      {state === true ? (
        <CheckOutlined style={{ color: "green" }} />
      ) : (
        <CloseOutlined style={{ color: "red" }} />
      )}
    </>
  );
}
