import { Row, Col, Table, Divider } from 'antd'
export const StatusLogs = (props: any) => {
	props = props.props;
	return (
		<div>
			{props.LogsStatus ? (
				<Table columns={props.statusLogsCols} dataSource={props.logs} />
			) : (
				""
			)}
			<Row>
				<Col span={1}>
					{" "}
					<Divider style={{ fontFamily: "", fontSize: "20px" }}>
						{" "}
					</Divider>
				</Col>
			</Row>
		</div>
	);
}