export enum ActionType {
    FETCHEMPLOYEE = "fetchEmployee",
    UPDATEEMPLOYEE = "updateEmployee",
    FETCHLOAN = "fetchLoan",
    FETCHLOANREQUEST = 'fetchLoanRequest',
    UPDATELOANREQUEST = "updateRequest",
    FETCHNBFC = 'fetchnbfc',
    RESET = 'reset',
    OPERATIONSFILTER = 'operationsFilter',
    APPRAISALFILTER = 'appraisalFIlter',
    FETCHCOMMENTS = 'fetchComments',
    FETCHLOGS = 'fetchLogs',
    FETCHSTREAM = 'fetchStream',
    FECTHREPAYMENT = 'fetchRepayment',
    FETCHARTHMATE = 'fetchArthmate',
    SET_OPERATIONS_API_PARAMS = "SET_OPERATIONS_API_PARAMS",
    SET_OPERATIONS_LIST = "SET_OPERATIONS_LIST",
    GET_OPERATIONS_LIST_API_LOADING = "GET_OPERATIONS_LIST_API_LOADING",
    SET_APPRAISAL_API_PARAMS = "SET_APPRAISAL_API_PARAMS",
    SET_APPRAISAL_LIST = "SET_APPRAISAL_LIST",
    GET_APPRAISAL_LIST_API_LOADING = "GET_APPRAISAL_LIST_API_LOADING",
    SET_MASTER_VIEW_API_PARAMS = "SET_MASTER_VIEW_API_PARAMS",
    SET_MASTER_VIEW_LIST = "SET_MASTER_VIEW_LIST",
    GET_MASTER_VIEW_LIST_API_LOADING = "GET_MASTER_VIEW_LIST_API_LOADING",
    CLEAR_EMPLOYEE_DATA =  "CLEAR_EMPLOYEE_DATA"
    
}