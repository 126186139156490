import { Col, Row } from "antd";
import React, { useEffect } from "react";
import { useDispatch } from "react-redux";
import { useLocation } from "react-router-dom";
import {
  fetchArthmate,
  fetchComments,
  fetchEmployee,
  fetchLoan,
  fetchLoanRequest,
  fetchLogs,
  fetchStream,
  clearEmployeeData
} from "../../state/action-creators";
import Comments from "./Comments";
import PersonalDetails from "./PersonalDetails";
import UpdateDetailTabs from "./UpdateDetailTabs";
import TextArea from "./TextArea";
import PendingItems from "./PendingItems";
import "./style.scss";


const AppraisalDetail: React.FC = () => {
  const dispatch = useDispatch();
  const search = useLocation().search;
  const loan_id = new URLSearchParams(search).get("loan_id");
  const employee_id = new URLSearchParams(search).get("employee_id");
  const request_id = new URLSearchParams(search).get("request_id");
  const type = new URLSearchParams(search).get("type");

  useEffect(() => {
    //if needed can be shifted to child comps
    dispatch(fetchLoanRequest(Number(request_id)));
    dispatch(fetchEmployee(String(employee_id)));
    dispatch(fetchLogs(request_id));
    dispatch(fetchStream('loan_request', request_id));
    dispatch(fetchComments(request_id));
    if (loan_id) {
      dispatch(fetchLoan(Number(loan_id)));
      dispatch(fetchArthmate(loan_id));
    }
    return () => {
      dispatch(clearEmployeeData());
    };
  }, []);

  return (
    <div className="appraisal-container">
      <Row gutter={24} style={{ display: 'flex', alignItems: 'stretch', marginBottom: "20px" }}>
        <Col xs={24} xl={16} style={{ height: '800px', paddingTop: "12px" }}>
          <PersonalDetails />
        </Col>
        <Col xs={24} xl={8} style={{ display: 'flex', flexDirection: 'column', height: '800px', overflowY: 'auto', paddingTop: "12px" }}>
          <div style={{
            flex: 3,
            marginBottom: '4px',
          }}>
            <Comments />
          </div>
          <div style={{
            flex: 7,
            overflowY: 'auto',
            height: '100%',
            padding: '2px'
          }}>
            <PendingItems />
          </div>
        </Col>
      </Row>
      <UpdateDetailTabs></UpdateDetailTabs>
    </div>

  );
};
export default AppraisalDetail;
