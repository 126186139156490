import { Button } from "antd";
import { Redirect } from "react-router-dom";
import { useEffect, useState } from "react";
import {
  CheckOutlined,
  CheckCircleTwoTone,
  CloseOutlined,
} from "@ant-design/icons";
export function Enach(props: any) {
  const [state, setState] = useState(null);
  useEffect(() => {
    setState(props["data"]["enach_possible"]);
  }, []);
  return (
    <>
      {" "}
      {state === true ? (
        <CheckOutlined style={{ color: "green" }} />
      ) : (
        <CloseOutlined style={{ color: "red" }} />
      )}
    </>
  );
}
