
import moment from "moment";

export const calculateSalaryMultiplierWeekly = (experience: any) => {
    experience = Number(experience);
    let salary_multiplier: number = 0
    if (experience <= 0.75) {
        salary_multiplier = 0
    }
    else if (experience > 0.75 && experience <= 1) {
        salary_multiplier = 3
    }
    else if (experience > 1 && experience <= 1.5) {
        salary_multiplier = 4
    }
    else if (experience > 1.5 && experience <= 2) {
        salary_multiplier = 5
    }
    else if (experience > 2 && experience <= 2.5) {
        salary_multiplier = 6
    }

    else if (experience > 2.5 && experience <= 3) {
        salary_multiplier = 7
    }
    else if (experience > 3) {
        salary_multiplier = 8
    }
    console.log("calculating weekly salary multiplier",salary_multiplier)
    return salary_multiplier

}



export const calculateEmiFirstDateWeekly = (approval_date:any) => {
    let dayINeed = 3
    let current = moment(approval_date).isoWeekday(dayINeed).add(2, 'w').format("YYYY-MM-DD")
    console.log("calculating weekly emi first date",current)
    return current;

}
export const calculatePreEmiDateWeekly = (emi_first_date:any) => {
    let current = moment(emi_first_date).subtract(7, 'days').format("YYYY-MM-DD")
    console.log("calculating weekly pre emi first date",current)
    return current;
}



export const calculateTenureWeekly: any = (approved_amount: any, avg_salary: any) => {
    //  Tenure: laon amount / (average salary*20%) min: 6 max: 25
    approved_amount = Number(approved_amount)
    avg_salary = Number(avg_salary);
    let result = Math.ceil(((approved_amount / (avg_salary))))
    if (result < 6)
        result = 6
    if (result > 25)
        result = 25
    console.log("calculating weekly tenure",result)
    return result


}

//@ts-ignore
export const calculateApprovedAmountWeekly = (salary_multiplier: any, avg_salary: any, amount_requested: any) => {
    // Average salary * salary multiplier. Max amount limited to 20K
    //console.log(amount_requested)
    salary_multiplier = Number(salary_multiplier)
    avg_salary = Number(avg_salary) > 0 ? Number(avg_salary) : 0;
    let result: number = Math.round(avg_salary * salary_multiplier) < amount_requested ? Math.round(avg_salary * salary_multiplier) : amount_requested;
    console.log("calculating weekly approved amount",avg_salary,salary_multiplier,result <= 20000 ? result : 20000)
    return result <= 20000 ? result : 20000;
}

export const calculateIrrWeekly = (tenure: any) => {

    
    let irr: number = 0.00;
    tenure = Number(tenure)
    switch (tenure) {
        case 1:
            irr = 18.20
            break;
        case 2:
            irr = 24.27
            break;
        case 3:
            irr = 27.30
            break;
        case 4:
            irr = 29.12
            break;
        case 5:
            irr = 30.33
            break;
        case 6:
            irr = 31.20
            break;
        case 7:
            irr = 31.85
            break;
        case 8:
            irr = 32.36
            break;
        case 9:
            irr = 32.76
            break;
        case 10:
            irr = 33.09
            break;
        case 11:
            irr = 33.37
            break;
        case 12:
            irr = 33.60
            break;
        case 13:
            irr = 33.80
            break;
        case 14:
            irr = 33.97
            break;
        case 15:
            irr = 34.13
            break;
        case 16:
            irr = 34.26
            break;
        case 17:
            irr = 34.38
            break;
        case 18:
            irr = 34.48
            break;
        case 19:
            irr = 34.58
            break;
        case 20:
            irr = 34.67
            break;
        case 21:
            irr = 34.75
            break;
        case 22:
            irr = 34.82
            break;
        case 23:
            irr = 34.88
            break;
        case 24:
            irr = 34.94
            break;
        case 25:
            irr = 35.00
            break;
        case 26:
            irr = 36.00
            break;

    }

    console.log("calculating weekly irr",irr.toFixed(2))
    return irr.toFixed(2)

}

export const calculateProcesasingFeeWeekly = (approved_amount:any) => {
    if(Number(approved_amount)  > 10000){
        return 354 
    }
    console.log("calculating weekly processing fee",236)
    return 236
}



