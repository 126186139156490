import React, { useEffect, useState } from "react";
import {useSelector} from "react-redux"
import { Divider, Space, Table } from "antd";
import type { ColumnsType } from "antd/es/table";
import {HighlightOutlined, DeleteTwoTone} from '@ant-design/icons'
import { MainReducer } from "../../types";
import { getUsers } from "../../utitlities/request";
const _ = require('lodash');
interface Logs {
  // updatedBy: string;
  status_time: any;
  status: string;
}


const StatusLogs: React.FC = () => {
  const loan = useSelector((state: MainReducer) => {
    return state.loan;
  });

  const [users, setUsers] = useState([]);
  async function fetchUserApi() {
    const users = await getUsers();
    setUsers(users);
  }

  useEffect(()=>{
    fetchUserApi();
  },[loan])
  const logs = loan.logs.body.data;
  const logsData = logs.map((s:any,index:Number) => ({
    appraisal_updatedBy: _.get(_.find(users, { id: s.appraisal_agent }), 'user_name', null),
    ops_updatedBy: _.get(_.find(users, { id: s.ops_agent }), 'user_name', null),
    status_time: s.status_time.split("T")[0],
    status:s.status
  }))
  const columns: ColumnsType<Logs> = [
    {
      title: "Status",
      dataIndex: "status",
      key: "status",
    },
    {
      title: "Updated At",
      dataIndex: "status_time",
      key: "status_time",
    },
    {
      title: "Appraisal Updated By",
      dataIndex: "appraisal_updatedBy",
      key: "appraisal_updatedBy",
    },
    {
      title: "Ops Updated By",
      dataIndex: "ops_updatedBy",
      key: "ops_updatedBy",
    },
  ];
  return (
    <div className="emp-info-form">
      <div className="title">Status Logs</div>
      <Divider style={{ borderColor: "#E5E7EA", margin: "20px 0px" }} />
      <Table columns={columns} dataSource={logsData} />
      <div className="form01"></div>
    </div>
  );
};
export default StatusLogs;
