import React, { useState, useEffect, forwardRef, useImperativeHandle } from 'react';
import { Select, Space, Button } from 'antd';
import 'antd/dist/antd.css';

interface CustomDropdownFilterProps {
    options: string[];
    filterChangedCallback: () => void;
    agGridReact?: any;
    params?: any;
    type: any;
}

const CustomDropdownFilter = forwardRef((props: CustomDropdownFilterProps, ref) => {
    const { options, filterChangedCallback, params, type } = props;
    const [selectedValue, setSelectedValue] = useState<string | null>(null);
    const [filteredOptions, setFilteredOptions] = useState<string[]>(options);

    useEffect(() => {
        setFilteredOptions([...options, "Not Assigned"]);
    }, [options]);

    useEffect(() => {
        filterChangedCallback();
    }, [selectedValue, filterChangedCallback]);

    useImperativeHandle(ref, () => ({
        isFilterActive() {
            return selectedValue !== null;
        },
        doesFilterPass(params: any) {
            if (selectedValue == "Not Assigned") {
                return type == 'loan' ? (!params.data.user_name || params.data.user_name.trim() === "") : (!params.data.ops_username || params.data.ops_username.trim() === "");
            }
            return type == 'loan' ? params.data.user_name === selectedValue : (params.data.ops_username === selectedValue);
        },
        getModel() {
            return selectedValue
                ? { filterType: "text", type: selectedValue }
                : null;
        },
        setModel(model: { filterType: string; type: string } | null) {
            setSelectedValue(model ? model.type : null);
        }
    }));

    const hideFilterPopup = () => {
        if (params && params.api && params.column) {
            params.api.getFilterInstance(params.column.getColId(), (instance: any) => {
                instance.onFloatingFilterChanged();
                params.api.hidePopup();
            });
        }
    };

    const handleApply = () => {
        filterChangedCallback();
        hideFilterPopup();
    };

    const handleReset = () => {
        setSelectedValue(null);
        filterChangedCallback();
        hideFilterPopup();
    };

    const handleCancel = () => {
        hideFilterPopup();
    };

    const handleChange = (value: string) => {
        setSelectedValue(value);
    };

    return (
        <div style={{ padding: '10px' }}>
            <Select
                showSearch
                placeholder="Please select an option"
                optionFilterProp="children"
                style={{ width: "100%" }}
                onChange={handleChange}
                value={selectedValue}
                filterOption={(input, option: any) =>
                    option.children.toLowerCase().includes(input.toLowerCase())
                }
            >
                {filteredOptions.map((option, index) => (
                    <Select.Option key={index} value={option}>
                        {option}
                    </Select.Option>
                ))}
            </Select>
            <Space style={{ marginTop: '10px', display: 'flex', justifyContent: 'center' }}>
                <Button onClick={handleApply} type="primary">Apply</Button>
                <Button onClick={handleReset}>Reset</Button>
                <Button onClick={handleCancel}>Cancel</Button>
            </Space>
        </div>
    );
});

export default CustomDropdownFilter;
