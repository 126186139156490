import {
  Descriptions,
  Badge,
  Typography,
  Card,
  Input,
  Spin,
  message,
  Row,
  Col,
  Button,
  Select,
  Modal,
  Switch
} from "antd";

import { useEffect, useState, useRef } from "react";
import {
  employeeDetails,
  EsignNotifications,
  getLoanRequest,
  getOriginalLoanData,
  nbfc,
  updateAllRowsConstant,
} from "../utitlities/request";
import { HighlightOutlined, MehOutlined } from "@ant-design/icons";
import {
  calculateApprovedAmount,
  calculateAverageSalary,
  calculateEmiFirstDate,
  calculateExpectedSalaryDate,
  calculateHealthPremium,
  calculateIrr,
  calculatePreEmiDate,
  calculatepreEmiPeriod,
  calculateProcesasingFee,
  calculateSalaryMultiplier,
  calculateTenure,
  DisbursedAmount,
  EmiAmount,
  Interest,
  preEmiInterest,
  TotalAmountToBeRepaid,
} from "../utitlities/calculations";
import usePrevious from "../utitlities/previous";
import { useSelector } from "react-redux";
import { appraisalNotifications, enach, esign } from "../utitlities/request";
import { MainReducer } from "../types";
const { Title, Paragraph, Text, Link } = Typography;
const { Option } = Select;
export function Loan(props: any) {
  const [eNachData, setEnach] = useState<any>({
    account_no: "",
    enach_url: "",
    source_id: "",
    status: "",
  });
  const [emi, setEmi] = useState<any>({
    file_name: "",
    org_id: "",
    sign_url: "",
    status: "",
    upload_url: "",
    webhook_url: "",
    timestamp: "",
  });
  const [eSignData, setEsign] = useState<any>({});
  const [data, setData] = useState<any>({});
  const [emi_amount, setEmiAmount] = useState<any>();

  // Loan Details
  const [expected_salary_date, setExpectedSalaryDate] = useState(""); // derived
  const [approval_date, setApprovalDate] = useState("");
  const [average_salary, setAveragesalary] = useState("1000");
  const [approved_amount, setApprovedAmount] = useState("");
  const [loan_amount, setLoanAmount] = useState("");
  const [tenure, setTenure] = useState("");
  const [irr, setIrr] = useState("");
  const [processing_fee, setProcessingFee] = useState("");
  const [userrole, setUserRole] = useState("");
  const [role, setRole] = useState("monthly");
  const [roles, setRoles] = useState(["monthly","weekly"]);
  const [experience, setExperience] = useState("");
  const [salaryMultiplier, setsalaryMultiplier] = useState(0);
  const [loan_id, setLoanId] = useState(props["loan_id"]);
  // previous
  const previousApprovedAmount = usePrevious(approved_amount);
  const prevTenure = usePrevious(tenure);
  const prevIrr = usePrevious(irr);
  const prevProcessingFee = usePrevious(processing_fee);

  // Derived
  const [installment_amt, setInstallmentAmount] = useState("");
  const [first_date, setFirstDate] = useState("");
  const [pre_emi_date, setPreEmiDate] = useState("");
  const [interest, setInterest] = useState("");
  const [pre_emi_interest, setPreEmiInteret] = useState("");
  const [total_amount_to_be_repaid, setAmountRepaid] = useState("");
  const [disbursal_amount, setDisbursalAmount] = useState("");
  //previous
  const prev_installment_amt = usePrevious(installment_amt);
  const prev_first_date = usePrevious(first_date);
  const prev_pre_emi_date = usePrevious(pre_emi_date);
  const prev_interest = usePrevious(interest);
  const prev_pre_emi_interest = usePrevious(pre_emi_interest);
  const prev_approval_date = usePrevious(approval_date);
  const prev_loan_amount = usePrevious(loan_amount);
  const prev_role = usePrevious(role);
  //nbfc 
  const [current_nbfc, setCurrentNbfc] = useState<any>();
  let [nbfcel, setNbfc] = useState<any>(useSelector((state:MainReducer) => {return state.loan.nbfc.body}));
  // for loader
  // first time
  const [first, setFirst] = useState(0);

  const [visible, isVisible] = useState(false);
  // for Salaries
  const [salaries, isSalaries] = useState(true);
  const firstUpdate = useRef(true);
  //
  const [health_prem, setHealthPrem] = useState("");
  const [health_prem_opted,setHealthPremOpted] = useState(false);
  const  prev_health_prem = usePrevious(health_prem);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [isModalVisibleEnach, setIsModalVisibleEnach] = useState(false);
  const eNach = async () => {
    const result = await updateAllRowsScreen();
    if(result){
      let data = {
        loan_ids: [loan_id],
      };
     const loans = await enach(data);

     setTimeout(()=>{

      window.location.reload();
   },5000)
      // window.location.reload();
      // message.success("Enach generated Successfully");
    }
 
  };
  const eSign = async () => {
    const result =  await updateAllRowsScreen();
    let nbfchash: any={
      1:'enableCap',
      2:'siply'
    }
    if(result){
      let data = {
        loan_ids: [loan_id],
        nbfc:nbfchash[current_nbfc]
      };
     const loans = await esign(data);

     setTimeout(()=>{
        window.location.reload();
     },5000)   
   }
  
    // message.success("Esignature generated Successfully");
   
  };
  const appraisalNotify = async () => {
    let emandate_status = ['mandate activated',
    'pending_bank_approval',
    'source submitted',
    'mandate acknowledged'];

    if(!emandate_status.includes(eNachData["status"])){
      if(eNachData["enach_url"]){
        let data = {
          loan_ids: [loan_id],
        };
        const loans = await appraisalNotifications(data);
      }
      else{
        message.error("Enach not generated");
      }
    }
    else{
      message.error("E-mandate is already activated");

    }
  };

  const EsignNotify = async () => {
    if(eSignData["status"] !== "signed"){
      if(eSignData["status"] == "processed" || eSignData["status"] == "esignature sent"){
        let data = {
          loan_ids: [loan_id],
        };
        const loans = await EsignNotifications(data)
      }
      else{
        message.error("Esignature not generated");
        
      }
    }
    else{
      message.error('Esignature already signed');
    }
    
   
   
    
  };
  const products = useSelector((state:MainReducer) => {return state.loan.loan.body});
  const prev_products = usePrevious(products);
  const employee= useSelector((state:MainReducer) => {return state.loan.employee.body});
  const prev_employee = usePrevious(employee);
  const request = useSelector((state:MainReducer) => {return state.loan.loanRequest.body});
  const prev_request = usePrevious(request);
 
  const productsstatus = useSelector((state:MainReducer) => {return state.loan.loan.status});
  const employeestatus= useSelector((state:MainReducer) => {return state.loan.employee.status});
  const requeststatus = useSelector((state:MainReducer) => {return state.loan.loanRequest.status});
  const nbfcstatus = useSelector((state:MainReducer) => {return state.loan.nbfc.status});
  const onChangeHealthPremium = (checked: boolean)=>{
    if(checked){
      let healthPremium = calculateHealthPremium(Number(approved_amount),Number(tenure));
      setHealthPrem(String(healthPremium));
    }
    else{
      setHealthPrem(String(0));
    }
    setHealthPremOpted(checked)
  }
  useEffect(() => { 
    (async () => {
      {   
        let nbfcelements:any=await nbfc();
        setNbfc(nbfcelements);
        if(products && products['nbfc']){
          setCurrentNbfc(products['nbfc']['id'])
        }
        
        if (productsstatus && products["enach"]) {
          setEnach(products["enach"]);
        }
        if (productsstatus && products["esign"]) {
          setEsign(products["esign"]);
        }
       if((productsstatus && employeestatus && requeststatus)){
          if (Number(employee["employment"]["salary"]) > 0 ) {
          
            if (!products["saved_value"]) {
              console.log("set values from suggested",products);
               let role: any = products["repayment_schedule"]["role"] ? products["repayment_schedule"]["role"] : "monthly";
               setRole(role);
              console.log("enter the needed hook 2",role,prev_role)
              let salary: any = employee["salaries"];
              let experience = employee["experience"];
              setExperience(Number(experience).toFixed(1));
              let avg_salary = String(Number(employee["employment"]["salary"]));
              let app_date = new Date().toISOString().split("T")[0];
              setApprovalDate(app_date);
              setAveragesalary(avg_salary);
              let app_amt = calculateApprovedAmount(
                experience,
                avg_salary,
                request["amount_requested"],
                role
              );
              let tenure = calculateTenure(app_amt, avg_salary,role);
              let exp_salary = calculateExpectedSalaryDate(
                salary,
                role,
                employee["employment"]["cmp_id"]
              );
              let emifirstdate = calculateEmiFirstDate(
                exp_salary,
                app_date,
                role,
                employee["employment"]["cmp_id"]
              );
              let preemidate = calculatePreEmiDate(
                emifirstdate,
                app_date,
                role
              );
              let irr: any= calculateIrr(tenure,role);
              let approvedAmount =  calculateApprovedAmount(
                experience,
                avg_salary,
                request["amount_requested"],
                role
              );
              let preemiinterest = preEmiInterest(
                app_amt,
                Number(irr) / 100,
                calculatepreEmiPeriod(preemidate, app_date)
              );
              setHealthPremOpted(products["health_prem_opted"])
              let healthPremium = calculateHealthPremium(approvedAmount,tenure);
              if (!products["health_prem_opted"]){
                 healthPremium = 0
              }
              let ln_amt =
                calculateApprovedAmount(
                  experience,
                  avg_salary,
                  request["amount_requested"],
                  role
                ) + preemiinterest + Number(healthPremium);
             
              setApprovedAmount(
                String(
                 approvedAmount
                )
              );
              // salary multiplier
              setsalaryMultiplier(
                calculateSalaryMultiplier(app_amt,avg_salary,role,experience)
              );
              setTenure(String(tenure));
              setIrr(String(irr));
              let pro_fee: any= calculateProcesasingFee(role,approvedAmount);
              setProcessingFee(String(pro_fee));
              // @ts-ignore
              let divide_amount = role == "weekly" ? 52 : 12
              let inst_amt = EmiAmount(irr / 100 / divide_amount, tenure, ln_amt * -1, 0, 0);
              setInstallmentAmount(String(inst_amt));
              //@ts-ignore
              let interest = Interest(tenure, irr, ln_amt, inst_amt);
              setInterest(String(interest));
              let amt_to_be_repaid = TotalAmountToBeRepaid(ln_amt, interest);
              setAmountRepaid(String(amt_to_be_repaid));
             // setEmiAmount(String(products["repayment_schedule"]["emi_amount"]));
              //@ts-ignore
              setExpectedSalaryDate(exp_salary);
              setFirstDate(emifirstdate);
              setPreEmiDate(preemidate);
              // @ts-ignore
              setLoanAmount(
                String(
                 ln_amt
                )
              );
              setPreEmiInteret(String(preemiinterest));
              let disbursalamt = DisbursedAmount(ln_amt, pro_fee, preemiinterest) - Number(healthPremium);
              setDisbursalAmount(String(disbursalamt));
              // others
              setData(products);
              // set health prem according to conditions
              setHealthPrem(String(healthPremium));
              //  setEnach(products["enach"]);
              //  setEsign(products["esign"]);
              setEmi(products["repayment_schedule"]);
            } else {
              // set values from api
              console.log("set values from api");
              setHealthPremOpted(products["health_prem_opted"])
              setHealthPrem(String(products["health_prem"]))
              setRole(products["repayment_schedule"]["role"]);
              if (products["approval_date"]) {
                setApprovalDate(products["approval_date"].split("T")[0]);
              } else {
                setApprovalDate(new Date().toISOString().split("T")[0]);
              }
  
              let salary: any = employee["salaries"];
              let avg_salary = String(Number(employee["employment"]["salary"]));
              setAveragesalary(avg_salary);
              setApprovedAmount(String(products["approved_amount"]));
              setLoanAmount(
                String(
                  Number(products["approved_amount"]) + Number(products["repayment_schedule"]["pre_emi_interest"]) + Number(products["health_prem"])
                )
              );
              // salary multiplier
              setsalaryMultiplier(
                calculateSalaryMultiplier(products["approved_amount"],avg_salary,products["repayment_schedule"]["role"],Number(employee["experience"]).toFixed(1))
              );
              setExperience(Number(employee["experience"]).toFixed(1));
              setTenure(String(products["tenure"]));
              setIrr(String(products["irr"]));
              setProcessingFee(String(products["processing_fee"]));
              setInstallmentAmount(
                String(products["repayment_schedule"]["installment_amt"])
              );
              setInterest(String(products["repayment_schedule"]["interest"]));
              setAmountRepaid(String(products["total_amount_to_be_repaid"]));
              setData(products);
             // setEmiAmount(String(products["repayment_schedule"]["emi_amount"]));
              let exp_salary = calculateExpectedSalaryDate(
                salary,
                products["repayment_schedule"]["role"],
                employee["employment"]["cmp_id"]
              );
              //@ts-ignore
              setExpectedSalaryDate(exp_salary);
              // let emifirstdate = calculateEmiFirstDate(
              //   exp_salary,
              //   products["approval_date"]
              // );
              setFirstDate(String(products?.repayment_schedule?.first_date?.split("T")[0]));
              // let preEmiDate = calculatePreEmiDate(
              //   emifirstdate,
              //   products["approval_date"]
              // );
              setPreEmiDate(String(products?.repayment_schedule?.pre_emi_date?.split("T")[0]));
              setPreEmiInteret(
                String(products["repayment_schedule"]["pre_emi_interest"])
              );
              setDisbursalAmount(String(products["disbursal_amount"]));

              // calcultaing health premium even after saved data
              setTimeout(()=>{
                let healthPremium =  calculateHealthPremium(Number(products["approved_amount"]),Number(products['tenure']));
                console.log('comparing from api and calulated health premium',Number(healthPremium),'bhikari',health_prem)
                if(!products['health_prem_opted']){
                  healthPremium = 0
                }
                setHealthPrem(String(healthPremium));
              },2000)
          
              
            }
         
            setTimeout(()=>{
              isVisible(true);
              setFirst(1);
            },2500)
           
          } else {
            isSalaries(false);
          }
       }
        // if created_at and updates_at are same show suggested values;
      }
    })();
  }, [productsstatus, employeestatus , requeststatus]);

  useEffect(() => { 
    (async () => {
      {        
       if((productsstatus && employeestatus && requeststatus && first == 1)){
          if (Number(employee["employment"]["salary"]) > 0 ) {
            console.log("comparing",role,prev_role)
            if ((prev_role !== role)) {
              console.log("set values from suggested for change after everything is rendered",products);
              console.log("enter the change after everything is rendered",role,prev_role)
              let salary: any = employee["salaries"];
              let experience = employee["experience"];
              setExperience(Number(experience).toFixed(1));
              let avg_salary = String(Number(employee["employment"]["salary"]));
              let app_date = new Date().toISOString().split("T")[0];
              setApprovalDate(app_date);
              setAveragesalary(avg_salary);
              let app_amt = calculateApprovedAmount(
                experience,
                avg_salary,
                request["amount_requested"],
                role
              );
              let tenure = calculateTenure(app_amt, avg_salary,role);
              let exp_salary = calculateExpectedSalaryDate(
                salary,
                role,
                employee["employment"]["cmp_id"]
              );
              let emifirstdate = calculateEmiFirstDate(
                exp_salary,
                app_date,
                role,
                employee["employment"]["cmp_id"]
              );
              let preemidate = calculatePreEmiDate(
                emifirstdate,
                app_date,
                role
              );
              let irr: any= calculateIrr(tenure,role);
              let approvedAmount =   calculateApprovedAmount(
                experience,
                avg_salary,
                request["amount_requested"],
                role
              );
              let preemiinterest = preEmiInterest(
                app_amt,
                Number(irr) / 100,
                calculatepreEmiPeriod(preemidate, app_date)
              );
              setHealthPremOpted(products["health_prem_opted"])
              let healthPremium = calculateHealthPremium(approvedAmount,tenure);
              if (!products["health_prem_opted"]){
                 healthPremium = 0
              }
              let ln_amt =
                calculateApprovedAmount(
                  experience,
                  avg_salary,
                  request["amount_requested"],
                  role
                ) + preemiinterest + Number(healthPremium);
             
              setApprovedAmount(
                String(
                 approvedAmount
                )
              );
              // salary multiplier
              setsalaryMultiplier(
                calculateSalaryMultiplier(app_amt,avg_salary,role,experience)
              );
              setTenure(String(tenure));
              setIrr(String(irr));
              let pro_fee: any= calculateProcesasingFee(role,approvedAmount);
              setProcessingFee(String(pro_fee));
              // @ts-ignore
              let divide_amount = role == "weekly" ? 52 : 12
              let inst_amt = EmiAmount(irr / 100 / divide_amount, tenure, ln_amt * -1, 0, 0);
              setInstallmentAmount(String(inst_amt));
              //@ts-ignore
              let interest = Interest(tenure, irr, ln_amt, inst_amt);
              setInterest(String(interest));
              let amt_to_be_repaid = TotalAmountToBeRepaid(ln_amt, interest);
              setAmountRepaid(String(amt_to_be_repaid));
              // setEmiAmount(String(products["repayment_schedule"]["emi_amount"]));
              // @ts-ignore
              setExpectedSalaryDate(exp_salary);
              setFirstDate(emifirstdate);
              setPreEmiDate(preemidate);
              // @ts-ignore
              setLoanAmount(
                String(
                 ln_amt
                )
              );
              setPreEmiInteret(String(preemiinterest));
              let disbursalamt = DisbursedAmount(ln_amt, pro_fee, preemiinterest) - Number(healthPremium);
              setDisbursalAmount(String(disbursalamt));
              // others
              setData(products);
              // set health prem according to conditions
              setHealthPrem(String(healthPremium));
              //  setEnach(products["enach"]);
              //  setEsign(products["esign"]);
              setEmi(products["repayment_schedule"]);
            } 
          
          } 
       }
        // if created_at and updates_at are same show suggested values;
      }
    })();
  }, [role]);

  const updateAllRowsScreen = async () => {
    const fields = await validateAllFields();
    if(fields){
    
      let rows: any = [];
      let json = {
        repayment_schedule: {
          installment_amt: Number(installment_amt),
          installment_count: Number(tenure),
          first_date: first_date,
          role: role,
          emi_amount: Number(installment_amt),
          pre_emi_interest: Number(pre_emi_interest),
          pre_emi_date: pre_emi_date,
          interest: Number(interest),
          loan_id: Number(props.loan_id),
        },
        loan: {
          approval_date: approval_date,
          approved_amount: Number(approved_amount),
          tenure: Number(tenure),
          irr: Number(irr),
          processing_fee: Number(processing_fee),
          interest_amount: Number(interest),
          disbursal_amount: Number(disbursal_amount),
          total_amount_to_be_repaid: Number(total_amount_to_be_repaid),
          nbfc_id:current_nbfc,
          health_prem:Number(health_prem),
          saved_value: true,
          health_prem_opted:health_prem_opted
        },
      };
      rows.push(json);
     const result = await updateAllRowsConstant(rows);

      if (result["statusCode"] == 200) {
        message.success("updated data");
        return true;
      } else {
        message.error("could not update data");
        return false;
      }
    }
    
  };
  const validateAllFields = async () =>{
    let fields_for_number: any= {
      'Installment Amount': Number(installment_amt),
      'Emi Amount': Number(installment_amt),
      'Pre Emi Interest': Number(pre_emi_interest),
      'Interest': Number(interest),
      'Approved Amount': Number(approved_amount),
      'Tenure': Number(tenure),
      'Irr': Number(irr),
      'Processing Fee': Number(processing_fee),
      'Disbursal Amount': Number(disbursal_amount),
      'Total Amount to be Repaid': Number(total_amount_to_be_repaid),
      'Health Premium':Number(health_prem)
    }
    let field = "";
    Object.keys(fields_for_number).forEach((key:any)=>{
      if(isNaN(fields_for_number[key])){
         field = key
      }
    })
    if(field.length > 0){
      message.error(`${field} must be a numeric value`)
      return false;
    }
    else{
      return true;
    }
  }
  // considering multiple changes
  useEffect(() => {
    console.log("triggering first is",first)
   if(first == 1){


    // if (firstUpdate.current) {
    //   firstUpdate.current = false;
    //   return;
    // }

    if (previousApprovedAmount !== approved_amount) {
      console.log("approved amount changed", previousApprovedAmount, approved_amount);
      // set tenure

      setsalaryMultiplier(
          calculateSalaryMultiplier(approved_amount, average_salary, role, experience)
      );
      let divide_amount = role == "weekly" ? 52 : 12

      let tenure_cal = calculateTenure(
        Number(approved_amount),
        Number(average_salary),
        role
      );
      console.log("tenure is ", tenure, tenure_cal);
      setTenure(String(tenure_cal));
      
      let preemiinterest = preEmiInterest(
        Number(approved_amount),
        Number(irr) / 100,
        calculatepreEmiPeriod(pre_emi_date, approval_date)
      );

      console.log("pre emi interest is ", pre_emi_interest, preemiinterest);
      setPreEmiInteret(String(preemiinterest));
      if(health_prem_opted){
        setHealthPrem(String(calculateHealthPremium(Number(approved_amount),Number(tenure))));
      }
      setLoanAmount(
        String(Number(approved_amount) + Number(pre_emi_interest) + Number(health_prem))
      );
      // set Installment Amount
      let inst_amt = EmiAmount(
        Number(irr) / 100 / divide_amount,
        Number(tenure),
        Number(loan_amount) * -1,
        0,
        0
      );
      console.log("install amount  is ", installment_amt, inst_amt);
      setProcessingFee(String(calculateProcesasingFee(role,approved_amount)));
    }
    if (prevTenure !== tenure) {
      console.log("tenure changed",prevTenure,tenure);
      // set Irr
      let irr_cal = calculateIrr(Number(tenure),role);
      console.log("irr is ", irr, irr_cal);
      setIrr(String(irr_cal));
    }
    if (prevIrr !== irr) {
      console.log("irr changed",prevIrr,irr);
      let divide_amount = role == "weekly" ? 52 : 12
      let preemiinterest = preEmiInterest(
        Number(approved_amount),
        Number(irr) / 100,
        calculatepreEmiPeriod(pre_emi_date, approval_date)
      );

      console.log("pre emi interest is ", pre_emi_interest, preemiinterest);
      setPreEmiInteret(String(preemiinterest));
      if(health_prem_opted){
        setHealthPrem(String(calculateHealthPremium(Number(approved_amount),Number(tenure))));
      }
      setLoanAmount(
        String(Number(approved_amount) + Number(pre_emi_interest) + Number(health_prem))
      );
      // set Installment Amount
      let inst_amt = EmiAmount(
        Number(irr) / 100 / divide_amount,
        Number(tenure),
        Number(loan_amount) * -1,
        0,
        0
      );
      console.log("install amount  is ", installment_amt, inst_amt);
      setInstallmentAmount(String(inst_amt));
    }
    if (prev_installment_amt !== installment_amt) {
      console.log("install amount changed",prev_installment_amt,installment_amt);
      // set Interest
      let interest_cal = Interest(
        Number(tenure),
        Number(irr),
        Number(loan_amount),
        Number(installment_amt)
      );
      console.log("interest is ", interest, interest_cal);
      setInterest(String(interest_cal));
    }
    if (prev_interest !== interest) {
      console.log("interest changed",prev_interest,interest);
      // set Amount to be repaid
      let amt_to_be_repaid = TotalAmountToBeRepaid(
        Number(loan_amount),
        Number(interest)
      );
      console.log(
        "amount to be repaid is",
        total_amount_to_be_repaid,
        amt_to_be_repaid
      );
      setAmountRepaid(String(amt_to_be_repaid));
    }
    if (prev_first_date !== first_date) {
      console.log(" emi first date changed",prev_first_date,first_date);
      // set Pre Emi Date
      let preemidate = calculatePreEmiDate(first_date, approval_date,role);
      setPreEmiDate(preemidate);
    }
    if (prev_pre_emi_date !== pre_emi_date) {
      console.log(" pre emi first date changed",prev_pre_emi_date,pre_emi_date);
      // set pre Emi Interest
      let preemiinterest = preEmiInterest(
        Number(approved_amount),
        Number(irr) / 100,
        calculatepreEmiPeriod(pre_emi_date, approval_date)
      );
      console.log("pre emi interest is ", pre_emi_interest, preemiinterest);
      setPreEmiInteret(String(preemiinterest));
    }
    if (prev_pre_emi_interest !== pre_emi_interest) {
      console.log("pre emi interest changed");
      // set Disbursal Amount
      let disbursalamt = DisbursedAmount(
        Number(loan_amount),
        Number(processing_fee),
        Number(pre_emi_interest)
      ) - Number(health_prem);
      console.log("disbursal ampount is", disbursal_amount, disbursalamt);
      setDisbursalAmount(String(disbursalamt));
      setLoanAmount(
        String(Number(approved_amount) + Number(pre_emi_interest)+ Number(health_prem))
      );
    }
    if (prevProcessingFee !== processing_fee) {
      // set Disbursal Amount
      console.log("processing fee changed");
      let disbursalamt = DisbursedAmount(
        Number(loan_amount),
        Number(processing_fee),
        Number(pre_emi_interest)
      ) - Number(health_prem);
      console.log("disbursal ampount is", disbursal_amount, disbursalamt);
      setDisbursalAmount(String(disbursalamt));
    }
    if (prev_approval_date !== approval_date) {
      console.log("approval date changed");
      let emifirstdate = calculateEmiFirstDate(
        expected_salary_date,
        approval_date,
        role,
        employee["employment"]["cmp_id"]
      );
      setFirstDate(emifirstdate);
      console.log("pre emi first date changed",prev_pre_emi_date,pre_emi_date);
      // set pre Emi Interest
      let preemiinterest = preEmiInterest(
        Number(approved_amount),
        Number(irr) / 100,
        calculatepreEmiPeriod(pre_emi_date, approval_date)
      );
      console.log("pre emi interest is ", pre_emi_interest, preemiinterest);
      setPreEmiInteret(String(preemiinterest));
    }
    if (prev_loan_amount !== loan_amount) {
      console.log("loan amount changed",prev_loan_amount,loan_amount)
      let divide_amount = role == "weekly" ? 52 : 12
      let inst_amt = EmiAmount(
        Number(irr) / 100 / divide_amount,
        Number(tenure),
        Number(loan_amount) * -1,
        0,
        0
      );
      console.log("install amount  is ", installment_amt, inst_amt);
      setInstallmentAmount(String(inst_amt));
      let disbursalamt = DisbursedAmount(
        Number(loan_amount),
        Number(processing_fee),
        Number(pre_emi_interest)
      ) - Number(health_prem);
      console.log("disbursal ampount is", disbursal_amount, disbursalamt);
      setDisbursalAmount(String(disbursalamt));
      let amt_to_be_repaid = TotalAmountToBeRepaid(
        Number(loan_amount),
        Number(interest)
      );
      console.log(
        "amount to be repaid is",
        total_amount_to_be_repaid,
        amt_to_be_repaid
      );
      setAmountRepaid(String(amt_to_be_repaid));

    }
    if(prev_health_prem !== health_prem){
      console.log('health premium changed',prev_health_prem,health_prem)
     // change loan amount 
     setLoanAmount(
      String(Number(approved_amount) + Number(pre_emi_interest) + Number(health_prem))
    );
  
    }
   }
    // array of variables to trigger use effect
  }, [
    approved_amount,
    tenure,
    irr,
    installment_amt,
    interest,
    first_date,
    pre_emi_date,
    pre_emi_interest,
    disbursal_amount,
    processing_fee,
    approval_date,
    loan_amount,
    health_prem
  ]);

  useEffect(() => {
    //@ts-ignore
    let user = JSON.parse(localStorage.getItem("user")) || " ";
    setUserRole(user["role"]);
  }, [])

  const handleDateChange = (event: any) => {
    const chosenDate = new Date(event.target.value);
    const approvalDate = new Date(approval_date);
    // Calculate the number of days between the chosen date and the approval date
    const daysDifference = Math.ceil(
      (chosenDate.getTime() - approvalDate.getTime()) / (1000 * 3600 * 24)
    );
    if (daysDifference > 52) {
      message.warning("EMI date is too far from approval date");
    } else if (daysDifference < 20) {
      message.warning("EMI date is too close to approval date");
    } else {
      setFirstDate(event.target.value);
    }
  };

  return (
    <>
      {visible == true && (userrole == "appraisal" || userrole == "admin" || userrole == "super_admin") ? (
        <Card style={{ boxShadow: "0px 2px 4px 0px rgba(0, 0, 0, 0.2)" }}>
          <p style={{ color: "rgba(0,0,0,.85)", fontWeight: 700, fontSize: "16px", marginBottom: "-5px" }}>EMI ENACH  ESIGNATURE</p>
          <Row>
            <Col span={18}>

            <>
                      <Button
                        shape="round"
                        type="primary"
                        onClick={() => {
                          appraisalNotify();
                        }}
                        style={{ marginRight: "2%", marginTop: "5%", background:"#003a8c"}}
                      >
                        Enach Notifications
                      </Button>

                      <Button
                        shape="round"
                        type="primary"
                        onClick={() => {
                          EsignNotify();
                        }}
                        style={{ marginRight: "2%", marginTop: "5%", background:"#003a8c"}}
                      >
                       Esign Notifications
                      </Button>


                      <Button
                        shape="round"
                        type="primary"
                        // disabled={eNachData["enach_url"] ? true: false}
                        onClick={() => {
                          if(eNachData["enach_url"] ){
                             setIsModalVisibleEnach(true)
                          }
                          else{
                            eNach();
                          }
                        
                        }}
                        style={{
                          marginRight: "2%",
                          marginTop: "5%",
                          background: "#096dd9",
                        }}
                      >
                        Generate Enach
                      </Button>

                      <Button
                        shape="round"
                        type="primary"
                        // disabled={eSignData["status"] == "initiated"  || eSignData["status"] == "processed" || eSignData["status"] == "esignature sent"? true: false}
                        onClick={() => {
                          if(eSignData["status"] == "initiated"  || eSignData["status"] == "processed" || eSignData["status"] == "esignature sent"){
                            setIsModalVisible(true);
                          }
                          else{
                            eSign();
                          }
                         
                        }}
                        style={{ marginRight: "2%", marginTop: "5%", background:"#40a9ff" }}
                      >
                        Generate Esign
                      </Button>
                    </>
                  
            </Col>
            <Col span={6}>
              <Button
                style={{ margin: "10%" , marginTop:"15%"}}
                type="primary"
                shape="round"
                onClick={() => {
                  updateAllRowsScreen();
                }}
              >
                {" "}
                Save Data
              </Button>
            </Col>
          </Row>
          <Descriptions bordered>
          <Descriptions.Item label="Loan Id" span={3}>
                  {products['ops_id']}
                </Descriptions.Item>
                <Descriptions.Item label="Request Id" span={3}>
                  {request['ops_id']}
                </Descriptions.Item>

            {emi ? (
              <>
                <Descriptions.Item label="Expected Salary Day" span={3}>
                  {expected_salary_date.split("-")[2]}
                </Descriptions.Item>
                <Descriptions.Item label="Approval Date" span={3}>
                  <Input
                    type="date"
                    value={approval_date}
                    onChange={(e) => {
                      setApprovalDate(e.target.value);
                    }}
                  />
                </Descriptions.Item>
                <Descriptions.Item label="Average Salary" span={3}>
                  {average_salary}
                </Descriptions.Item>
                <Descriptions.Item label="Salary Multiplier" span={3}>
                  {salaryMultiplier}
                </Descriptions.Item>
                <Descriptions.Item label="Approved Amount" span={3}>
                  <Text
                    style={{ fontSize: "15px" }}
                    editable={{
                      icon: <HighlightOutlined />,
                      tooltip: "click to edit text",
                      onChange: setApprovedAmount,
                    }}
                  >
                    {approved_amount}
                  </Text>
                </Descriptions.Item>

                <Descriptions.Item label="Loan Amount" span={3}>
                  <Text
                    style={{ fontSize: "15px" }}
                    // editable={{
                    //   icon: <HighlightOutlined />,
                    //   tooltip: "click to edit text",
                    //   onChange: setLoanAmount,
                    // }}
                  >
                    {loan_amount}
                  </Text>
                </Descriptions.Item>
                <Descriptions.Item label="Experience in months" span={3}>
                  <Text style={{ fontSize: "15px" }}>{experience}</Text>
                </Descriptions.Item>
                <Descriptions.Item label="Tenure" span={3}>
                  <Text
                    style={{ fontSize: "15px" }}
                    editable={{
                      icon: <HighlightOutlined />,
                      tooltip: "click to edit text",
                      onChange: setTenure,
                    }}
                  >
                    {tenure}
                  </Text>
                </Descriptions.Item>
                <Descriptions.Item label="Irr" span={3}>
                  <Text
                    style={{ fontSize: "15px" }}
                    editable={{
                      icon: <HighlightOutlined />,
                      tooltip: "click to edit text",
                      onChange: setIrr,
                    }}
                  >
                    {irr}
                  </Text>
                </Descriptions.Item>
                <Descriptions.Item label="Processing Fee" span={3}>
                  <Text
                    style={{ fontSize: "15px" }}
                    editable={{
                      icon: <HighlightOutlined />,
                      tooltip: "click to edit text",
                      onChange: setProcessingFee,
                    }}
                  >
                    {processing_fee}
                  </Text>
                </Descriptions.Item>

                <Descriptions.Item label="Period" span={3}>
                 
                  <Select
                defaultValue={role}
                style={{ width: "100%", marginBottom: "2%" }}
                onChange={(value: any) => {
                  setRole(value)
                }}
              >
                { // @ts-ignore
                roles.map((element: any) => {
                  return <Option value={element}>{element}</Option>;
                })
                }
                 </Select> 
                </Descriptions.Item>
                {/* 
          // derived */}
                <Descriptions.Item label="Installment Amount" span={3}>
                  <Text
                    style={{ fontSize: "15px" }}
                    editable={{
                      icon: <HighlightOutlined />,
                      tooltip: "click to edit text",
                      onChange: setInstallmentAmount,
                    }}
                  >
                    {installment_amt}
                  </Text>
                </Descriptions.Item>

                <Descriptions.Item label="Emi First Date" span={3}>
                  <Input
                    type="date"
                    value={first_date}
                    onChange={(e) => {
                      handleDateChange(e);
                    }}
                  />
                </Descriptions.Item>
                <Descriptions.Item label="Pre Emi Date" span={3}>
                  <Input
                    type="date"
                    value={pre_emi_date}
                    onChange={(e) => {
                      setPreEmiDate(e.target.value);
                    }}
                  />
                </Descriptions.Item>
                <Descriptions.Item label="Interest" span={3}>
                  <Text
                    style={{ fontSize: "15px" }}
                    editable={{
                      icon: <HighlightOutlined />,
                      tooltip: "click to edit text",
                      onChange: setInterest,
                    }}
                  >
                    {interest}
                  </Text>
                </Descriptions.Item>
                <Descriptions.Item label="Pre Emi Interest" span={3}>
                  <Text
                    style={{ fontSize: "15px" }}
                    editable={{
                      icon: <HighlightOutlined />,
                      tooltip: "click to edit text",
                      onChange: setPreEmiInteret,
                    }}
                  >
                    {pre_emi_interest}
                  </Text>
                </Descriptions.Item>
                <Descriptions.Item label="Total Amount to be repaid" span={3}>
                  <Text
                    style={{ fontSize: "15px" }}
                    editable={{
                      icon: <HighlightOutlined />,
                      tooltip: "click to edit text",
                      onChange: setAmountRepaid,
                    }}
                  >
                    {total_amount_to_be_repaid}
                  </Text>
                </Descriptions.Item>

                <Descriptions.Item label="Disbursal Amount" span={3}>
                  <Text
                    style={{ fontSize: "15px" }}
                    editable={{
                      icon: <HighlightOutlined />,
                      tooltip: "click to edit text",
                      onChange: setDisbursalAmount,
                    }}
                  >
                    {disbursal_amount}
                  </Text>
                </Descriptions.Item>

                <Descriptions.Item label="Health Premium" span={3}>

                   <Text
                    style={{ fontSize: "15px" }}
                    // editable={{
                    //   icon: <HighlightOutlined />,
                    //   tooltip: "click to edit text",
                    //   onChange: setHealthPrem,
                    // }}
                  >
                    {health_prem}
                  </Text>
                </Descriptions.Item>
                <Descriptions.Item label="Toggle Health Premium" span={3}>
                <Switch defaultChecked={health_prem_opted} disabled={false} onChange={onChangeHealthPremium} />
                </Descriptions.Item>
              </>
            ) : (
              ""
            )}
            <>
            {productsstatus ?<Descriptions.Item label="NBFC" span={6}>
             <Select
                defaultValue={current_nbfc}
                style={{ width: "100%", marginBottom: "2%" }}
                onChange={(value: any) => {
                  setCurrentNbfc(value);
                }}
              >
                { // @ts-ignore
                nbfcel.map((element: any) => {
                  return <Option value={element?.id}>{element?.name}</Option>;
                })
                }
                 </Select> 
              </Descriptions.Item>:""}
            </>

<>
              {/* <Descriptions.Item label="Enach Account No" span={6}>
                {eNachData["account_no"] || ""}
              </Descriptions.Item> */}
              <Descriptions.Item label="Enach Url" span={6}>
                <Text copyable={true}>{eNachData["enach_url"] || ""}</Text>
              </Descriptions.Item>
              <Descriptions.Item label=" Enach Source Id" span={3}>
                {eNachData["source_id"] || ""}
              </Descriptions.Item>
              <Descriptions.Item label=" Enach Umrn" span={3}>
                {eNachData["umrn"] || ""}
              </Descriptions.Item>
              <Descriptions.Item label=" Enach Mandate Id" span={3}>
                {eNachData["mandate_id"] || ""}
              </Descriptions.Item>
              <Descriptions.Item label=" Enach Status" span={3}>
                <b>{eNachData["status"] || ""}</b>
              </Descriptions.Item>
            </>

            <>
              <Descriptions.Item label="Esign File" span={6}>
                <a style={{}} target="_blank" href={eSignData["upload_url"] || ""}>
                  {eSignData["file_name"] || ""}
                </a>
              </Descriptions.Item>
              {/* <Descriptions.Item label="Esign Upload Url" span={3}>
                <Text copyable={true}>{eSignData["upload_url"] || ""}</Text>
              </Descriptions.Item> */}
              {/* <Descriptions.Item label="Esign Org Id" span={6}>
                {eSignData["org_id"] || ""}
              </Descriptions.Item> */}
              <Descriptions.Item label="Esign Url" span={6}>
                <Text copyable={true}>{eSignData["sign_url"] || ""}</Text>
              </Descriptions.Item>
              <Descriptions.Item label=" Esign Status" span={3}>
                <b>{eSignData["status"] || ""}</b>
              </Descriptions.Item>
              {/* <Descriptions.Item label=" Esign Webhook Url" span={3}>
                <Text copyable={true}>{eSignData["webhook_url"] || ""}</Text>
              </Descriptions.Item> */}
              {/* <Descriptions.Item label=" Esign Created On" span={3}>
                {eSignData["timestamp"] || ""}
              </Descriptions.Item> */}
            </>
          </Descriptions>
        </Card>
      ) : salaries == true && (userrole == "appraisal" || userrole == "admin" || userrole == "super_admin") ? (
        <Spin size="large" style={{ marginLeft: "40%", marginTop: "20%" }} />
      ) : (userrole == "appraisal" || userrole == "admin" || userrole == "super_admin") ? (
        <Card>
          <p
            style={{
              textAlign: "center",
              fontFamily: "serif",
              fontSize: "25px",
            }}
          >
            {" "}
            Insufficient or Zero Salary Data
          </p>
          <MehOutlined style={{ fontSize: "100px", marginLeft: "46%" }} />
        </Card>
      ):" "}{" "}

      <Modal title="Alert" visible={isModalVisible} onOk={()=>{setIsModalVisible(false);  eSign();}} onCancel={()=>{setIsModalVisible(false)}}>
        <p>Do you want to proceed for generating esignature?</p>
      </Modal>

      <Modal title="Alert" visible={isModalVisibleEnach} onOk={()=>{setIsModalVisibleEnach(false);  eNach();}} onCancel={()=>{setIsModalVisibleEnach(false)}}>
        <p>Do you want to proceed for generating enach?</p>
      </Modal>
    </>
  );
}