
const dev_url ="https://api-staging.entitled.co.in"

const prod_url = "https://api-credit.entitled.co.in"

export const config: any = {
    "base_url":process.env.REACT_APP_STAGE === 'production' ? prod_url : dev_url,
    "kinesis_url":process.env.REACT_APP_STAGE === 'production' ? "https://i4umemy7ud.execute-api.ap-south-1.amazonaws.com/prod/streams/ops-backend-stream/record" : "https://d4hbvkcof0.execute-api.ap-south-1.amazonaws.com/dev/streams/ops-backend-stream-staging/record"
}

