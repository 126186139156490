import { useSelector } from "react-redux";
import { MainReducer } from "../../types";
import { Row, Col, Typography, Input } from "antd";
const { Text } = Typography;

const AdditionalInfo: React.FC = () => {
const employee:any = useSelector((state: MainReducer) => {
    return state.loan.employee.body;
});
  const metaData = employee?.meta_data;
  return (
    <div>

      <Row>
        <Col span={8} style={{ padding: "2%" }}>
          <h6><b>Active Status</b></h6>
          <Text style={{ fontSize: "14px" }}>{metaData?.ActiveStatus ? metaData.ActiveStatus : "Unknown"}</Text>
        </Col>
        <Col span={8} style={{ padding: "2%", background: "#f5f5f5" }}>
          <h6><b>Deduction Status</b></h6>
          <Text style={{ fontSize: "14px" }}>{metaData?.DeductionStatus ? metaData.DeductionStatus : "Unknown"}</Text>
        </Col>
        <Col span={8} style={{ padding: "2%" }}>
          <h6><b>FnF Status</b></h6>
          <Text style={{ fontSize: "14px" }}>{metaData?.FnFStatus ? metaData.FnFStatus : "Unknown"}</Text>
        </Col>
      </Row>

      <Row>
        <Col span={8} style={{ padding: "2%" }}>
          <h6><b>Last Salary Release Date</b></h6>
          <Text style={{ fontSize: "14px" }}>{metaData?.LastSalaryReleaseDate ? metaData.LastSalaryReleaseDate : "Unknown"}</Text>
        </Col>
        <Col span={8} style={{ padding: "2%", background: "#f5f5f5" }}>
          <h6><b>Resignation Date</b></h6>
          <Text style={{ fontSize: "14px" }}>{metaData?.ResignationDate ? metaData.ResignationDate : "Unknown"}</Text>
        </Col>
        <Col span={8} style={{ padding: "2%" }}>
          <h6><b>Resignation Status</b></h6>
          <Text style={{ fontSize: "14px" }}>{metaData?.ResignationStatus ? metaData.ResignationStatus : "Unknown"}</Text>
        </Col>
      </Row>

      <Row>
        <Col span={8} style={{ padding: "2%" }}>
          <h6><b>Total Non-Deducted Last Month</b></h6>
          <Text style={{ fontSize: "14px" }}>{metaData?.TotalNondeductedLastMonth ? metaData.TotalNondeductedLastMonth : "Unknown"}</Text>
        </Col>
        <Col span={8} style={{ padding: "2%", background: "#f5f5f5" }}>
          <h6><b>Total Outstanding EMI</b></h6>
          <Text style={{ fontSize: "14px" }}>{metaData?.TotalOutstandingEMI ? metaData.TotalOutstandingEMI : "Unknown"}</Text>
        </Col>
        <Col span={8} style={{ padding: "2%" }}>
          <h6><b>Message</b></h6>
          <Text style={{ fontSize: "14px" }}>{metaData?.Message ? metaData.Message : "Unknown"}</Text>
        </Col>
      </Row>
      <Row>
        <Col span={8} style={{ padding: "2%" }}>
          <h6><b>Loan Booking status</b></h6>
          <Text style={{ fontSize: '14px' }}>{metaData?.loanApiStatus !== undefined ? (metaData.loanApiStatus ? 'Success' : 'Failed') : 'Unknown'}</Text>
        </Col>
      </Row>

    </div>
  );
};
export default AdditionalInfo;
