import { Row, Col, Table, Typography } from 'antd'
import { HighlightOutlined } from "@ant-design/icons";
const { Text } = Typography;
export const AddressDetails = (props: any) => {
	props = props.props;
	return (<div>
		<Row>
			<Col
				span={6}
				style={{ padding: "2%", background: "#f5f5f5" }}
			>
				<h6>
					<b>Permanent Address</b>
				</h6>
				<Text
					style={{ fontSize: "14px" }}
					editable={{
						icon: <HighlightOutlined />,
						tooltip: "click to edit text",
						onChange: props.setAddress,
					}}
				>
					{props.address}
				</Text>
			</Col>
			<Col span={6} style={{ padding: "2%" }}>
				<h6>
					<b>State</b>
				</h6>
				<Text style={{ fontSize: "14px" }}>{props.state}</Text>
			</Col>

			<Col span={6} style={{ padding: "2%" }}>
				<h6>
					<b>District</b>
				</h6>
				<Text style={{ fontSize: "14px" }}>{props.city}</Text>
			</Col>
			<Col
				span={6}
				style={{ padding: "2%", background: "#f5f5f5" }}
			>
				<h6>
					<b>Pincode</b>
				</h6>
				<Text
					style={{ fontSize: "14px" }}
					editable={{
						icon: <HighlightOutlined />,
						tooltip: "click to edit text",
						onChange: props.setPincode,
					}}
				>
					{props.pincode}
				</Text>
			</Col>
		</Row>

		<Row>
			<Col
				span={6}
				style={{ padding: "2%", background: "#f5f5f5" }}
			>
				<h6>
					<b>Temporary Address</b>
				</h6>
				<Text
					style={{ fontSize: "14px" }}
					editable={{
						icon: <HighlightOutlined />,
						tooltip: "click to edit text",
						onChange: props.setAddress_1,
					}}
				>
					{props.address_1}
				</Text>
			</Col>
			<Col span={6} style={{ padding: "2%" }}>
				<h6>
					<b>State</b>
				</h6>
				<Text style={{ fontSize: "14px" }}>{props.state_1}</Text>
			</Col>

			<Col span={6} style={{ padding: "2%" }}>
				<h6>
					<b>District</b>
				</h6>
				<Text style={{ fontSize: "14px" }}>{props.city_1}</Text>
			</Col>
			<Col
				span={6}
				style={{ padding: "2%", background: "#f5f5f5" }}
			>
				<h6>
					<b>Pincode</b>
				</h6>
				<Text
					style={{ fontSize: "14px" }}
					editable={{
						icon: <HighlightOutlined />,
						tooltip: "click to edit text",
						onChange: props.setPincode_1,
					}}
				>
					{props.pincode_1}
				</Text>
			</Col>
		</Row>
		<Row>
			<Col span={9} style={{ padding: "2%" }}></Col>
			{/* <Col span={2} style={{ padding: "2%" }}> */}
			<h5>
				<b>Addresses As Per Documents</b>
			</h5>
			{/* <Button
                      type="primary"
                      shape="round"
                      onClick={() => {
                        updateLoan("7");
                      }}
                      style={{ background: "#096dd9" }}
                    >
                      Save Data
                    </Button> */}
			{/* </Col> */}
		</Row>
		{props.employeeStatus ? (
			<Table columns={props.addressCols} dataSource={props.otheraddress} />
		) : (
			""
		)}
	</div>)
}