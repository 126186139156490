import { Tooltip } from "antd";
import { Link } from "react-router-dom";
export function BtnCellRenderer(props: any) {
  return (
    <Tooltip
      title={
        !props["data"]["employee_present"]
          ? "Employee data not present"
          : "Click here to go to details"
      }
    >
      <Link
        type="primary"
        to={`/employees?request_id=${props["data"]["loan_id"]}&employee_id=${props["data"]["internal_emp_id"]}&type=operations`}
        className="btn btn-primary"
      >
        Show Details
      </Link>
    </Tooltip>
  );
}
