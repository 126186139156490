import React, { useEffect, useState } from "react";
import {useSelector} from "react-redux"
import { Divider, Space, Table } from "antd";
import type { ColumnsType } from "antd/es/table";
import {HighlightOutlined, DeleteTwoTone} from '@ant-design/icons'
import { MainReducer } from "../../types";
import { getUsers } from "../../utitlities/request";
import moment from "moment";
const _ = require('lodash');
interface Logs {
  // updatedBy: string;
  status_time: any;
  status: string;
}


const ActivityStream: React.FC = () => {
  const loan = useSelector((state: MainReducer) => {
    return state.loan;
  });

  const [users, setUsers] = useState([]);
  async function fetchUserApi() {
    const users = await getUsers();
    setUsers(users);
  }

  useEffect(()=>{
    fetchUserApi();
  },[loan])
  const logs = loan.logs.body.data_new;
  const stream = loan.stream.body.data;
  console.log("loan",stream)

  const logsData = logs.map((s:any,index:Number) => ({
    updatedBy: _.get(_.find(users, { id: Number(s.user_id)}), 'user_name', s.user_id),
    status_time: s.action_timestamp.split("T")[0],
    status:s.action_target,
    trigger:s.trigger_type
  }))

  const columns: ColumnsType<Logs> = [
    {
      title: "Status",
      dataIndex: "status",
      key: "status",
    },
    {
      title: "Updated At",
      dataIndex: "status_time",
      key: "status_time",
    },
    {
      title: "Updated By",
      dataIndex: "updatedBy",
      key: "updatedBy",
    },
    {
      title: "Trigger",
      dataIndex: "trigger",
      key: "trigger",
    },
  ];

  const filteredStream = stream.filter((s: any) => s.action === 'assign_user_loan_request');
  const streamData = filteredStream.map((s:any,index:Number) => ({
    assignedBy: _.get(_.find(users, { id: Number(s.user_id)}), 'user_name', s.user_id),
    action_time: moment(s.action_timestamp).format("DD/MM/YYYY hh:mm A"),
    assigned_to: _.get(_.find(users, { id: Number(s.action_target)}), 'user_name', s.action_target),
  }))
  const streamcolumns: ColumnsType<Logs> = [

    {
      title: "Assigned At",
      dataIndex: "action_time",
      key: "action_time",
    },
    {
      title: "Assigned By",
      dataIndex: "assignedBy",
      key: "assignedBy",
    },
    {
      title: "Assigned To",
      dataIndex: "assigned_to",
      key: "assigned_to",
    },
  ];
  return (
    <div className="emp-info-form">
      <div className="title">Status Activity Stream</div>
      <Divider style={{ borderColor: "#E5E7EA", margin: "20px 0px" }} />
      <Table columns={columns} dataSource={logsData} />
      <div className="form01"></div>

      <div className="title">Agent Activity Stream</div>
      <Divider style={{ borderColor: "#E5E7EA", margin: "20px 0px" }} />
      <Table columns={streamcolumns} dataSource={streamData} />
      <div className="form01"></div>
    </div>
  );
};
export default ActivityStream;
