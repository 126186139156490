// import React, { useEffect, useState } from "react";
import "./App.css";
import { Sidebar } from "./components/sidebar";
import { Layout } from "antd";
import "antd/dist/antd.css";
import "./index.css";
import { Provider } from "react-redux";
import { store } from "./state";
function App() {
  // const [path, setPath] = useState("");
  // useEffect(() => {
  //   setPath(window.location.pathname);
  // }, [window]);
  return (
    <Provider store={store}>
      <Layout>
        <Sidebar></Sidebar>
      </Layout>
    </Provider>
  );
}

export default App;
