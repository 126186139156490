import { combineReducers } from "redux";
import loanReducer from "./loanReducer";
import appraisalReducers from "./appraisalReducer";
import masterViewReducers from "./masterViewReducer";

const reducers = combineReducers({
    loan: loanReducer,
    appraisal: appraisalReducers,
    master: masterViewReducers
})

export default reducers

export type RootState = ReturnType<typeof reducers>