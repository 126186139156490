import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { Switch } from "antd";
import "./toggle.scss";
const ViewToggle = (props: any) => {
  const { redirectUrl, defaultValue } = props;
  const [toggle, setToggle] = useState(true);
  const history = useHistory();

  useEffect(() => {
    setToggle(defaultValue);
  }, []);

  const onViewChangeHandler = (val: boolean) => {
    setToggle(val);
    setTimeout(() => {
      history.push(redirectUrl);
    }, 1000);
  };

  return (
    <div className="toggle-view">
      <Switch
        checkedChildren="New View"
        unCheckedChildren="Old View"
        checked={toggle}
        onChange={onViewChangeHandler}
      />
    </div>
  );
};
export default ViewToggle;
