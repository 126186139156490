import React, { useEffect, useState } from "react";
import { Divider, Row, Col, Button, Typography } from "antd";
import { BankOutlined, HighlightOutlined } from "@ant-design/icons";
import { useDispatch, useSelector } from "react-redux";
import * as _ from "lodash";
import {
	Personal,
	Employment,
	Salary,
	BankDetails,
	Data,
	DocumentSchema,
	MainReducer,
	ExistingLoanRequest,
	ExistingLoan,
	BankStatementData,
} from "../../types";

import { useLocation } from "react-router-dom";

import { analyseStatements, bankValidate, abbSave, BankDetailsUpdateDatabase, generateCibil, BankDetailsFetch } from "../../utitlities/request";
import { fetchEmployee, updateEmployee } from "../../state/action-creators";


const { Text } = Typography;

const NBFCData: React.FC = () => {
	const dispatch = useDispatch();
	const search = useLocation().search;
	const type = new URLSearchParams(search).get("type");
	const employee = useSelector((state: MainReducer) => {
		return state.loan.employee.body;
	});


	const nbfcObj = useSelector((state: MainReducer) => {
		const arthmate = state.loan.arthmate.body;

		return {
			error: _.get(arthmate.data[0], 'error', '-'),
			status: _.get(arthmate.data[0], 'status', '-'),
			emp_id: _.get(arthmate.data[0], 'arthmate_emp_id', '-'),
			loan_app_id: _.get(arthmate.data[0], 'arthmate_loan_id', '-'),
			loan_id: _.get(arthmate.data[0], 'arthmate_post_loan_id', '-'),
			colender: _.get(arthmate.data[0], 'colender', '-'),
			colender_id: _.get(arthmate.data[0], 'colender_assignment_id', '-'),
			cibil_score: _.get(arthmate.data[0], 'cibil_score', '-')
		};

	});

	return (<>

		<Row gutter={24}>
			<Col xs={24} xl={12} className="info-input-row">
				<div className="label">NBFC Loan App ID</div>
				<Text style={{ fontSize: "14px" }}>{nbfcObj.loan_app_id || "-"}</Text>
			</Col>
			<Col xs={24} xl={12} className="info-input-row">
				<div className="label">NBFC Emp ID</div>
				<Text style={{ fontSize: "14px" }}>{nbfcObj.emp_id || "-"}</Text>
			</Col>
		</Row>
		<Row gutter={24}>
			<Col xs={24} xl={12} className="info-input-row">
				<div className="label">NBFC Loan ID</div>
				<Text style={{ fontSize: "14px" }}>{nbfcObj.loan_id || "-"}</Text>
			</Col>
		</Row>
		<Row gutter={24}>
			<Col xs={24} xl={12} className="info-input-row">
				<div className="label">NBFC Status</div>
				<Text style={{ fontSize: "14px" }}>{nbfcObj.status || "-"}</Text>
			</Col>
			<Col xs={24} xl={12} className="info-input-row">
				<div className="label">NBFC Error</div>
				<Text style={{ fontSize: "14px" }}>{nbfcObj.error || "-"}</Text>
			</Col>
		</Row>
		<Row gutter={24}>
			<Col xs={24} xl={12} className="info-input-row">
				<div className="label">Colender</div>
				<Text style={{ fontSize: "14px" }}>{nbfcObj.colender || "-"}</Text>
			</Col>
			<Col xs={24} xl={12} className="info-input-row">
				<div className="label">Colender Id</div>
				<Text style={{ fontSize: "14px" }}>{nbfcObj.colender_id || "-"}</Text>
			</Col>
		</Row></>)

}

export default NBFCData